.code-c-pagination {
  $pagination-padding: 0 1em;
  $pagination-height: 2.5rem;
  $pagination-border-color: $color-border-gray;

  z-index: $z-index-pagination;
  display: inline-flex;
  justify-content: center;
  height: $pagination-height;
  line-height: $pagination-height;

  a {
    display: block;
    padding: $pagination-padding;
    line-height: $pagination-height;
    color: $color-link;
    text-decoration: none;
    outline: 0;
  }

  .code-c-pagination__link {
    border-top: 1px solid $pagination-border-color;
    border-right: 1px solid $pagination-border-color;
    border-bottom: 1px solid $pagination-border-color;

    &:hover {
      background-color: $color-gray-100;
    }
  }

  .code-c-pagination__break {
    border-top: 1px solid $pagination-border-color;
    border-right: 1px solid $pagination-border-color;
    border-bottom: 1px solid $pagination-border-color;
  }

  .code-c-pagination__previous {
    border: 1px solid $pagination-border-color;
    border-radius: 4px 0 0 4px;

    &:hover {
      background-color: $color-gray-100;
    }
  }

  .code-c-pagination__next {
    border: 1px solid $pagination-border-color;
    border-left: 0;
    border-radius: 0 4px 4px 0;

    &:hover {
      background-color: $color-gray-100;
    }
  }

  .is-current {
    background: $color-primary;

    &:hover {
      background: $color-primary;
    }

    a {
      color: $color-text;
    }
  }

  .is-disabled {
    pointer-events: none;
    background: $color-gray-light;

    a {
      color: $color-gray-dark;
    }
  }
}
