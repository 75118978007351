.code-c-bar-chart-mini {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  min-width: 2rem;
  height: 1.5rem;
  padding: 0 2px 1px;
  border-bottom: 1px solid $color-border-gray;

  .code-c-bar-chart-mini__bar {
    width: 2px;
    background: $color-green-500;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}
