.code-submission-auto-evaluated-tag {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
  align-items: center;

  // aligning with Tertiary Button
  padding: 0.25rem 0.5rem;
  color: $color-neutral-500;
  border: 1px solid transparent;

  .icon {
    margin-left: calc(-0.375em - 1px); // aligning with Tertiary Button

    .fa {
      font-size: 14px;
    }
  }
}
