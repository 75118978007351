.code-c-exam-title-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .code-c-exam-title-column__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .code-c-exam-title-column__archived {
    color: $color-white;
    background: $color-gray-dark;
  }

  .code-c-exam-title-column__multilang {
    color: $color-secondary-900;
    background: $color-secondary-300;
  }

  .code-c-exam-title-column__disabled {
    color: $color-gray-600;
    background: $color-gray-100;
  }

  .code-c-exam-title-column__official {
    color: $color-green-900;
    background: $color-green-300;
  }
}
