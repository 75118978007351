.code-exam-edit__reviewers {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

  .code-exam-edit__reviewers-header {
    margin: 0 1.5rem 1.5rem;

    .code-c-page-title {
      margin-bottom: 0;
    }
  }

  .code-exam-edit__reviewers-container {
    display: flex;
    flex: 1;
    gap: 1rem;
    padding: 0 1.5rem 1.75rem;
    overflow-y: auto;

    > div:first-child {
      flex: 2;
    }

    .code-exam-edit__reviewers-container__form-group {
      display: flex;
      max-width: none;
      max-height: 100%;
    }
  }

  .code-exam-edit__reviewer-table__head {
    > tr {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  .code-exam-edit__reviewers-settings {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 20rem;
    padding: 1.25rem;
    overflow-y: scroll;
    border: 1px solid $color-gray-200;

    &.code-exam-edit__reviewers-settings__min-content {
      height: min-content;
    }
  }

  .code-exam-edit__reviewers-info {
    margin-bottom: 0.75rem;
    color: $color-neutral-700;

    @include text-sm;
  }

  .code-exam-edit__reviewers-options {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-bottom: 0.75rem;

    .code-exam-edit__reviewers-options__select {
      width: 100%;

      .select {
        width: 100%;
      }
    }

    > * {
      flex: 1;
    }
  }
}

.code-exam-edit__reviewers-checkbox {
  min-height: 1.25rem;
}
