.code-c-submission-detail-coding {
  .code-c-submission-detail-section {
    border-bottom: 1px solid $color-border-gray;

    .code-c-submission-detail-section__title {
      display: flex;
      gap: 0.5rem;
    }
  }

  .code-c-submission-detail-section__body {
    padding: 1rem;
  }

  .no-body-padding {
    .code-c-submission-detail-section__body {
      padding: 0;
    }
  }
}
