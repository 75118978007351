.code-time-limit-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 0 4rem;
  font-size: 1rem;

  .code-time-limit-panel__container {
    display: flex;
    flex-direction: column;
  }

  .code-time-limit-panel__time {
    font-size: 100px;
    font-weight: 100;
    line-height: 152px;
  }

  .code-time-limit-panel__time-unit {
    font-size: 45px;
    line-height: 90px;
  }
}

// --------------------------------------------

.code-time-limit-panel-new {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.25rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  font-size: 1rem;

  margin-bottom .code-time-limit-panel__container {
    display: flex;
    flex-direction: column;
  }

  .code-time-limit-panel__timetext {
    width: 100%;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 133.333% */
    color: $color-gray-600;
    text-align: center;
  }

  .code-time-limit-panel__time {
    width: 100%;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    color: $color-primary-900;
    text-align: center;
  }

  .code-time-limit-panel__time-unit {
    width: 100%;
    padding-left: 1rem;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    color: $color-primary-900;
    text-align: center;
  }

  .code-time-limit-panel__longtext {
    position: relative;
    width: 100%;
    height: 19rem;
    background-color: $color-white;
    background-image: url("./assets/images/walkthrough-getstarted.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;

    & > span {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      text-align: center;
      background-color: $color-neutral-100;
    }
  }
}
