.code-c-error-icon {
  position: relative;
  width: 1rem;
  height: 1rem;

  // to add white background to make the icon visible on any color.
  .has-error-background {
    position: absolute;
    font-size: 0.9rem;
    color: $color-white;

    &::before {
      position: absolute;
      top: 0.25rem;
      bottom: 0;
      left: 0.4rem;
      width: 0.25rem;
      height: 0.5rem;
      content: "";
      background: $color-white;
    }
  }

  .has-error {
    position: absolute;
  }
}