.code-c-submission-detail {
  .code-c-submission-detail__header {
    position: sticky;
    top: 38px;
    z-index: 4;
  }

  .code-c-submission-detail-section__header {
    position: sticky;
    top: 124px;
    z-index: 3;
  }

  .code-c-test-output {
    .code-c-tooltip {
      z-index: 2;
    }
  }

  .code-c-submission-detail-question__header {
    position: sticky;
    top: 124px;
    z-index: 2;
  }

  .code-c-submission-detail__empty {
    padding: 1rem;
  }
}
