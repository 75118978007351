.code-admin-exam-list {
  .code-admin-exam-list__header {
    padding: 1.5rem 1.5rem 0;
  }

  .code-c-table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .code-admin-exam-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-admin-exam-list__modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .code-admin-exam-list__delete-button {
    color: $color-red-700 !important;
  }

  .code-admin-exam-list__tags {
    display: flex;
    column-gap: 0.5rem;

    .code-admin-exam-list__tag {
      color: $color-white !important;
      background: $color-gray-dark !important;
    }
  }
}
