.code-exam-forms {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 1.5rem 1.75rem;
  overflow-y: auto;

  &.is-preview {
    max-width: calc(52rem + 2px);
  }

  .code-exam-forms__container {
    overflow-y: auto;
    border: 1px solid $color-border-gray;
  }

  .code-exam-forms__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    background: $color-gray-super-light;
    border-bottom: 1px solid $color-border-gray;

    > div {
      flex: 1;
      padding: 0.5rem;
    }
  }

  .code-exam-forms__form,
  .code-exam-forms__preview {
    padding: 1rem;
  }

  .code-exam-forms__preview-wrapper {
    max-width: 100%;
  }

  .code-exam-forms__left-border {
    max-width: 50%; // When it is next to the edit box, it should be 50%
    border-left: 1px solid $color-border-gray;

    // Switch to vertical on small screens
    @include mq(lg) {
      max-width: 100%;
      border-top: 1px solid $color-border-gray;
      border-left: none;
    }
  }

  .code-exam-forms__body {
    display: flex;
    flex-direction: row;

    // Switch to vertical on small screens
    @include mq(lg) {
      flex-direction: column;
    }

    > div {
      flex: 1;
    }
  }

  .code-c-custom-form-configure-box {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $color-border-gray;
    }
  }

  .code-c-custom-form-preview-box {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $color-border-gray;
    }
  }
}
