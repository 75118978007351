.code-c-official-exam-card {
  width: 13.5rem;
  height: 18rem;
  font-size: 0.75rem;
  text-align: center;
  border: 1px solid $color-border-gray;
  border-radius: 3px;

  .code-c-official-exam-card__image-container {
    position: relative;
    height: 45%;

    .cover-image-wrapper {
      display: inline-block;
      width: 100%;
      height: 100%;

      .cover-image {
        height: 100%;
      }
    }

    .code-c-tooltip-wrapper {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      z-index: unset;

      .tag-language {
        font-size: 0.75rem;
        background: $color-primary-200;
        border-radius: 15px;
      }
    }
  }

  .code-c-official-exam-card__detail-container {
    position: relative;
    height: 55%;
    padding: 0.5rem;
    text-align: left;
    background: $color-gray-super-light;

    .code-c-official-exam-card__detail-container__title {
      margin-bottom: 0.25rem;
      margin-left: 0.25rem;
      font-weight: 600;
    }

    .code-c-official-exam-card__detail-container__items {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .icon {
        height: 1.3rem;
      }
    }

    .code-c-official-exam-card__updatedat {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      font-size: 0.65rem;
      color: $color-gray;
    }
  }
}
