.code-c-action-log-filter {
  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  align-items: center;
  height: 40px;

  .code-c-action-log-select {
    flex-basis: 280px;
  }

  .code-c-action-log-filter__action-select {
    .dropdown-item {
      color: $color-neutral-900;
    }
  }

  .code-c-action-log-filter__challenge-select {
    .dropdown-item {
      label {
        .challenge-title {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.is-disabled {
        label {
          display: flex;
          white-space: pre;

          .challenge-status {
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
