.code-admin-challenge-collection-form {
  display: flex;

  .code-admin-challenge-collection-form__left {
    flex-grow: 1;
    padding: 1.5rem;
    border-right: solid 1px $color-border-gray;

    .code-c-form,
    .code-c-table {
      margin-bottom: 1.875rem;
    }

    .code-c-checkbox {
      .code-c-checkbox__label {
        display: inline-flex;
      }

      .code-c-checkbox__label__text {
        word-break: break-all;
      }
    }

    .code-c-validation-message {
      margin: 0.5rem 0;
    }

    .code-admin-challenge-collection-form__save {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding: 2rem;
      background: $color-white;
    }
  }

  .code-admin-challenge-collection-form__right {
    padding: 1.5rem;

    .code-c-challenge-collection-card {
      margin-bottom: 1rem;
    }
  }

  .code-admin-challenge-collection-form__pre-action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .code-c-button:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .code-admin-challenge-collection-form__post-action {
    text-align: center;
  }

  .code-challenge-detail {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: $z-index-loading + 1;
    width: 100%;
    max-width: 65rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .code-admin-challenge-collection-form__overlay {
    background: transparentize($color-gray-super-dark, 0.5);
  }
}
