.code-exam-detail-header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 0.5rem;
  }

  .code-exam-detail-header__left-element {
    flex: 1
  }

  .code-exam-detail__exam_id {
    margin-left: 0.5rem;
    font-size: 0.8125rem;
  }
}
