.code-exam-create__settings {
    .code-c-form-group {
      margin-bottom: 1.875rem;
    }
  
    .code-c-copy-box {
      .code-c-input {
        flex-grow: 1;
      }
    }
  
    .code-c-label {
      :not(:last-child) {
        margin-right: 0.25rem;
      }
  
      &.exam-label {
        display: flex;
        align-items: center;
      }
    }
  
    .settings-info {
      display: flex;
      flex-direction: row;
      column-gap: 0.25rem;
      align-items: flex-start;
      margin-bottom: 0.75rem;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      color: $color-neutral-700;
  
      .code-c-icon {
        width: 1rem;
        height: 1.125rem;
        font-size: 1rem;
      }
  
      a {
        font-weight: 700;
  
        .code-c-icon {
          width: 0.75rem;
          height: 1.125rem;
          font-size: 0.75rem;
        }
      }
    }
  
    .code-exam-create_settings__applicant-action-log {
      max-width: unset;
  
      .exam-label {
        margin-bottom: 0.5rem;
      }
  
      .code-c-checkbox__label {
        .setting-explanation {
          margin-top: 0.5rem;
          color: $color-gray-700;
        }
  
        &.is-readonly {
          .setting-explanation {
            color: $color-gray-600;
          }
        }
      }
  
      .action-log-setting-checkbox {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
  
        &.has-sub-option {
          margin-bottom: 0.55rem;
        }
  
        &.is-sub-option {
          margin-left: 1.5rem;
        }
  
        .action-log-setting-checkbox__text {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          white-space: nowrap;
        }
      }
    }
  
    .code-exam-create_settings__auto-filter {
      .code-c-checkbox {
        margin-bottom: 1rem;
      }
    }
  
    .code-exam-create__settings__monitor-warning {
      margin-bottom: 0.5rem;
    }
  
    .code-exam-create__settings__local-exam-warning {
      margin-bottom: 0.5rem;
    }
  
    .code-exam-create__settings__option-box {
      max-width: 30rem;
      padding: 1rem;
      margin-top: 1rem;
      border: 1px solid $color-border-gray;
      border-radius: 6px;
  
      .code-c-form-group:last-child {
        margin-bottom: 0;
      }
  
      .code-c-checkbox__label__text {
        white-space: nowrap;
      }
    }
  
    .code-exam-create__guest-sharing {
      .code-exam-create__guest-sharing__label {
        @include text-sm;
  
        color: $color-gray-700;
      }
  
      .code-c-form-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;
  
        .code-c-label {
          margin-bottom: 0;
        }
  
        .code-exam-create__guest-sharing__group {
          > :first-child {
            margin-bottom: 0.5rem;
          }
  
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
        }
  
        .code-exam-create__guest-sharing__secret-test-cases {
          margin-left: 1.5rem;
        }
      }
    }
  
    .code-exam-create__enable-url-subtext {
      margin-left: 1.5rem;
  
      @include text-sm;
  
      color: $color-gray-700;
    }
  
    .code-exam-create__settings__cover-image {
      padding: 1rem;
      border: 1px solid $color-border-gray;
      border-radius: 3px;
  
      .code-c-avatar {
        width: 190px;
        height: 110px;
  
        .code-c-avatar__image {
          content: unset;
          background-image: unset;
        }
      }
    }
  
    .code-exam-create__settings__domains {
      .code-exam-create__settings__domains-label {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.75rem;
  
        .code-exam-create__settings__domains-label__text {
          display: flex;
          align-items: center;
  
          .code-c-label {
            margin-bottom: 0;
          }
        }
      }
  
      .code-exam-create__settings__domains-textarea {
        max-height: 300px;
        cursor: pointer;
  
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  
  .code-exam-create-new__settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1.5rem;
    padding-bottom: 1.5rem;
  
    .code-exam-create-new__settings__container {
      padding: 1rem 1.5rem;
      border: 1px solid $color-neutral-200;
      border-radius: 0.5rem;
    }
  
    .code-exam-create-new__settings__switch-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .code-c-form-group {
      margin-bottom: 0 !important;
    }
  
    .exam-label {
      display: flex;
      gap: 0.25rem;
      align-items: center;
    }
  
    .code-exam-create-new__settings__id-delivery {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      > div {
        max-width: 30rem;
      }
  
      .code-exam-create-new__settings__checkboxes {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
    }
  
    .code-exam-create-new__settings__email-delivery {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      .code-self-delivery-checkbox {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
  
      .code-exam-create-new__settings__self-delivery-options {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 30rem;
        margin-left: 1.5rem;
  
        .code-c-copy-box {
          margin-bottom: 0;
        }
      }
  
      .code-exam-create-new__settings__domain-list {
        margin-bottom: 0.5rem;
      }
  
    }
  
    .code-exam-create-new__settings__ats-integration {
      .code-exam-create-new__settings__ats-integration__options {
        margin-left: 1.5rem;
      }
    }
  
    .code-exam-create__guest-sharing {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      
      .code-exam-create__guest-sharing__label {
        @include text-md;
  
        color: $color-gray-700;
      }
  
      .code-c-form-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;
  
        .code-exam-create__guest-sharing__secret-test-cases {
          margin-left: 1.5rem;
        }
      }
    }
  
    .code-exam-create_settings__applicant-action-log {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      .with-sub-options {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
  
      .is-sub-option {
        margin-left: 1.5rem;
      }
    }
  
    .code-exam-create__settings__delivery-type {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      width: 100%;
  
      > div {
        flex: 1;
      }
  
    }
  
    .code-exam-create-new__settings__delivery-date-container {
      display: flex;
      gap: 0.5rem;
  
      .tilde {
        margin-top: 0.5rem;
      }
  
      .code-c-date-picker {
        width: 12rem;
      }
    }
  
    .code-exam-create-new__settings__days-input {
      width: 6rem;
    }
  }
  
  .code-exam-create-new__settings__tooltip {
    max-width: 22rem;
  }