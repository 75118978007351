.code-exam-edit__outline {
  .code-exam-edit__outline__subtitle {
    font-size: 1rem;
    font-weight: bold;
  }

  .code-exam-edit__outline__group-container {
    display: flex;
    gap: 1rem;
    max-width: $breakpoint-xl;

    .code-exam-edit__outline__info, .code-exam-edit__outline__types {
      flex-grow: 1;
      flex-shrink: 0;
      max-width: 50rem;
      padding: 1rem;
      background: $color-neutral-100;
      border: 1px solid $color-border-gray;
      border-radius: 3px;

      > div:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .code-exam-edit__outline__label-container {
    margin-bottom: 0.75rem;

    .code-exam-edit__outline__label {
      @include text-sm;

      margin-bottom: 0.25rem;
    }

    .code-exam-edit__outline__sub-label {
      @include text-xs;

      color: $color-gray-600;
    }
  }
}

.code-exam-edit__outline-autocomplete-popup {
  padding-right: 0;
  padding-left: 0;
}
