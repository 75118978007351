.code-liveness-result-modal {
  .modal-card-body {
    padding: 1.5rem;
    padding-bottom: 1.75rem !important;
  }
}

.code-liveness-result {
  .code-liveness-result__banner {
    margin-bottom: 1rem;
  }

  .code-liveness-result__content {
    display: flex;
    justify-content: center;
  }

  .code-liveness-result__img {
    width: 30rem;
    height: 23rem;
    transform: scaleX(-1);
  }

  video {
    width: 25rem;
    height: 18.75rem;
    border-radius: 0.25rem;

    &.is-not-capturing {
      display: none;
    }

    &.no-camera {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      align-items: center;
      justify-content: center;
      color: $color-white;
      background-color: $color-black;

      .icon {
        color: $color-warning-300;
      }

      .code-c-spinner {
        border-width: 0.25rem;
      }
    }
  }
}
