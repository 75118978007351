.code-setting-profile {
  padding: 1.5rem;

  .code-setting-profile__form {
    max-width: 600px;
    padding: 0 0 1.75rem;

    .code-c-column {
      padding: 0;

      &:first-child {
        margin-right: 1rem;
      }
    }

    .code-c-form-group {
      margin-bottom: 1.875rem;
    }
  }

  .code-setting-profile__buttons {
    margin-top: 4rem;
  }
}
