.code-question-select {
  .code-c-sub-menu-container {
    height: calc(
      100vh - #{$header-height} - #{$breadcrumb-height} - #{$modal-footer-height}
    );
    border-top: 0;
  }

  .code-c-sub-menu-container-right {
    padding-top: 1.5rem;
  }

  .code-question-select__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-c-table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .code-c-sortable-text {
        align-items: center;
      }

      .code-c-tooltip-wrapper {
        margin-left: 0;
      }
    }
  }
}
