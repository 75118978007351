@mixin date-selection-template() {
  color: $color-white;
  background: $color-primary;
  border-radius: 50%;
  transition: all 0.5s;
}

.code-c-date-picker {
  .react-datepicker-popper {
    z-index: $z-index-datapicker;

    .react-datepicker {
      font-family: $font-family-base;
      line-height: 21px;
      color: $color-text;
      letter-spacing: 0;
      border-color: $color-gray;

      .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 95px;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header {
        color: $color-text-vivid;
      }

      .react-datepicker__time-container {
        box-sizing: content-box;

        .react-datepicker__time-list-item {
          height: unset !important;
        }

        .react-datepicker__time-list-item--selected {
          background: $color-primary !important;
        }
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--in-selecting-range {
        @include date-selection-template;
      }

      .react-datepicker__month:not(.react-datepicker__month--selecting-range) {
        .react-datepicker__day--in-range,
        .react-datepicker__day--selected {
          @include date-selection-template;
        }
      }

      .react-datepicker__month--selecting-range {
        .react-datepicker__day--selected:not(.react-datepicker__day--in-range) {
          @include date-selection-template;
        }
      }

      .react-datepicker__day--highlighted {
        &.react-datepicker__day--disabled {
          background-color: $color-green-100;

          &:hover {
            background-color: $color-green-100 !important;
          }
        }
      }

      .react-datepicker__header__dropdown {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
      }
    }
  }

  .code-c-date-picker__readonly-custom-input {
    cursor: pointer;
  }
}
