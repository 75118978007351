.code-challenge-detail-box {
  margin-bottom: 2rem;

  .code-challenge-detail-box__title {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: bold;
    border-bottom: 1px solid $color-border-gray;
  }

  .code-challenge-detail-box__body {
  }
}
