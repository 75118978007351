.code-member-role-edit {
  &.modal .modal-card-body {
    height: 26rem;
    padding-bottom: 2rem; // Override the default large padding
  }

  .modal-card-foot__text {
    .code-c-icon {
      margin-right: 0.25rem;
      color: $color-yellow;
    }
  }
}
