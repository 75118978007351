.readme-container {
  display: flex;
  justify-content: center;

  .readme-markdown {
    padding: 4rem 0;
    margin: 0 3rem;
  }

  .readme-header {
    position: absolute;
    top: 0;
    z-index: $z-index-header + 1;
    display: flex;
    align-items: center;
    width: 100vw;
    height: $header-height;
    padding: 0 0.5rem;
    background-color: transparent;

    .readme-header__title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
