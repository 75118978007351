.code-exam-detail-top {
  .code-exam-detail-top__header-wrapper {
    display: flex;
    align-items: center;
    min-height: 2.25rem;
    padding: 0 1.5rem;
    margin-bottom: 0.5rem;

    .code-exam-detail-top__header {
      h1 {
        margin-bottom: 0.5rem;
      }

      .code-exam-detail-top__exam_id {
        margin-left: 0.5rem;
        font-size: 0.8125rem;
      }
    }
  }

  .code-exam-detail-top__body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0.5rem 1.5rem 2.5rem;

    @include mq-min(1366) {
      grid-template-columns: 2.5fr 1fr;
    }

    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &:nth-child(2) {
        gap: 0.5rem;
      }
    }
  }

  .code-exam-detail-top__side {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    min-height: 0;

    @include mq-min-max(994, 1365) {
      flex-direction: row;
      gap: 1rem;
    }

    .code-exam-detail-top__side-left {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
  }

  .code-exam-detail-top__panel {
    padding: 1rem;
    border: 1px $color-neutral-200 solid;
    border-radius: 0.5rem;
  }

  .code-exam-detail-top__deadline {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media print {
  .code-exam-detail-top {
    .code-exam-detail-top__header-wrapper {
      padding: 0;
    }

    .code-c-exam-segment-tags {
      margin: 0 !important;
    }

    .code-exam-detail-top__body {
      padding: 0.5rem 0 2.5rem;
    }
  }
}
