.code-c-sub-menu-button {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@include mq(sm) {
  .code-c-button.is-stretch {
    width: auto;
  }
}
