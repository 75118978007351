.code-submission-detail {
  .code-submission-detail__header-wrapper {
    display: flex;
    align-items: center;
    min-height: 2.25rem;
  }

  .code-exam-detail__header {
    padding: 0 1.5rem;

    .code-exam-detail__exam_id {
      margin-left: 0.5rem;
      font-size: 0.8125rem;
    }
  }

  .code-submission-detail__header {
    display: flex;
    column-gap: 1.5rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    margin-bottom: 1rem;

    .code-submission-detail__header__left {
      flex-grow: 1;
      min-width: 0;
    }

    .code-submission-detail__header__right {
      flex-basis: 600px;
      flex-grow: 1;
      flex-shrink: 0;
      min-width: 400px;
      max-width: 600px;
    }
  }

  .code-submission-detail__section-title {
    margin-bottom: 1.5rem;

    @include fontSize("heading2", true);
  }

  .code-submission-detail__header-banner {
    margin-bottom: 1rem;

    .code-submission-detail__header-banner-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .code-submission-detail__header-banner-link {
      cursor: pointer;

      span {
        font-weight: bold;
        color: $color-warning-700;
        text-decoration: underline;
      }
    }
  }

  @include mq(hd) {
    .code-submission-detail__header {
      flex-direction: column;

      .code-submission-detail__header__left {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .code-submission-detail__header__right {
        margin-left: 0;
      }
    }
  }

  @keyframes fadeUp {
    0% {
      filter: alpha(opacity=0);
      opacity: 0.1;
      transform: translateY(2rem);
    }

    100% {
      filter: alpha(opacity=100);
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media print {
    .code-submission-detail-header,
    .code-submission-detail__tab,
    .code-c-recalculate-score-button,
    .code-c-playback-button,
    .code-submission-detail__floating-button,
    .code-submission-detail-deadline__button,
    .code-submission-detail-status button {
      display: none;
    }

    .code-c-submission-detail-sub-header,
    .code-c-submission-detail-question__header {
      position: static;
    }

    .code-submission-detail__header__right {
      min-width: 300px !important;
      max-width: 300px !important;

      .code-c-submission-exam-summary {
        flex-basis: 300px !important;
        min-width: 300px !important;

        canvas {
          width: 280px !important;
          height: auto !important;
        }
      }
    }
  }
}
