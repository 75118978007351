.code-c-submission-result-action-log__detail-card {
  padding: 1rem;
  overflow: hidden;
  overflow-y: auto;
  background-color: $color-white;
  border: 1px solid $color-border-gray;

  & > * + * {
    margin-top: 0.75rem;
  }

  &:first-child {
    flex: 1 0 auto;
  }

  section {
    article:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
}

.code-c-submission-result-action-log__card-title {
  font-weight: 600;
  color: $color-gray-600;
  text-transform: uppercase;

  & > * + * {
    margin-top: 0.25rem;
  }
}

.code-c-submission-result-action-log__card-field {
  display: flex;
  gap: 0.25rem;
  align-items: center;

  & > h3 {
    font-weight: 600;
    white-space: nowrap;
  }

  & > p {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.code-c-submission-result-action-log__card-field__question-title {
    align-items: flex-start;
  }

  .code-c-submission-result-action-log__card-field__ip-button {
    margin-left: 0.75rem;
  }

  .code-c-submission-result-action-log__card-field__pasted-text {
    padding: 0.5rem 0 0.5rem 1rem;
    overflow-wrap: anywhere;
    border-left: 1px solid $color-gray-light;
  }
}
