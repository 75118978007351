.code-exam-edit__exam-challenge-set-header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  background: $color-gray-super-light;
  border: 1px solid $color-border-gray;
  border-bottom-width: 0;

  .code-exam-edit__exam-challenge-set-header__left {
    display: flex;
    flex-direction: column;

    .code-exam-edit__exam-challenge-set-header__title {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 0.25rem;

      .code-c-button {
        margin-left: 0.5rem;
      }

      .code-exam-edit__exam-challenge-set-header__options {
        display: flex;
        align-items: center;

        .code-exam-edit__exam-challenge-set-header__label {
          margin-right: 0.25rem;
          white-space: nowrap;

          .code-c-tag {
            background: $color-white;
          }
        }
      }
    }
  }

  .code-exam-edit__exam-challenge-set-header__right {
    display: flex;
    align-items: flex-start;
  }

  .delete {
    margin-top: 0.5rem;
  }
}
