.score-summary {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 1rem;

  .score-summary-boxes {
    display: flex;
    column-gap: 0.75rem;

    .score-summary-box {
      display: flex;
      flex: 1 0;
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: center;
      justify-content: center;
      min-width: 7.75rem;
      height: 8rem;
      padding: 0.5rem;
      color: $color-gray-900;
      text-align: center;
      text-wrap: nowrap;
      background-color: $color-neutral-100;
      border-radius: 0.25rem;

      @include text-sm;

      .label {
        @include text-md;

        margin-bottom: 0;
        font-size: 0.8125rem;
        color: $color-gray-600;
      }

      .result-value {
        @include text-title;

        font-size: 1.75rem;
        font-weight: 700;
        color: $color-black;

        .result-suffix {
          @include text-lg;

          font-weight: 400;
        }
      }
    }
  }

  .score-stats {
    display: flex;
    flex-direction: column;

    .score-stats-row {
      @include text-md;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.25rem 0;
      color: $color-gray-900;

      &:not(:last-child) {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $color-gray-100;
      }
    }
  }
}
