.code-dashboard-challenge-analysis {
  padding: 1.5rem !important;

  .code-dashboard-challenge-analysis__actions {
    display: flex;
    gap: 1rem;
  }

  .code-dashboard-challenge-analysis__applicant-dropdown-menu {
    width: 27%;
  }
}
