.code-c-search-box {
  background-color: $color-white;
  border: 1px solid $color-gray-300;
  border-radius: 0.25rem;

  .code-c-search-box__header {
    @include text-heading3;

    padding: 1rem 1.25rem;
    border-bottom: 1px solid $color-gray-200;
  }

  .code-c-search-box__content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    overflow: hidden;
  }

  .code-c-search-box__container {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
  }

  .code-c-search-box__label {
    @include text-sm;

    margin-bottom: 0.5rem;
  }

  .code-c-search-box__input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    max-height: 8rem;
    padding: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
    cursor: text;
    border: 1px solid $color-neutral-300;
    border-radius: 0.25rem;

    &.active {
      outline: 1px solid $color-gray-900;
    }
  }

  .code-c-search-box__input {
    flex-grow: 1;
    height: unset;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;

    &:focus-visible {
      outline: none;
    }
  }

  .code-c-search-box__clear {
    @include text-sm;

    margin-top: 0.25rem;
    color: $color-neutral-800;
    text-align: right;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      color: $color-neutral-400;
      text-decoration: none;
      cursor: not-allowed;
    }
  }
}
