.code-submit-exam {
  .code-submit-exam__header {
    display: flex;
    margin-bottom: 1rem;
  }

  .code-submit-exam__body {
    text-align: left;
  }

  .code-submit-exam__left {
    flex-shrink: 0;
    margin-right: 1rem;
  }

  .code-submit-exam__right {
    flex-grow: 1;

    .code-submit-exam__text {
      margin-bottom: 2rem;
    }
  }

  .code-submit-exam__challenges {
    margin: 0 2rem 0 0;
    list-style: none;
    border: 1px solid $color-border-gray;

    .code-submit-exam__challenges {
      margin: 0.25rem 0 0 1rem;
      border-right: none;
      border-bottom: none;
    }
  }

  .code-submit-exam__challenge-set {
    padding: 0.25rem 0 0 0.5rem;
    border-bottom: 1px solid $color-border-gray;
  }

  .code-submit-exam__challenge {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid $color-border-gray;
    }

    .code-submit-exam__challenge-status {
      flex-basis: 6rem;
      flex-shrink: 0;
      margin-left: 1rem;
    }

    .code-submit-exam__challenge-not-finished {
      font-weight: bold;
      color: $color-error;
    }
  }

  .code-c-icon {
    color: $color-yellow;
  }
}
