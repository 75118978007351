.code-change-deadline {
  .modal-card {
    height: 100%;
  }

  .code-change-deadline__deadline {
    display: flex;
    margin-bottom: 0.5rem;

    .code-change-deadline__deadline__arrow {
      align-self: center;
      margin: 0 1rem;
    }
  }

  .code-change-deadline__table {
    overflow: visible;
  }

  .code-change-deadline__time-diff {
    color: $color-red;
  }

  .code-change-deadline__msg {
    margin-bottom: 1rem;
  }

  .code-change-deadline__in-progress {
    position: relative;
    padding: 1rem;
    margin: 1rem 0;
    font-weight: bold;
    color: $color-warning;
    text-decoration: underline;
    background-color: $color-warning-background;
    border: 1px solid $color-warning;
  }

  .code-change-deadline__new-version {
    margin-bottom: 0.5rem;
  }
}
