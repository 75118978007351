.code-editor-challenge-list {
  display: flex;
  gap: 3rem;
  max-width: $applicant-container-max-width;
  padding: 3rem 1rem;
  margin: 0 auto;

  .code-editor-challenge-list__container {
    flex: 1;
    min-height: calc(100vh - #{$submit-exam-panel-height} - #{$header-height});
    margin: 0 auto;
    margin-bottom: 2rem;

    .code-editor-challenge-list__alert {
      position: sticky;
      top: calc(#{$header-height} + 1rem);
      z-index: $z-index-header - 1;
      margin-bottom: 2.5rem;
    }

    .code-editor-challenge-list__header-container {
      display: flex;
      margin-bottom: 3rem;
    }

    .code-editor-challenge-list__main {
      flex-grow: 1;
      flex-shrink: 1;
      padding-right: 1.5rem;
    }

    .code-editor-challenge-list__challenge-set {
      .code-c-applicant-challenge-set,
      .code-c-applicant-challenge {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .code-editor-challenge-list__aside {
    flex-basis: 16rem;

    .code-editor-challenge-list__aside__sticky {
      position: sticky;
      top: calc(#{$header-height} + 1rem);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}
