.code-c-cheat-webcam-icon-badge {
  display: flex;
  gap: 0.125rem;
  align-items: center;
  width: fit-content;
  padding: 0.15rem 0.25rem;
  color: $color-neutral-600;
  background-color: $color-neutral-100;
  border-radius: 0.25rem;


  .code-c-cheat-webcam-icon-badge-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;

    &__ok {
      color: $color-neutral-600;
    }

    &__strikethrough {
      color: $color-neutral-300;
    }

    &__warning {
      color: $color-warning-500;
    }
  }

  .code-c-cheat-webcam-icon-badge-verify__ok {
    color: $color-neutral-600;
  }

}
