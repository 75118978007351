.code-submission-evaluation-modal {
  .code-submission-evaluation-modal__title {
    margin-bottom: 1.5rem;
  }

  .code-submission-evaluation-modal__buttons {
    display: flex;
    gap: 1rem;
  }

  &__body {
    padding-bottom: 1.25rem !important;
  }

  &__content {
    display: grid;
    gap: 1.5rem;
    justify-content: center;
  }

  &__reset {
    height: 2.25rem;
  }
}
