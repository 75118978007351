.code-exam-domain-modal {
  .modal-card-body {
    padding: unset;
  }

  .code-exam-domain-modal__domains-bar-count {
    display: flex;
    gap: 0.5rem;
  }

  .code-exam-domain-modal__domains-detail {
    padding: 1.25rem;

    .code-exam-domain-modal__domains-detail__search-bar {
      display: flex;
      margin-bottom: 1rem;

      .code-exam-domain-modal__domains-detail__search-bar__tag {
        padding: 0.25rem 0.5rem;
        white-space: nowrap;
        background: $color-gray-light;
        border: 1px solid $color-border-gray;
        border-right: 0;
        border-radius: 3px 0 0 3px;
      }

      .code-exam-domain-modal__domains-detail__search-bar__input-wrapper {
        position: relative;
        width: 100%;
        border: 1px solid $color-border-gray;
        border-radius: 0 3px 3px 0;

        &::before {
          position: absolute;
          top: 50%;
          left: 5px;
          font-family: FontAwesome;
          color: $color-gray-dark;
          content: "\f002";
          transform: translateY(-50%);
        }

        .search-bar__input-form {
          width: 100%;
          height: 100%;
          padding-left: 1.5rem;
          border: none;
          border-radius: 0 3px 3px 0;
        }
      }
    }

    .code-exam-domain-modal__domains-detail__list {
      border: 1px solid $color-border-gray;
      border-bottom: 0;

      .code-exam-domain-modal__domains-detail__list-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 0.5rem;
        border-bottom: 1px solid $color-border-gray;

        .code-exam-domain-modal__domains-detail__list-row__text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .code-exam-domain-modal__domains-detail__list-no-result {
        padding: 0.25rem 0.5rem;
        color: $color-gray-dark;
        border-bottom: 1px solid $color-border-gray;
      }
    }
  }

  .code-exam-domain-modal__domains-input {
    padding: 1.25rem;
  }
}
