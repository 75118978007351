.code-c-submission-result-action-log__table-row {
  @include action-log-grid-template;

  align-items: center;
  min-height: 4rem;
  padding: 1rem;
  line-height: 1.125rem;

  &> * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.is-clickable {
    &:hover {
      background: transparentize($color-gray-light, 0.75);
    }
  }

  .time-column {
    color: $color-gray-600;
  }

  & > .code-c-submission-result-action-log__row-title {
    display: block;
  }
}

.code-c-submission-result-action-log__row-action {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;

  & > .code-c-submission-result-action-log__row-action-tag {
    flex: 1;
  }

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > .code-c-icon {
    flex: none;
  }
}
