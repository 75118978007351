.code-c-date-time {
  display: flex;
  flex-direction: column;

  .code-c-date-time__date {
    color: $color-gray-700;

    @include fontSize("normal", true);
  }

  .code-c-date-time__time {
    color: $color-gray-600;
  }

  .code-c-date-time__time-wrapper {
    display: flex;
    align-items: center;

    .code-c-date-time__tooltip {
      text-align: left;
    }
  }
}
