.challenge-result-preview__button-group {
  position: fixed;
  top: calc($header-height - 2px);
  right: 0;
  z-index: 6;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 40px;
  margin: 0 1rem;
  line-height: 40px;

  a {
    cursor: pointer;
  }

  .code-explanation-button--disabled,
  .local-code-files-button--disabled {
    display: block;
    cursor: not-allowed;

    a {
      color: $color-gray !important;
      cursor: not-allowed;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
