.code-c-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-overlay;

  &.is-full-screen {
    position: fixed;
  }
}
