

  .code-c-autocomplete__input-container {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    min-width: 0;
    padding: 0.5rem;
    padding-right: 1.875rem;
    cursor: default;
    background-color: $color-white;
    border: 1px solid $color-gray-300;
    border-radius: 4px;

    &.grow {
        flex-grow: 1;
    }

    &.no-border {
        border: none;
    }

    &:focus {
        border: 1px solid black;
        outline: none;
    }

    &.is-danger {
      border: 1px solid red;
    }

    &-text-input {
        flex-grow: 1;
        text-overflow: ellipsis;
        border: none; 

        &:focus {
            border: none;
            outline: none;
        }

    }

    &-caret-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        color: $color-gray-500;
        pointer-events: none;
        transform: translateY(-50%);
    }
}

