.code-guest-container {
  .code-c-hero-tile {
    margin: 3.75rem auto;
  }

  .code-c-page-title {
    margin-bottom: 2.5rem;
  }

  .code-c-form {
    max-width: 20rem;
  }
}
