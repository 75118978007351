// ----------------------------
// Override bulma style
// ----------------------------

$primary: $color-primary;
$info: $color-gray-900;
$success: $color-green-500;
$danger: $color-red-500;
$link: $color-link;

$family-sans-serif: $font-family-base;

$navbar-height: $header-height;

$menu-item-color: $color-white;
$menu-item-hover-color: $color-white;
$menu-item-hover-background-color: $color-sidemenu-active-background;
$menu-item-active-background-color: $color-sidemenu-active-background;

$tabs-link-padding: 0.5em 1.75em;
$tabs-link-active-color: $color-text;
$tabs-boxed-link-active-background-color: $color-gray-super-light;
