
.barchart-row {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  .barchart-wrapper {
    display: flex;
    column-gap: 1px;
    height: 40px;
    background: $color-gray-100;
    border-radius: 3px;

    &.has-white-bg {
      background: $color-white;
    }

    .bar {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;

      &.auto-grow {
        flex-grow: 1;
      }
    }

    .bar-failed {
      background: $color-red-100;
      border-radius: 3px 0 0 3px;
    }

    .bar-passed {
      background: $color-success-100;
      border-radius: 0 3px 3px 0;
    }

    .bar-submitted {
      background: $color-neutral-200;
    }
  }

  .bar-ticks {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    line-height: 1.125rem;
    color: $color-gray-600;
  }
}
