.code-c-progress-bar {
  width: 100%;
  height: 0.325rem;
  background-color: $color-neutral-200;
  border-radius: 2px;

  &.code-c-progress-bar-- {
    &primary {
      .code-c-progress-bar__bg {
        background-color: $color-primary-400;
      }
    }

    &neutral {
      .code-c-progress-bar__bg {
        background-color: $color-neutral-300;
      }
    }
  }

  .code-c-progress-bar__bg {
    height: 0.325rem;
    border-radius: 2px;
  }
}
