.code-c-evaluate-icon {
  > .fa-stack-2x {
    font-size: 1em;
  }

  > .fa-check {
    position: absolute;
    top: -20%;
    left: -5%;
    font-size: 0.5em;
  }
}
