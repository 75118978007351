.code-admin-organization-new {
  .level-left {
    display: block;
    flex: 1;
  }

  .level-right {
    flex: 1;
  }

  .code-admin-organization-new__category-container {
    display: flex;
    gap: 1.25rem;

    .code-admin-organization-new__category-container__sub-category {
      select {
        width: 23rem;
      }
    }
  }

  .sharing-group__settings-block {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding-left: 1rem;
  }

  .integrations__settings-block {
    max-width: 30rem;
    padding: 1rem;
    margin-bottom: 3rem;
    border: 1px solid $color-border-gray;
    border-radius: 6px;
  }

  .integrations__track-token {
    .code-c-input {
      width: 100%;
    }
  }

  .code-admin-organization-new__verify-org {
    margin-left: 0.5rem;
    color: $color-white;
    background: $color-gray-dark;
  }

  .ip-whitelist {
    .code-c-tooltip-wrapper.tier-tooltip {
      display: block;
    }
  }
}
