.code-question-list {
  height: $challenge-list-container-height;

  .code-c-sub-menu-container {
    height: $challenge-list-container-height;
    border-top: 0;
  }

  .code-question-list-header {
    padding: 1.5rem 1.5rem 0;
  }

  .code-question-list__table {
    margin: 0 1.5rem;
    overflow: visible;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .code-c-sortable-text {
        align-items: center;
      }
    }
  }

  .code-question-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }
}
