td.code-c-column {
  min-width: 7.5rem;
  padding: 1.25rem 0.75rem;
  word-break: break-word;
  border: 0;

  &.no-padding-x {
    padding-right: 0;
    padding-left: 0;
  }

  &.no-padding-left {
    padding-left: 0;
  }

  &.no-padding-right {
    padding-right: 0;
  }

  &:hover {
    background: none;
  }

  .table.is-bordered & {
    border-bottom: 1px solid $color-neutral-200;
  }

  .table.is-inside-bordered &:not(:last-child) {
    border-right: 1px solid $color-neutral-200;
  }

  &.row-header {
    background-color: $color-neutral-100;
  }
}
