.code-detail-review-section {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid $color-border-gray;
  border-radius: 8px;

  .code-detail-review-section__summary {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    background: $color-white;
    border: none;
    outline: 0;

    > .code-submission-detail__section-title {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }

    &.with-hover {
      cursor: pointer;
      transition: background-color 0.25s;

      &:hover {
        background: $color-neutral-100;
      }
    }
  }

  .code-detail-review-section__solo-review {
    padding: 0;
    border: 0;
  }

  .code-detail-review-section__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0; // Override for when we don't show the tracker bar if there are no reviews
    }

    .code-detail-review-section__header__title {
      display: flex;
      gap: 0.5rem;

      h3 {
        @include text-heading3;
      }
    }
  }

  .code-detail-review-section__reviews {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 1rem;
    border: 0;
  }
}
