.code-submission-ip-address-modal {
  .code-submission-ip-address-modal__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .code-submission-ip-address-modal__header__info {
      color: $color-neutral-700;
    }

    .code-submission-ip-address-modal__header__matching-ip {
      display: flex;
      gap: 0.5rem;

      :nth-child(1) {
        font-weight: bold;
      }

      :nth-child(2) {
        color: $color-warning-700;
      }
    }
  }

  .code-submission-ip-address-modal__ip-address {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .code-submission-ip-address-modal__ip-address__text {
      color: $color-warning-700;
    }
  }

  .code-submission-ip-address-modal__overall-score {
    padding-left: 0;
  }

  .modal-card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .code-c-table {
      overflow: visible;
    }

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }



  .code-submission-ip-address-modal__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }
}
