.code-c-challenge-title {
  .code-c-challenge-title__header {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .code-c-challenge-title__tag {
      color: $color-white;
      background: $color-gray-dark;
    }

    .code-c-challenge-title__not-available {
      display: block;
      justify-content: unset;
      width: fit-content;
      max-width: 100%;
      margin-bottom: 0.5rem;
      overflow: hidden;
      color: $color-red-500;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: $color-white;
      border: 1px solid $color-red-500;
    }
  }

  .code-c-challenge-title__body {
    display: inline-flex;
    color: $color-gray-700;
    text-decoration: none;
    cursor: text;

    .code-c-challenge-title__title {
      font-weight: bold;
      text-decoration: none;
      word-break: break-all;
    }

    &.clickable {
      color: $color-link;
      cursor: pointer;

      &:hover {
        .code-c-challenge-title__title {
          text-decoration: underline;
        }
      }
    }
  }



  .code-c-challenge-title__open-button {
    width: 21px;
    height: 21px;
  }
}
