.code-c-form-group {
  max-width: 50rem;

  &.is-inline-form {
    line-height: 36px;

    .code-c-input,
    .code-c-select,
    .code-c-checkbox,
    .code-c-radio,
    .code-c-date-picker {
      display: inline-block;
      margin-right: 0.5rem;
    }

    .code-c-label {
      line-height: initial;
    }
  }

  &.is-centered {
    display: flex;
    justify-content: center;

    .code-c-button:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &.is-full-width {
    max-width: unset;
  }

  &:not(:last-child) {
    margin-bottom: 1.875rem !important;
  }
}
