.code-c-review-score-icon {
  i.score-icon {
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.is-strongly-approved {
    i.score-icon {
      background-image: url("./assets/images/review_strongly_approved_v2.svg");
    }
  }

  &.is-approved {
    i.score-icon {
      background-image: url("./assets/images/review_approved_v2.svg");
    }
  }

  &.is-rejected {
    i.score-icon {
      background-image: url("./assets/images/review_rejected_v2.svg");
    }
  }

  &.is-strongly-rejected {
    i.score-icon {
      background-image: url("./assets/images/review_strongly_rejected_v2.svg");
    }
  }
}