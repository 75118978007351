.code-exam-finished {
  display: flex;
  justify-content: center;
  height: calc(100vh - $header-height);
  padding: 3rem 1rem 6rem;
  background: $color-white;

  .code-exam-finished__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 720px;
    margin: auto;
  }

  .code-exam-finished__title {
    margin-bottom: 1rem;
    font-family: $font-family-heading;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;

    &.code-exam-finished__title__error {
      margin-bottom: 0.5rem;
    }

    &.code-exam-finished__title__next-steps {
      font-size: 1.25rem;

    }
  }

  .code-exam-finished__subtitle {
    max-width: 31rem;
    margin: auto;;
  }

  .code-exam-finished__header {
    margin-bottom: 2rem;
    text-align: center;

    &.code-exam-finished__header__error {
      margin-bottom: 5rem;
    }
  }

  .code-exam-finished__image-container {
    max-width: 400px;
    margin-bottom: 2rem;

    .code-exam-finished__image {
      background: $color-neutral-500;
    }

    &.code-exam-finished__error {
      margin-bottom: 1rem;
    }
  }

  .code-exam-finished__follow-up {
    width: 100%;
    margin-bottom: 2rem;

    p {
      text-align: center;
    }
  }
}
