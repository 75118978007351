.code-c-menu {
  position: absolute;
  z-index: $z-index-menu;
  width: $menu-width;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  background: $color-gray-800;

  .code-c-menu__list {
    a {
      padding: 1.5em 0;
      font-size: 0.8em;
      text-align: center;
      letter-spacing: -0.03em;

      &:hover {
        text-decoration: none;
        background-color: $color-gray-700;
      }
    }

    .code-c-icon {
      margin-bottom: 0.5em;
      font-size: 1.5em;
    }
  }

  .code-c-menu__list-item-link {
    &.active {
      color: $color-primary-400;
      background: $color-gray-900;
    }
  }
}

@include mq(sm) {
  .code-orgs-container aside.code-c-menu {
    position: sticky;
    z-index: $z-index-header - 1;
    width: 100%;
    height: 5rem;

    .menu-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & div {
        flex-grow: 1;
      }
    }

    .code-c-menu__list-item-link span {
      display: block;
      width: 100%;
    }

    li.code-c-menu__list-item {
      flex-grow: 1;
      border-top: none;
    }
  }

  .code-app-container .code-orgs-container .code-orgs-container__body {
    padding-left: 0;
  }
}
