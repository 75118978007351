.code-c-file-upload-modal__dropzone {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: $color-neutral-100;
  border-radius: 0.25rem;

  & > div {
    text-align: center;
  }

  &:hover {
    cursor: pointer;

    & .code-c-file-upload-modal__browse-label {
      text-decoration: underline;
    }
  }
}

.code-c-file-upload-modal__description {
  color: $color-gray-800;
}

.code-c-file-upload-modal__content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.code-c-file-upload-modal__drop-icon {
  width: 5rem;
  height: 5rem;
  font-size: 4rem;
  color: $color-neutral-200;
}

.code-c-file-upload-modal__browse-label {
  color: $color-neutral-800;
}

.code-c-file-upload-modal__drop-label {
  color: $color-neutral-500;
}

.code-c-file-upload-modal__file-rules {
  color: $color-gray-500;
}

.code-c-file-upload-modal__files {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.code-c-file-upload-modal__files-error,
.code-c-file-upload-modal__files-warning {
  @include text-md;

  display: flex;
  flex-direction: column;
  color: $color-gray-800;
}

.code-c-file-upload-modal__files-error-list,
.code-c-file-upload-modal__files-warning-list {
  list-style: inside;
}
