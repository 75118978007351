.code-c-loading {
  position: absolute;
  background: transparentize($color-gray-super-dark, 0.5);

  .code-c-spinner {
    position: absolute;
    top: calc(50% - #{$spinner-height / 2});
    left: calc(50% - #{$spinner-width / 2});
    transform: translate(-50%, -50%);
  }

  &.is-open {
    z-index: $z-index-loading;
    animation: fadeIn 0.5s forwards;
  }

  &.hide-background {
    background: $color-gray-super-dark;
    animation: none;
  }

  &.is-full-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;

    .code-c-spinner {
      top: unset;
      left: unset;
      transform: unset;
    }
  }

  &.no-overlay {
    background: none;
  }
}
