@keyframes hide-scroll {
  from, to {
    overflow: hidden;
  }
}

.code-c-popup {
  display: flex;
  flex-direction: column;
  width: 424px;
  max-height: 90vh;
  overflow: hidden;
  background: $white;
  border-color: $color-primary-500;
  border-style: solid;
  border-width: 8px 1px 1px;
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(20, 20, 19, 0.16);
  opacity: 1;
  transition:
    width 500ms ease 0s,
    max-height 500ms ease 0s,
    opacity 500ms ease-out 0s;
  transform-origin: bottom right;

  &.initial-style {
    width: 0;
    max-height: 0;
    opacity: 0;

    .popup-body {
      overflow: hidden;
    }
  }

  .collapsible-content {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow: hidden;
    opacity: 1;
    transition:
      opacity 500ms ease-out 0s,
      max-height 500ms ease 0s;

    &.is-collapsed {
      max-height: 0;
      opacity: 0;
    }
  }

  .popup-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    cursor: pointer;
    border-bottom: 1px solid $color-gray-200;
    border-radius: 8px 8px 0 0;

    a.collapse-button {
      color: $color-gray-900;

      &:hover {
        color: $color-gray-600;
      }
    }
  }

  .popup-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    overflow-y: auto;
    animation: hide-scroll 500ms ease-out 0s;
  }

  .popup-footer {
    display: flex;
    column-gap: 1.5rem;
    justify-content: center;
    padding: 1.5rem;
    border-top: 1px solid $color-gray-200;
  }
}
