// --------------------------------------------
// font
// --------------------------------------------
@font-face {
  font-family: CodechechYUGothicM;
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Med"), local("YuGothic-Rular"); // for Window8.1
}

@font-face {
  font-family: CodecheckYUGothicM;
  font-weight: bold;
  src: local("YuGothic-Bold"), local("Yu Gothic"); // for chrome
}

@font-face {
  font-family: CodecheckPop;
  font-style: normal;
  font-weight: normal;
  src: url("./assets/font/m1p_sub1-webfont.woff") format("woff"),
    url("./assets/font/m1p_sub1-webfont.woff2") format("woff2");
}

$font-family-base: "Helvetica Neue", arial, "HiraginoSans-W3", "HiraKakuProN-W3",
  "CodechechYUGothicM", "Meiryo", sans-serif;
$font-family-heading: "HelveticaNeue-Bold", arial,
  "HiraginoSans-W6", "HiraKakuProN-W6", "CodechechYUGothicM", "Meiryo", sans-serif;
$font-family-pop: "CodecheckPop", "Hiragino Maru Gothic ProN", "Meiryo", fantasy;

// --------------------------------------------
// color
// --------------------------------------------
$color-primary-900: #191f10;
$color-primary-800: #293812;
$color-primary-700: #445c1f;
$color-primary-600: #658a2c;
$color-primary-500: #9bca54;
$color-primary-400: #bee08b;
$color-primary-300: #daf0bb;
$color-primary-200: #ebf5dc;
$color-primary-100: #f6faf0;
$color-primary-list: [
  $color-primary-100,
  $color-primary-200,
  $color-primary-300,
  $color-primary-400,
  $color-primary-500,
  $color-primary-600,
  $color-primary-700,
  $color-primary-800,
  $color-primary-900,
];


$color-neutral-900: #0a3b52;
$color-neutral-800: #184d66;
$color-neutral-700: #36647a;
$color-neutral-600: #4d768a;
$color-neutral-500: #7295a6;
$color-neutral-400: #93acb8;
$color-neutral-300: #b4c4cc;
$color-neutral-200: #dde6eb;
$color-neutral-100: #f5f8fa;

$color-secondary-900: #3d111c;
$color-secondary-800: #5c1a2b;
$color-secondary-700: #8f2540;
$color-secondary-600: #b84966;
$color-secondary-500: #dc6b88;
$color-secondary-400: #e694a9;
$color-secondary-300: #f0bdca;
$color-secondary-200: #f9e7eb;
$color-secondary-100: #faf5f7;

$color-gray-900: #141413;
$color-gray-800: #2d2e2b;
$color-gray-700: #4b4d49;
$color-gray-600: #6e706b;
$color-gray-500: #8c8f89;
$color-gray-400: #acada9;
$color-gray-300: #cbccc9;
$color-gray-200: #e0e1df;
$color-gray-100: #eeefee;

$color-red-900: #360a0a;
$color-red-700: #851b1b;
$color-red-500: #e05a5a;
$color-red-300: #f09e9e;
$color-red-100: #f7dada;

$color-yellow-900: #462704;
$color-yellow-700: #955409;
$color-yellow-500: #f18e1e;
$color-yellow-300: #f7be7e;
$color-yellow-100: #fdeede;

$color-green-900: #134329;
$color-green-700: #237b4b;
$color-green-500: #31ab68;
$color-green-300: #a2e4c0;
$color-green-100: #d5f3e3;

$color-white: #fff;
$color-black: #000;

$color-error-900: #0A3B52;
$color-error-800: #5C1215;
$color-error-700: #851B1B;
$color-error-600: #B23B39;
$color-error-500: #E05A5A;
$color-error-300: #F09E9E;
$color-error-100: #F7DADA;

$color-warning-900: #462704;
$color-warning-800: #6C3D08;
$color-warning-700: #955409;
$color-warning-600: #C27013;
$color-warning-500: #F18E1E;
$color-warning-300: #F7BE7E;
$color-warning-100: #FDEEDE;

$color-success-900: #134329;
$color-success-800: #1B5E3A;
$color-success-700: #227849;
$color-success-600: #2A9359;
$color-success-500: #31AB68;
$color-success-300: #A2E4C0;
$color-success-100: #E1F5EA;

$color-primary: $color-primary-500;

$color-error: $color-red-500;
$color-error-background: $color-red-100;

$color-success: $color-green-500;
$color-success-background: $color-green-100;

$color-warning: $color-yellow-500;
$color-warning-background: $color-yellow-100;

$color-text-vivid: $color-gray-900;
$color-text: $color-gray-800;

$color-red: $color-red-500;
$color-yellow: $color-yellow-500;

$color-gray-super-dark: $color-gray-900;
$color-gray-dark: $color-gray-800;
$color-gray: $color-gray-500;
$color-gray-light: $color-neutral-200;
$color-gray-super-light: $color-neutral-100;

$color-border-gray: $color-gray-200;
$color-link: $color-neutral-800;

$color-report-applicant: $color-green-900;
$color-report-within-exam: $color-green-500;
$color-report-within-country: $color-secondary-500;
$color-report-rank-ss: $color-primary-600;
$color-report-rank-a: $color-green-700;
$color-report-rank-b: $color-yellow-700;
$color-report-rank-d: $color-secondary-600;
$color-report-rank-f: $color-red-700;

// -------------------------------------------
// Default theme
// -------------------------------------------

$app-background-color: $color-gray-super-light;

$header-color: $color-gray-700;
$header-background-color: $color-neutral-100;

$footer-background-color: $color-gray-super-light;

$breadcrumb-background-color: $color-white;

$color-sidemenu-background: $color-gray-800;
$color-sidemenu-active-background: $color-gray-900;

$color-book-modal-background: #1a9adb;

// --------------------------------------------
// size
// --------------------------------------------
$header-height: 3rem;
$footer-height: 3.125rem;
$list-tab-height: 2.313rem;
$contents-list-padding-top: 1.5rem;

$hero-tile-max-width: 40rem;
$hero-tile-max-width-large: 63rem;

$book-style-max-width: 980px;
$applicant-container-max-width: 1200px;

$error-tile-max-width: 50rem;

$menu-width: 5rem;
$submission-menu-width: 15rem;

$table-head-height: 2.5rem;
$breadcrumb-height: 2.5rem;
$modal-footer-height: 6rem;

$spinner-height: 100px;
$spinner-width: 100px;

$submit-exam-panel-height: 7rem;
$exam-challenge-set-header-height: 4rem;

$challenge-list-container-height: calc(
  100vh - #{$header-height + $breadcrumb-height + $list-tab-height + $contents-list-padding-top}
);

$code-explanation-button-height: 40px;

// --------------------------------------------
// z-index
// --------------------------------------------

$z-index-loading: 25;
$z-index-account-menu: 21;

// z-index-modal: 20;
$z-index-spinner: 20;
$z-index-overlay: 19;
$z-index-menu: 19;
$z-index-challenge-header: 19;
$z-index-header: 19;
$z-index-footer: 18;
$z-index-datapicker: 18;
$z-index-breadcrumbs: 18;
$z-index-action-overlay: 17;
$z-index-tooltip: 16;
$z-index-preview-mode: 16;
$z-index-message-bar: 15;
$z-index-appeal-comment: 6;
$z-index-editor-overlay: 5;
$z-index-pagination: 4;

// --------------------------------------------
// media query
// --------------------------------------------

$breakpoint-sm: 414px !default; /* iPhone XR */
$breakpoint-md: 768px !default;
$breakpoint-lg: 1000px !default;
$breakpoint-xl: 1240px !default;
$breakpoint-hd: 1400px !default;

$breakpoints: (
  sm: "screen and (max-width: #{$breakpoint-sm})",
  md: "screen and (max-width: #{$breakpoint-md})",
  lg: "screen and (max-width: #{$breakpoint-lg})",
  xl: "screen and (max-width: #{$breakpoint-xl})",
  hd: "screen and (max-width: #{$breakpoint-hd})",
);

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mq-min-max($min, $max) {
  @media screen and (min-width: "#{$min}px") and (max-width: "#{$max}px") {
    @content;
  }
}

@mixin mq-min($min) {
  @media screen and (min-width: "#{$min}px") {
    @content;
  }
}

@mixin mq-max($max) {
  @media screen and (max-width: "#{$max}px") {
    @content;
  }
}

// might need to rename and make this the standard as more designs
// take into account responsiveness
$breakpoints-dashboard: (
  sm: "screen and (max-width: 744px)",
  md: "screen and (max-width: 1024px)",
  lg: "screen and (max-width: 1440px)",
);

@mixin mq-dashboard($breakpoint: md) {
  @media #{map-get($breakpoints-dashboard, $breakpoint)} {
    @content;
  }
}
