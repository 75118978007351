.code-c-challenge-major-update-confirm {
  .code-c-challenge-major-update-confirm__body {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .code-c-challenge-major-update-confirm__body-box {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    &:not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: 1px solid $color-border-gray;
    }
  }

  .code-c-challenge-major-update-confirm__body-title {
    display: flex;
    column-gap: 0.5rem;
  }

  .code-c-challenge-major-update-confirm__body-version {
    display: flex;
    column-gap: 0.5rem;
  }
}
