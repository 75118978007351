.code-c-autocomplete {
    &__option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 4px;
        padding-right: 6px;
        padding-bottom: 4px;
        padding-left: 12px;
        cursor: pointer;
        border-radius: 4px;

        &[aria-selected='true'] {
            background-color: $color-neutral-100;
        }

        &:hover {
            background-color: $color-neutral-100;
        }

        &-label {
            margin-right: auto;
        }

        &-default {
            margin-right: 8px;
            font-size: 0.75rem;        
            color: $color-gray-500;
        }
    }
}