.code-c-switch {
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 1em;

  .code-c-switch__label {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    min-width: 1rem;
    min-height: 1rem;

    &.is-readonly {
      color: $color-gray-600;
      cursor: not-allowed;

      strong {
        color: $color-gray-600;
      }
    }

    // Hide the original checkbox
    .code-c-switch-checkbox {
      position: absolute;
      flex-grow: 0;
      flex-shrink: 0;
      align-self: flex-start;
      margin: 0;
      margin-top: 2px;
      cursor: pointer;
      opacity: 0;

      &[readonly] {
        cursor: not-allowed;
      }
    }

    .code-c-switch__label__text {
      flex-grow: 1;
      flex-shrink: 1;
      word-break: break-word;
    }

    // Style the checkmark

    .code-c-switch__checkmark-wrapper {
      display: flex;
      align-items: center;
      height: 1rem; // Note: can be overridden using the initialLineHeight prop
    }

    .code-c-switch__checkmark-container {
      position: relative;
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 1.25rem;
      margin: 0.125rem;
      background-color: $color-neutral-200;
      border-radius: 1rem;
      transition: background-color 0.1s ease-in;

      .code-c-switch__checkmark {
        &::after {
          position: absolute;
          top: 0.125rem;
          left: 0.125rem;
          width: 1rem;
          height: 1rem;
          content: "";
          background: $color-white;
          border-radius: 50%;
          box-shadow: 0 2px 4px 0 rgba(39, 39, 39, 0.10);
          transition: transform 0.1s ease-in;
        }
      }
    }

    input {
      &[disabled] {
        & ~ .code-c-switch__checkmark-container {
          background-color: $color-gray-200;
        }
      }

      &:checked {
        & ~ .code-c-switch__checkmark-container {
          background-color: $color-primary-500;

          .code-c-switch__checkmark::after {
            transform: translateX(1rem);
          }
        }

        &[disabled] {
          & ~ .code-c-switch__checkmark-container {
            background-color: $color-primary-300;
          }
        }
      }

      &:focus-visible {
        & ~ .code-c-switch__checkmark-container {
          &::before {
            position: absolute;
            inset: -0.25rem;
            content: "";
            border: 2px solid $color-primary-500;
            border-radius: 1rem;
          }
        }
      }
    }
  }
}
