.code-c-switch-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: normal;
  justify-content: stretch;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}
