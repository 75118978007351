.code-c-applicant-challenge-set {
  position: relative;
  background: $color-gray-super-light;

  .code-c-applicant-challenge-set__container {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
  }

  .code-c-applicant-challenge-set__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .code-c-applicant-challenge-set__header-title {
    @include fontSize("heading1", true);

    margin-right: 0.5rem;
  }

  .code-c-applicant-challenge-set__header-count {
    @include fontSize("heading1", true);
  }
}
