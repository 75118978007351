.code-exam-edit__detail_forms {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  .form-alert-modal {
    .fa-exclamation-triangle {
      color: $color-yellow !important;
    }
  }
}
