.code-exam-create__autofilter {
  .code-exam-create_settings__auto-filter {
    display: flex;

    .code-exam-create_settings__auto-filter__form {
      .score-section {
        width: 100%;
      }
    }
  }
}
