.code-exam-edit__action-area {
    position: fixed;
    bottom: 0;
    z-index: $z-index-action-overlay;
    display: flex;
    justify-content: center;
    width: calc(100% - $submission-menu-width - $menu-width);
    padding: 1rem 0;
    background: $color-white;
    border-top: 1px solid $color-border-gray;
}