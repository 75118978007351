.code-c-programming-language-stats {
  display: flex;

  .code-c-programming-language-stats__avg-time-column {
    width: 4.5rem;
  }

  .code-c-programming-language-stats__chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1rem;

    .code-c-programming-language-stats__chart-legend {
      font-size: 0.625rem;
    }
  }
}
