.code-c-search-box__applicant-tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  overflow: hidden;

  & > li {
    overflow: hidden;
  }

  .code-c-tag {
    width: 100%;
  }
}

.code-c-search-box__applicant-results {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;

  &.active {
    max-height: 16rem;
    overflow-y: auto;
    transition: max-height 0.25s ease-in;
  }
}

.code-c-search-box__applicant {
  .code-c-search-box__applicant-card {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;

    &:hover {
      cursor: pointer;
      background-color: $color-neutral-100;
    }
  }

  .code-c-search-box__applicant-name {
    @include text-md;

    color: $color-gray-800;
  }

  .code-c-search-box__applicant-mail {
    @include text-xs;

    color: $color-gray-600;
  }
}
