.code-project-role-form {
  .code-project-role-form__project-select__title-label {
    margin-bottom: 0;
  }

  .code-project-role-form__project-select {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0.5rem;
    vertical-align: top;
  }

  .code-member-role-form {
    margin: 0.5rem 0 1.5rem 1rem;
  }
}
