.code-exam-create__preset-exam__cover-image-spacer {
  margin-bottom: 2.1875rem // Match the height of the image upload button so that the container doesn't resize when readOnly.
}

.code-exam-create__preset-exam__cover-image__upload {
  .code-c-image-upload__drag-area {
    border: none;
    border-radius: 0;
  }

  .code-c-avatar__image {
    border-radius: 0;
  }

  .code-c-image-upload__button {
    margin-top: 0.5rem;
  }
}

.code-exam-create__preset-exam__cover-message {
  margin-bottom: 0.69rem;
}
