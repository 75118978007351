.code-setting {
  .code-setting__header {
    display: flex;
    align-items: center;
    min-height: 2.25rem;
  }

  .code-c-sub-menu {
    padding: 1.5rem 1.5rem 0;
  }
}
