.code-c-horizontal-funnel-chart {
  @for $i from 1 through 8 {
    g:nth-child(8n + #{$i}) {
      path.funnel-section {
        fill: nth($color-primary-list, $i + 1);
      }
    }
  }

  path.funnel-section:hover {
    fill-opacity: 0.75;
  }

  text {
    @include text-md;
  }

  tspan.label-percentage {
    @include text-lg;

    font-weight: 700;
    fill: $color-gray-800;
  }

  tspan.label-count,
  tspan.label-name {
    fill: $color-gray-600;
  }

  tspan.label-count {
    @include text-xs;
  }

  tspan.label-name {
    @include text-sm;
  }
}
