.code-c-average-stats {
  .code-c-average-stats__table {
    width: 32rem;

    .code-c-average-stats__table__title {
      width: 21rem;
      text-align: center;
      white-space: nowrap;
      background: $color-gray-super-light;
      border-right: 1px solid $color-border-gray;
    }
  }
}
