.code-submission-detail-memo {
  min-width: 18.75rem;

  .code-submission-detail-memo__label {
    margin-bottom: 0.25rem;

    span {
      font-weight: 700;
      color: $color-gray-900;
      white-space: nowrap;
    }
  }

  .code-submission-detail-memo__placeholder {
    color: $color-gray-500;
  }

  .code-submission-detail-memo__add-notes {
    @include text-md;

    position: relative;
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    overflow: hidden;
    line-height: 1.25rem;
    background-color: $color-white;
    border-radius: 0.25rem;

    &.editable {
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: $color-gray-100;

        .code-c-rich-markdown__body {
          background-color: $color-gray-100;
        }

        .code-submission-detail-memo__add-notes__fade {
          background: linear-gradient(transparent, $color-gray-100);
        }
      }
    }

    &.limited-height {
      max-height: 9.75rem;
    }

    &.code-submission-detail-memo__add-notes__empty {
      color: $color-gray-500;
    }

    .code-submission-detail-memo__markdown {
      color: $color-gray-500;

      .code-c-rich-markdown__body {
        border: none;

        .code-c-markdown {
          padding: 0;
        }
      }

      .markdown-body {
        font-family: inherit !important;
        overflow-wrap: anywhere;

        code {
          white-space: pre-wrap;
        }

        p {
          @include text-md;

          line-height: 1.25rem;
        }
      }
    }

    .code-submission-detail-memo__add-notes__fade {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(transparent, $color-white);
    }
  }

  .code-submission-detail-memo__add-notes__see-more {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0.5rem;

    @media print {
      display: none;
    }
  }

  .code-submission-detail-memo__text-area {
    margin-bottom: 0.5rem;

    textarea {
      @include text-md;

      line-height: 1.25rem;
      resize: none;
    }

    textarea::placeholder {
      color: $color-gray-500;
    }
  }

  .code-submission-detail-memo__buttons {
    display: flex;
    gap: 0.5rem;

    button {
      padding: 0 0.75rem;
    }
  }

  .code-submission-detail-memo__last-updated {
    @include text-xs;

    margin-left: 0.5rem;
    color: $color-gray-600;
    word-break: break-word;
  }
}
