.code-submission-download-modal {
  .code-c-form-group {
    display: flex;
    flex-direction: column;

    > .code-c-radio {
      margin-left: 0 !important;
    }

    .code-c-tooltip-wrapper {
      width: max-content;
    }
  }

  .modal-card {
    width: 700px;
  }
}

.code-submission-download-modal__body {
  padding: 1.5rem;
}

.code-submission-download-modal__field {
  display: flex;
  flex-direction: row;
  gap: 2rem;

  & > .code-c-form-group {
    flex: 1 1 0%;
  }
}

.code-submission-download-modal__field-label {
  margin-bottom: 1rem !important;
  font-size: 1rem;
  font-weight: 600;
  color: $color-gray-900;
}

.code-submission-download-modal__field-select {
  width: 100%;

  .select {
    width: 100%;
    color: $color-gray-500;
  }
}

.code-submission-download-modal__radio {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  background-color: transparent;
  border-radius: 0.25rem;
  transition: background-color 0.25s cubic-bezier(0.42,0,0.58,1);

  &.is-clickable:hover {
    cursor: pointer;
    background-color: $color-gray-100;
  }

  &:not(.is-readonly) {
    .code-submission-download-modal__radio-title {
      color: $color-gray-900;
    }

    .code-submission-download-modal__radio-subtitle {
      color: $color-gray-600;
    }
  }
}

.code-submission-download-modal__radio-label {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

.code-submission-download-modal__radio-label-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: $color-neutral-500;
  background-color: $color-neutral-200;
  border-radius: 0.25rem;
}

.code-submission-download-modal__radio-subtitle {
  font-size: 0.75rem;
}

.code-submission-download-modal__columns-radio {
  display: inline-flex;
  margin-bottom: 0.5rem;

  &:hover {
    cursor: default;
  }

  & > .code-c-radio__label {
    &:hover {
      cursor: pointer;
    }
  }
}

.code-submission-download-modal__selected-columns {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid $color-gray-200;
  border-radius: 0.25rem;
}

.code-submission-download-modal__learn-more {
  padding-top: 0.5rem;
}
