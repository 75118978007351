.code-c-submission-detail-section {
  .code-c-submission-detail-section__header {
    padding: 0.5rem 1rem;
    color: $color-gray-dark;
    cursor: pointer;
    background: $color-gray-super-light;
    border-bottom: 1px solid $color-border-gray;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .code-c-submission-detail-section__icon {
    width: 18px;
    height: 18px;
    background: $color-white;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
  }

  .code-c-submission-detail-section__body {
  }

  &.disabled {
    .code-c-submission-detail-section__header {
      cursor: not-allowed;

      .code-c-submission-detail-section__icon {
        color: $color-gray-300;
      }
    }
  }
}
