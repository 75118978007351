.submit-exam-alert {
  .submit-exam-alert__alert {
    padding: 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

    .code-c-icon {
      align-self: flex-start;
    }

    .submit-exam-alert__content {
      display: flex;
      flex: 1;
      gap: 1.5rem;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        gap: 1.5rem;
        align-items: center;
      }

      .submit-exam-alert__content__subtitle {
        font-weight: normal;
        color: $color-text;
        text-align: left;
      }
    }
  }
}
