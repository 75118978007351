.code-c-radiobutton {
  min-width: 10rem;
  padding: 0.375rem 0.75rem;
  font-weight: 700;
  color: $color-gray-800;
  color: $color-primary-900;
  cursor: pointer;
  border-radius: 0.5rem;
  transition-duration: 0.25s;
  transition-property: color, background-color, border-color, translate,
    box-shadow;

  &:hover {
    text-decoration: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

    &[disabled] {
      cursor: not-allowed;
      box-shadow: none;
      transform: none;
    }
  }

  &.is-stretch {
    width: 100%;
  }

  &.is-shrink {
    min-width: unset;
  }

  &.is-narrow {
    padding: 0.25rem;
  }

  &.is-selected {
    color: $color-gray-800;
    background-color: $color-gray-100;
  }

  &.is-outlined {
    border: 1px solid $color-gray-200;
  }

  &:not(.is-outlined) {
    border: none;
  }

  &.is-rounded {
    border-radius: 1.5rem;
  }
}
