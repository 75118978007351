.code-c-exam-analysis__content {
  min-height: 10rem;

  .code-c-exam-analysis__content__chart {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;

    @include mq-dashboard(md) {
      grid-template-columns: 1fr;
    }
  }
}

.code-c-exam-analysis__score-distribution {
  gap: 2rem;
}
