.code-c-review-status-bar {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 0.5rem;

  .code-c-review-status-bar__container {
    display: flex;
    column-gap: 0.25rem;
    cursor: pointer;

    .code-c-review-status-bar__category {
      display: flex;
      flex-grow: 1;
      height: 0.5rem;

      .code-c-review-status-bar__item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      .code-c-review-status-bar__item:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    .code-c-review-status-bar__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &.is-strongly-approved {
        color: $color-green-700;
        background: $color-green-700;
        border-color: $color-green-700;

        .code-c-review-status-bar__item-count {
          border-color: $color-green-700;
        }
      }

      &.is-approved {
        background: $color-green-500;

        .code-c-review-status-bar__item-count {
          border-color: $color-green-500;
        }
      }

      &.is-rejected {
        background: $color-red-500;

        .code-c-review-status-bar__item-count {
          border-color: $color-red-500;
        }
      }

      &.is-strongly-rejected {
        background: $color-red-700;

        .code-c-review-status-bar__item-count {
          border-color: $color-red-700;
        }
      }

      &.is-stale {
        background: $color-neutral-300;

        .code-c-review-status-bar__item-count {
          border-color: $color-neutral-300;
        }
      }

      &.is-stale-approved {
        background: $color-neutral-300;

        .code-c-review-status-bar__item-count {
          border-color: $color-green-300;
        }
      }

      &.is-stale-rejected {
        background: $color-neutral-300;

        .code-c-review-status-bar__item-count {
          border-color: $color-red-300;
        }
      }


      &.is-not-reviewed {
        background: $color-neutral-500;

        .code-c-review-status-bar__item-count {
          border-color: $color-neutral-500;
        }
      }

      .code-c-review-status-bar__item-count {
        position: absolute;
        z-index: 1;
        padding: 0 6px;
        font-size: 0.5rem;
        color: $color-neutral-900;
        background: $color-white;
        border: 2px solid;
        border-radius: 12px;
      }
    }
  }
}
