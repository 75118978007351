.code-admin-billing-form {
  .code-admin-billing-form__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
  }

  .code-admin-billing-form__tier-container {
    display: flex;
    gap: 1rem;
  }

  .code-admin-billing-form__tier {
    display: flex;
    flex-basis: 11rem;
    flex-direction: column;
    border-right: 1px solid $color-border-gray;
  }

  .code-admin-billing-form__actions {
    flex-grow: 1;
  }

  .code-admin-billing-form__override-numbers {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    input {
      width: 7rem;
    }
  }
}