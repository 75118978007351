.code-c-badge {
  width: fit-content;
  padding: 0 0.5rem;
  line-height: 1.2rem;
  border-radius: 0.75rem;

  &.is-warning {
    color: $color-warning-600;
    background-color: $color-warning-100;
    border: 1px solid $color-warning-300;
  }
}
