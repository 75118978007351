.code-solving-method-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 4rem;
  font-size: 1rem;

  .code-solving-method-panel__image {
    height: 200px;
    margin-bottom: 1rem;
    background-image: url("./assets/images/walkthrough-branch.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}