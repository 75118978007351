.code-c-score-distribution__table {
  color: $color-gray-800;
}

.code-c-score-distribution__title {
  text-align: right;
  background: $color-neutral-100;
  border-right: 1px solid $color-neutral-200 !important;
}

.code-c-score-distribution__body {
  text-align: right;
}
