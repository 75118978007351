.code-c-input {
  vertical-align: top;

  &.is-1 {
    max-width: 3rem;
  }

  &.is-2 {
    max-width: 5rem;
  }

  &.is-3 {
    max-width: 7rem;
  }

  &.is-4 {
    max-width: 11rem;
  }

  &.is-5 {
    max-width: 13rem;
  }

  &.is-6 {
    max-width: 19rem;
  }

  &.is-7 {
    max-width: 23rem;
  }

  &.is-8 {
    max-width: 29rem;
  }

  .input.error {
    border: 1px solid red;
  }

  &.input.has-suffix {
    color: $color-gray-600;

    &:focus-within {
      outline: 1px solid $color-neutral-700;
    }

    &.is-danger {
      &:focus-within {
        outline: none;

        // imitating bulma error style
        box-shadow: 0 0 0 0.125em rgba(224,90,90,.25);
      }
    }

    > .input {
      height: unset;
      padding: 0;
      font-size: inherit;
      text-align: right;
      background: transparent;
      border: none;
      box-shadow: unset;
    }

    &.is-readonly {
      color: #7a7a7a;
      cursor: not-allowed;

      // Emulating bulma's input disable style
      background-color: whitesmoke;

      &:focus-within {
        outline: none;
      }
    }

    /* Hide the spinner on number input when it has a non-editable suffix */
    > input[type="number"] {
      appearance: textfield;

      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        margin: 0;
        appearance: none;
      }
    }
  }
}
