.code-coding-checklist-panel {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  font-size: 1rem;

  .code-coding-checklist-panel__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    padding: 0 4rem;
  }

  .code-coding-checklist-panel__item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0 !important;
  }

  .code-coding-checklist-panel__check {
    .code-c-checkbox__checkmark {
      width: 0 !important;
      height: 0 !important;
      border: 0 !important;
    }

    .code-c-checkbox__label__text {
      padding-left: 2.5rem;
      margin-left: 0 !important;
      font-size: 1.25em;
      font-weight: bold;

      &::before {
        position: absolute;
        top: -2px;
        left: 0;
        width: 30px;
        height: 30px;
        font-family: FontAwesome;
        color: $color-gray-super-light;
        content: "";
        background: $color-gray-super-light;
        border: 5px solid $color-border-gray;
        border-radius: 50%;
        transition: all 0.25s;
      }
    }

    .code-c-checkbox__checkbox {
      display: none;

      &:checked {
        & ~ .code-c-checkbox__label__text {
          &::before {
            line-height: 22px;
            color: $color-white;
            content: "\f00c";
            background: $color-primary-500;
            border-color: $color-primary-500;
          }
        }
      }
    }
  }

  .code-coding-checklist-panel__doc {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    margin-left: 2.5rem;
    font-size: smaller;
  }

  .code-coding-checklist-panel__doc-link {
    display: flex;
    gap: 0.5rem;
  }
}
