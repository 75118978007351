.code-c-local-code-files {
  gap: 0.75rem;

  &.is-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &.is-row {
    display: flex;
    flex-direction: row;
  }

  &.is-column {
    display: flex;
    flex-direction: column;
  }
}
