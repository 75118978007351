.code-c-new {
  display: inline-block;

  .code-c-new {
    margin-right: 0.3rem;
    font-size: 0.65rem;
    color: $color-white;
    background: $color-secondary-500;
    border-radius: 15px;
  }
}
