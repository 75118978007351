.code-c-weight-cell {
  &-input-container {
    display: flex;
    align-items: center;
  }
    
  .input-container .no-border {
    flex-grow: 1;
    margin-right: 10px;
  }
    

  &-number-input {
    width: 56px;
    height: 24px;
    font-size: .875rem;
  }
}