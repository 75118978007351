.code-admin-project-list {
  .code-admin-project-list__header {
    padding: 1.5rem 1.5rem 0;
  }

  .code-project-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-c-table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .code-admin-project-list__action-menu {
    a.code-admin-project-list__danger-menu-item {
      color: $color-error-700;
    }
  }
}
