.code-c-submission-actions {
  display: flex;
  gap: 0.5rem;

  @media print {
    display: none;
  }
}

.code-c-submission-actions-dropdown-items-list {
  & > li:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  .code-c-list-item {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    padding: 0.75rem 1rem;
    cursor: pointer;

    &:hover {
      background: transparentize($color-gray-light, 0.7);
    }
  }
}

.code-c-submission-sharing {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 16rem;
  padding: 0.75rem;

  .code-c-submission-sharing-link {
    display: flex;
    border: 1px solid $color-gray-200;
    border-radius: 0.25rem;
  }

  .code-c-submission-copy-link {
    border-left: 1px solid $color-gray-200;
  }
}
