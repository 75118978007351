.code-c-checkbox {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 1em;

  .code-c-checkbox__label {
    min-width: 1rem;
    min-height: 1rem;

    input {
      position: absolute;
      width: 0;
      height: 0;
      cursor: pointer;
      opacity: 0;
    }

    .code-c-checkbox__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      margin-top: 1px;
      background-color: $color-white;
      border: 1px solid $color-neutral-300;
      border-radius: 2px;

      // Style the checkmark/indicator
      // checkmark is hidden by default
      &::after {
        position: absolute;
        top: 2px;
        left: 5px;
        display: none;
        width: 4px;
        height: 8px;
        content: "";
        border: solid $color-primary-800;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
    }

    // when input is checked
    input:checked ~ .code-c-checkbox__checkmark {
      background-color: $color-primary-400;
      border: 1px solid transparent;
    }

    // show checkmark if checked
    input:checked ~ .code-c-checkbox__checkmark::after,
    input:checked:disabled ~ .code-c-checkbox__checkmark::after {
      display: block;
    }

    // when input is focused
    input:focus ~ .code-c-checkbox__checkmark {
      outline: 1px solid $color-primary-600;
    }

    input:focus:not(:focus-visible) ~ .code-c-checkbox__checkmark {
      outline: none;
    }

    // when input is disabled
    input:disabled ~ .code-c-checkbox__checkmark {
      background-color: $color-gray-100;
      border: 1px solid $color-gray-300;
    }

    input:checked:disabled ~ .code-c-checkbox__checkmark {
      background-color: $color-primary-200;
      border: 1px solid transparent;
    }

    // hover states when hovering over checkbox
    &:hover {
      input ~ .code-c-checkbox__checkmark {
        border: 1px solid $color-neutral-400;
      }

      input:disabled ~ .code-c-checkbox__checkmark {
        border: 1px solid $color-gray-300;
      }

      input:checked ~ .code-c-checkbox__checkmark {
        background-color: $color-primary-500;
        border: 1px solid transparent;
      }

      input:checked:disabled ~ .code-c-checkbox__checkmark {
        background-color: $color-primary-200;
        border: 1px solid transparent;
      }
    }

    &.is-readonly {
      color: $color-gray-600;
      cursor: not-allowed;

      strong {
        color: $color-gray-600;
      }
    }

    .code-c-checkbox__label__text {
      margin-left: 1.5rem;
      word-break: break-word;

      .code-c-icon {
        height: 1rem;
      }
    }
  }

  .code-c-checkbox__description {
    margin-top: 0.25rem;
    margin-left: 1.5rem;
    line-height: 1.125rem;
    color: $color-gray-700;
  }

  &.has-description {
    padding-top: 0;
    padding-bottom: 0;
  }
}
