.editor-appeal-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 20rem;
  padding: 1rem;
  overflow: hidden;
}

.editor-appeal-form__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.editor-appeal-form__description {
  margin-bottom: 1rem;
  font-size: 0.75rem;
}

.editor-appeal-form__content {
  display: flex;
  flex: 1;
  min-height: 0;
}

.editor-appeal-form__footer {
  margin-top: 0.75rem;
}

.editor-appeal-form__markdown-status {
  display: flex;
  justify-content: flex-end;
  height: 1.125rem;
  font-size: 0.75rem;

  &.green {
    color: $color-green-700;
  }

  &.red {
    color: $color-green-700;
  }
}
