.code-exam-list-table-header {
  .code-exam-list-table-header__in-review,
  .code-exam-list-table-header__unread-delivery {
    width: 4.5rem;
    min-width: unset;
  }

  .code-exam-list-table-header__padding-small {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
