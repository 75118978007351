.code-challenge-list-action {
  .code-c-tooltip-wrapper {
    width: 100%;
  }

  .code-challenge-list-action__disable {
    width: 100%;
    color: $color-gray !important;
    cursor: not-allowed;

    .code-c-icon {
      opacity: 0.5;
    }

    .code-c-tooltip {
      z-index: unset;
      line-height: initial;
    }
  }
}
