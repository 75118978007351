@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  1% {
    opacity: 0;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  100% {
    right: 0;
  }
}

@keyframes slideOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
