.code-exam-invitation-complete {
  ol {
    padding-top: 0.5rem;
    padding-left: 1rem;
  }

  .code-exam-invitation-complete__steps {
    padding: 0.5rem 1rem 1.5rem;
    margin: 3rem 0;
    text-align: center;
    border: 4px solid $color-primary;
    border-radius: 12px;

    .code-c-page-sub-title {
      margin-top: 1rem;
    }
  }

  .code-c-hero-tile {
    margin: 1rem auto;
  }
}
