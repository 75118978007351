.code-c-submission-result-details {
  margin-top: 1rem;
  border: 1px solid $color-border-gray;

  .code-c-submission-result-details__table-head {
    position: sticky;
    top: 0;
    z-index: 6;
  }

  .code-c-submission-result-details__container {
    margin-bottom: 1rem;
    border-top: 4px solid $color-gray-dark;
  }

  .code-c-submission-result-details__header {
    padding: 0.5rem 0.75rem;

    .code-c-tag {
      margin-left: 0.5rem;
    }

    .code-c-submission-result-details__optional-score-explanation {
      margin: 0.25rem 0 0 1rem;
    }
  }

  .code-c-submission-result-details__body {
    margin: 0 1.25rem;

    .code-c-submission-result-details__detail-wrapper {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $color-gray-light;
      }

      .code-c-submission-detail {
        border-right: 1px solid $color-gray-light;
        border-left: 1px solid $color-gray-light;
      }

      .code-c-submission-result-details__not-included {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
        background: transparentize($color-gray, 0.85);
      }
    }
  }
}
