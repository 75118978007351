.code-auto-filter-form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .code-c-icon {
    width: 1rem;
    height: 1rem;
    font-size: 16px;

    .fa-check {
      color: $color-success;
    }

    .fa-times {
      color: $color-error;
    }

    .fa-inbox {
      color: $color-neutral-700;
    }
  }

  .filter-type-section {
    display: flex;
    column-gap: 1rem;
  }

  .score-section {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 480px;
    padding: 1rem;
    border: 1px solid $color-gray-300;
    border-radius: 3px;
  }

  &.is-readonly {
    color: $color-gray-600;
  }
}
