.code-c-test-output {
  .code-c-test-output__testcases-summary {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid $color-border-gray;

    > .code-c-icon {
      margin-right: 0.5rem;
    }
  }

  .code-c-test-output__secret-icon {
    width: 20px;
    height: 20px;
    background: $color-border-gray;
    border-radius: 100%;
  }

  .code-c-test-output__column {
    padding: 0;
    border-bottom: 1px solid $color-border-gray;

    .code-c-test-output__secret {
      display: flex;
      flex-basis: 2rem;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
    }
  }

  .code-c-test-output__item {
    display: flex;
    align-items: center;
    padding: 0.1rem 1rem 0.1rem 0;

    .code-c-tooltip {
      z-index: unset;
    }

    &.is-ok {
      background: $color-success-background;
      border-left: 3px solid $color-success;

      .code-c-icon {
        color: $color-success;
      }
    }

    &.is-ng {
      background: $color-error-background;
      border-left: 3px solid $color-error;

      .code-c-icon {
        color: $color-error;
      }
    }
  }

  .code-c-test-output__line {
    flex-basis: 2.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
  }

  .code-c-test-output__result {
    flex-basis: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
  }

  .code-c-test-output__text {
    .code-c-test-output__text__secret {
      user-select: none;
      filter: blur(8px);
    }
  }

  .code-c-test-output__evalpoint-column {
    width: 18rem;
    padding: 1.25rem 0.75rem;
    text-align: center;
    border-right: 1px solid $color-border-gray;
    border-bottom: 1px solid $color-border-gray;

    .code-c-test-output__evalpoint-title {
      margin: 1rem;
      font-weight: bold;
    }

    .code-c-test-output__evalpoint-count {
      margin: 1rem;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .code-c-test-output__no-test {
    padding: 1rem;
  }
}
