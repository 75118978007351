.code-c-action-dropdown {
  text-align: left;
  white-space: nowrap;

  .dropdown-menu {
    position: fixed;
    left: unset;
    z-index: $z-index-header - 1;
    min-width: unset;
  }

  .dropdown-content {
    flex-direction: column;
    padding: 0;

    a {
      display: block;
      align-items: center;
      padding: 0 1.75rem 0 1rem;
      line-height: 2.75rem;
      color: $color-text;
      transition: background-color 0.3s;

      &:hover {
        text-decoration: none;
        background: transparentize($color-gray-light, 0.7);
      }
    }
  }

  .code-c-overlay {
    z-index: $z-index-header - 2;
  }

  .separator {
    width: 100%;
    height: 1px;
    background: $color-border-gray;
  }

  .dropdown-button__content {
    display: flex;
    gap: 0.25rem;
  }
}
