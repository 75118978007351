$score-input-height: 2.125rem;

.score-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;

  label {
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
    height: $score-input-height;
    font-size: 13px;
  }

  .right-section {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    align-items: flex-end;
    max-width: 40%;

    .score-input-wrapper {
      display: flex;
      column-gap: 0.25rem;
      align-items: center;

      .score-input {
        width: 3.875rem;
        height: $score-input-height;
        padding: 0.5rem;
        font-size: 14px;

        &.has-error {
          border-color: $color-error;
        }
      }
    }

    .error-wrapper {
      display: flex;
      justify-content: flex-end;
      min-width: 100%;
      overflow: visible;

      .code-c-validation-message {
        min-width: 100%;
      }
    }
  }
}
