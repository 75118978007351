.code-official-exam-deliver {
}

.code-official-exam-deliver__modal-banner {
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background: $color-neutral-200;
  border-radius: 0.25rem;
}

.code-official-exam-deliver__modal-banner-icon {
  margin-top: 0.25rem;
  font-size: 1rem;
  color: $color-neutral-800;
}
