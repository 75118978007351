.code-c-submission-result-header {
  display: grid;
  grid-template-columns:
    minmax(17rem, auto) minmax(12rem, 15rem) minmax(auto, 6rem)
    minmax(auto, 10rem);
  column-gap: 1rem;
  padding: 0.5rem 1rem;
  color: $color-gray-dark;
  background: $color-gray-super-light;
  border-bottom: 1px solid $color-border-gray;
}
