.code-c-autocomplete__checkbox-option {
    display: flex;
    padding-top: 4px;
    padding-right: 6px;
    padding-bottom: 4px;
    padding-left: 12px;
    cursor: pointer;
    border-radius: 4px;

    &-label {
        margin-right: auto;
    }

    &-default {
        margin-right: 8px;
        font-size: 0.75rem;
        color: $color-gray-500;
    }

    &__checkbox-container {
        display: inline-flex !important;
    }
}

.code-c-autocomplete__option-divider {
    height: 1px;
    margin-top: 0.5rem;
    margin-right: 0;
    margin-bottom: 0.5rem;
    margin-left: 0;
    background-color: $color-gray-300;
}
