// SCSS mixin

// base size is 16px
/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin fontSize($name: "normal", $bold: false) {
  @if $name == "heading1" {
    // 24px
    font-size: 1.5rem;
  } @else if $name == "heading2" {
    // 20px
    font-size: 1.25rem;
  } @else if $name == "heading3" {
    // 17.6px
    font-size: 1.1rem;
  } @else if $name == "large" {
    // 16px
    font-size: 1rem;
  } @else if $name == "normal" {
    // 14px
    font-size: 0.825rem;
  } @else if $name == "small" {
    // 12.8px
    font-size: 0.8rem;
  } @else if $name == "xsmall" {
    // 12px
    font-size: 0.75rem;
  } @else {
    // Normal
    font-size: 0.825rem;
  }

  @if $bold {
    font-weight: bold;
  }

  line-height: 1.5;
}

@mixin action-log-grid-template() {
  display: grid;
  grid-template-columns: 1.5rem 1fr 2fr 1.25fr;
  column-gap: 1.25rem;
}

@mixin text-heading1() {
  font-size: 1.5rem; // 24px
  font-weight: 700;
  line-height: 2.25rem;
}

@mixin text-heading2() {
  font-size: 1.25rem; // 20px
  font-weight: 700;
  line-height: 1.25rem;
}

@mixin text-heading3() {
  font-size: 1.125rem; // 18px
  font-weight: 700;
  line-height: 1.25rem;
}

@mixin text-title() {
  font-size: 1.5rem; // 24px
  font-weight: 400;
  line-height: 2.25rem;
}

@mixin text-xl() {
  font-size: 1.125rem; // 18px
  font-weight: 400;
  line-height: 1.5rem;
}

@mixin text-lg() {
  font-size: 1rem; // 16px
  font-weight: 400;
  line-height: 1.5rem;
}

@mixin text-md() {
  font-size: 0.875rem; // 14px
  font-weight: 400;
  line-height: 1.125rem;
}

@mixin text-sm() {
  font-size: 0.8125rem; // 13px
  font-weight: 400;
  line-height: 1.125rem;
}

@mixin text-xs() {
  font-size: 0.75rem; // 12px
  font-weight: 400;
  line-height: 1.125rem;
}

@mixin text-xxs() {
  font-size: 0.675rem; // 10px
  font-weight: 400;
  line-height: 1.125rem;
}

@mixin spacing($className, $styleName) {
  @for $i from 1 through 64 {
    #{$className + $i} {
      #{$styleName}: #{($i * 0.25) + "rem"};
    }
  }
}
