.code-submission-list-table-action-menu {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;

  .code-submission-list-table-action-menu__icon {
    width: 18px;
    height: 18px;

    &.archive {
      background: transparent url("./assets/images/icon-archive.svg") no-repeat;
      background-size: contain;
    }

    &.unarchive {
      background: transparent url("./assets/images/icon-unarchive.svg") no-repeat;
      background-size: contain;
    }
  }
}