.code-c-breadcrumbs {
  .code-c-breadcrumbs__container {
    display: flex;
    height: $breadcrumb-height;
    padding: 0 1.5rem;
    overflow: hidden;
    overflow-x: auto;
    font-size: 1em;
    line-height: $breadcrumb-height;
    white-space: nowrap;
    background: $breadcrumb-background-color;
    border-bottom: 1px solid $color-border-gray;

    .code-c-breadcrumbs__final {
      display: inline;
    }

    > span {
      display: inline-flex;
      max-width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;

      &:last-child {
        font-weight: bold;
      }
    }

    a {
      display: inline-block;
      overflow: hidden;
      color: $color-gray-dark;
      text-overflow: ellipsis;
    }
  }

  .code-c-breadcrumbs__separator {
    margin: 0 0.5rem;
  }
}
