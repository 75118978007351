.code-important-points-panel {
  $important-points-panel-image-size: 120px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 4rem;
  font-size: 1rem;

  .code-important-points-panel__container {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    .panel-image1 {
      width: $important-points-panel-image-size;
      height: $important-points-panel-image-size;
      margin-right: 2rem;
      background-image: url("./assets/images/walkthrough-donotshare.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .panel-image2 {
      width: $important-points-panel-image-size;
      height: $important-points-panel-image-size;
      margin-right: 2rem;
      background-image: url("./assets/images/walkthrough-donotshare2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .code-important-points-panel__list {
    padding-left: 1.5rem;
    list-style: disc;
  }
}