.code-c-status-tag {
  display: flex;
  column-gap: 0.25rem;
  align-items: center;

  @include text-sm;

  font-weight: 700;
  text-transform: uppercase;

  .code-c-status-tag__circle {
    width: 0.5rem;
    height: 0.5rem;
    font-size: 8px;
  }
}
