.code-submission-list-table-header {
  &.is-unread {
    // acts as min-height on table elements
    height: 2.75rem;
  }

  .code-submission-list-table-header__score {
    white-space: initial;
  }

  .code-submission-list-table-header__action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .code-c-button {
      margin: 0 !important;
    }
  }
}
