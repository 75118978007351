.code-setting-integration {
  .code-setting-integration__header {
    flex-wrap: wrap;
    align-items: center;
    padding: 1.5rem 1.5rem 0;
    white-space: nowrap;
  }

  .code-setting-integration__body {
    margin: 1rem 1.5rem;
  }

  .code-setting-integration__channel-header {
    display: flex;
    align-items: center;

    .code-setting-integration__block {
      display: flex;
      gap: 0.75rem;
      align-items: center;
      font-size: 1rem;
      line-height: 1;

      .code-setting-integration__block__sub-title {
        font-size: 1.25rem;
        font-weight: normal;
        color: $color-black;
      }
    }
  }

  .code-setting-integration__workspace {
    display: flex;
    flex-wrap: wrap;

    .code-setting-integration__workspace__card {
      display: grid;
      grid-template-columns: min-content 1fr;
      row-gap: 0.5rem;
      column-gap: 2rem;
      padding: 0.75rem;
      border: 1px solid $color-border-gray;

      & :nth-child(2n + 1) {
        font-size: 0.8rem;
      }
    }
  }

  .code-setting-integration__column-name {
    display: flex;
    gap: 0.5rem;
  }

  .code-setting-integration__delete-channel-modal-channel {
    align-items: flex-start;
  }

  .code-setting-integration__actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }

  .code-setting-integration-form {
    .code-setting-integration-form__checkboxes {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      margin-bottom: 1rem;
    }

    .code-setting-integration-form__section {
      margin-left: 1rem;
    }

    label + .code-setting-integration-form__section {
      margin-bottom: 1rem;
    }
  }
}
