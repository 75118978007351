.code-c-warning-tag {
  &.is-icon-large {
    padding: 0;
    padding-right: 0.5rem;
  }

  &.is-icon-medium {
    padding: 0;
    padding-right: 0.5rem;
  }

  &.is-rounded {
    border-radius: 1.5rem;
  }

  &.is-bordered {
    border: 1px solid $color-yellow-300;
  }

  .is-icon-size-small {
    font-size: 0.75rem;

    & > span:first-child {
      margin-right: 0;
    }

    & > span:last-child {
      margin-left: 0;
    }
  }

  .is-icon-size-medium {
    font-size: 1rem;

    & > span:first-child {
      margin-right: 0.25rem;
    }

    & > span:last-child {
      margin-left: 0.25rem;
    }
  }

  .is-icon-size-large {
    font-size: 1.25rem;

    & > span:first-child {
      margin-right: 0.5rem;
    }

    & > span:last-child {
      margin-left: 0.5rem;
    }
  }
}
