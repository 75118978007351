.code-c-challenge-collection-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23rem;
  height: 8.25rem;
  padding: 1rem;
  color: $color-white;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    $color-neutral-800 0%,
    $color-neutral-500 150%
  );
  border-radius: 3px;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: FontAwesome;
    font-size: 4rem;
    color: transparentize($color-neutral-800, 0.8);
    content: "\f1b3";
  }

  .code-c-challenge-collection-card__title {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  .code-c-challenge-collection-card__description {
    margin-bottom: 0.5rem;
  }

  .code-c-challenge-collection-card__more {
    text-decoration: underline;
  }
}
