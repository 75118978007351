.code-editor-main {
  .code-c-dummy-editor,
  .code-editor-main__editor {
    position: relative;
    flex: 1;
    min-width: 760px;
  }

  .code-editor-main__iframe {
    width: 100%;
    height: 100%;
    min-height: 0;
  }

  .code-editor-main__header-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-index-challenge-header;
  }

  $editor-appeal-link-height: 40px;

  .code-editor-main__appeal-link {
    position: absolute;
    right: 0;
    z-index: $z-index-appeal-comment;
    height: $editor-appeal-link-height;
    margin: 0 1rem;
    line-height: $editor-appeal-link-height;
    cursor: pointer;
  }

  .code-editor-main__appeal-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300px;
    min-height: 100%;
    background: $color-white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

    %appeal-form-title {
      display: flex;
      flex-direction: row-reverse;
      padding: 0.5rem;
    }

    .code-editor-main__appeal-form-title {
      @extend %appeal-form-title;

      background-color: rgba(15, 71, 141, 0.15);
    }

    .code-editor-main__appeal-form-title__ai {
      @extend %appeal-form-title;

      background-color: transparent;
      border-left: 1px solid #eee; // remove after TEST-3897
    }

    .code-editor-main__appeal-form__header {
      display: flex;
      justify-content: space-between;
    }

    .code-editor-main__appeal-form__rich-markdown {
      display: flex;
      flex: 1;
      min-height: 0; // Hack: for displaying the scroll bar
    }
  }

  .code-editor-main__submitted-container {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 4rem;
    z-index: $z-index-editor-overlay;
    width: calc(100% - 4rem);
    height: calc(100% - 3rem);
    text-align: center;
    background: $color-white;

    .code-editor-main__submitted-container-top {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .code-editor-main__submitted__alert {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 1.5rem;
  }

  .code-editor-main__submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 40rem;
  }

  .code-editor-main__submitted-title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: $color-red;

    .code-c-icon {
      width: 3rem;
    }
  }

  .code-editor-main__cli-footer {
    position: absolute;
    right: 190px; // For test run button
    bottom: 0;
    left: $menu-width;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 0 0 6rem; // For Intercom inbox

    .code-c-tooltip {
      max-width: 25rem;

      &:first-child {
        flex-basis: 25rem;
        flex-shrink: 1;
      }
    }

    .code-c-copy-box {
      display: inline-flex;
      width: 100%;
      max-width: 25rem;

      .code-c-input {
        width: 100%;
      }
    }

    .code-c-jump-to {
      .code-c-icon {
        margin-left: 0.5rem;
        font-size: 1.5em;
        color: $color-text;
      }
    }
  }

  .code-editor-main__code-updated {
    position: fixed;
    right: 0;
    bottom: calc(40px + 3rem);
    z-index: calc(#{$z-index-overlay} + 1);
    padding: 2rem 1rem;
    margin: 0 1rem;
    text-align: center;
    background: $color-white;
    border-top: 0.5em solid $color-primary;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    transform: translateY(200%) translateZ(0);

    .code-c-icon {
      margin-bottom: 1.5rem;
      color: $color-yellow;
    }

    .code-c-button {
      margin-top: 1rem;
    }

    &.is-show {
      transition: transform 0.25s;
      transform: translateY(0) translateZ(0);
    }
  }

  .code-editor__editor {
    display: flex;
    width: 100%;
    height: calc(100vh - #{$header-height});
    overflow: hidden;

    .code-editor-main__appeal-form,
    .gutter {
      display: none;
    }

    &.is-code-explanation-open {
      .code-editor-main__appeal-form {
        display: flex;
      }

      .gutter {
        display: unset;
      }
    }
  }
}
