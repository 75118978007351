.code-submit-challenge.modal {
  $submit-challenge-width: 640px;

  .modal-card {
    width: 100%;
    max-width: $submit-challenge-width;
    background-color: $color-neutral-500;
    border-radius: 5px;

    .modal-card-head__top-border {
      display: none;
    }
  }

  .modal-card-head {
    padding-top: 20px !important;
    text-align: center;
    background: $color-neutral-500;
    border-bottom: 0;

    .modal-card-title {
      font-family: $font-family-heading;
      color: $color-white;
    }
  }

  .modal-card-body {
    min-height: 17rem;
    padding: 20px 0 1rem;
    background: transparent;
  }

  .code-submit-challenge__body__contents {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  .modal-card-foot {
    border-top: 0;

    .code-c-button {
      width: 100%;
      max-width: 250px;
      height: 48px;
    }
  }

  .code-submit-challenge__body {
    display: flex;
  }

  .code-submit-challenge__item:not(:last-child) {
    margin-right: 1rem;
  }

  .code-c-icon {
    color: $color-yellow;
  }

  .code-submit-challenge__unsaved {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid $color-warning;
    border-radius: 3px;
  }

  .code-submit-challenge__meter {
    position: relative;
    width: 275px;
    height: 288px;
    margin: 0 auto;

    .code-submit-challenge__meter__score-line-behind {
      opacity: 0.3;
      fill: none;
      stroke: $color-white;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 18.93px;
      enable-background: new;
    }

    .code-submit-challenge__meter__score-line {
      fill: none;
      stroke: $color-white;
      stroke-dasharray: 614;
      stroke-dashoffset: 614px;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 28.39px;
      transition: stroke-dashoffset 0.5s;
      transition-delay: 0.25s;
    }

    .code-submit-challenge__meter__score-wrapper {
      color: $color-white;

      .code-submit-challenge__meter__score {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: fit-content;
        margin: auto;
        font-family: $font-family-pop;
        font-size: 7rem;
        text-align: center;
      }

      .code-submit-challenge__meter__total-score {
        position: absolute;
        right: 87px;
        bottom: 60px;
        font-family: $font-family-pop;
        font-size: 1.5rem;
      }

      .code-submit-challenge__meter__text {
        position: absolute;
        right: 0;
        bottom: 25px;
        left: 0;
        font-size: 1rem;
        text-align: center;
      }
    }
  }

  &.no-scored,
  &.not-latest-execution {
    .modal-card-body {
      background-color: $color-gray;
    }

    .code-submit-challenge__no-scored,
    .code-submit-challenge__not-latest-execution {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 1.5rem;
      color: $color-white;
      text-align: center;
      background-color: transparentize($color-gray-super-dark, 0.25);

      .code-c-icon {
        margin-bottom: 1rem;
      }
    }
  }

  &.is-submitting {
    .modal-card {
      background-color: $color-white;
    }

    .modal-card-head,
    .modal-card-foot {
      display: none;
    }

    .code-submit-challenge__loading {
      margin: 6rem auto;
      text-align: center;
    }

    .code-submit-challenge__loading-text {
      margin-top: 2rem;
      font-size: 1.5em;
    }
  }

  &.is-submitted {
    .modal-card {
      max-height: 569px;
      background: $color-neutral-500;
      background-image: url("./assets/images/have-a-coffee.png");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: $submit-challenge-width;
    }

    .modal-card-head,
    .modal-card-body {
      background-color: transparent;
    }
  }
}
