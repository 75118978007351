.code-delivery-cancel-modal {
  &.has-memo {
    > .modal-card {
      > .modal-card-body {
        min-height: 19rem;
      }
    }
  }

  .code-delivery-cancel-modal__text {
    margin-bottom: 1rem;
  }

  .code-delivery-cancel-modal__memo {
    margin-top: 1rem;
  }
}
