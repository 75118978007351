.code-submit-exam-modal {
  .modal-card {
    width: 100%;
    max-width: 50rem;
  }

  .code-submit-exam-modal__warning {
    margin-bottom: 1.5rem;
  }

  .code-submit-exam-modal__warning_icon {
    margin-top: 0.25rem;
    color: $color-yellow;
  }

  .code-submit-exam-modal__name {
    :first-child {
      margin-bottom: 0.25rem;
    }

    :last-child {
      font-size: 0.75rem;
      color: $color-gray-600;
    }
  }

  .code-submit-exam-modal__last-activity {
    display: flex;
    gap: 0.5rem;
  }
}
