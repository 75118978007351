.code-c-completed {
  .code-c-completed__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .code-c-completed__docs {
    padding-left: 2rem;
    list-style: disc;

    .code-c-completed__doc-item {
      display: inline-flex;
      gap: 0.5rem;
    }
  }
}
