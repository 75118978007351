.code-webcam-setup-panel {
  flex: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .code-webcam-setup-panel__webcam {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 1.5rem;

    a {
      font-weight: 700;
      color: $color-neutral-800;
    }

    .code-webcam-setup-panel__webcam__select {
      width: 25rem;
    }

    .code-webcam-setup-panel__webcam__video-dimensions {
      width: 25rem;
      height: 18.75rem;
      border-radius: 0.25rem;

      &.is-not-capturing {
        display: none;
      }

      &.no-camera {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        align-items: center;
        justify-content: center;
        color: $color-white;
        background-color: $color-black;

        .icon {
          color: $color-warning-300;
        }

        .code-c-spinner {
          border-width: 0.25rem;
        }
      }
    }
  }

  .code-c-select {
    height: auto;
  }

  .code-webcam-setup-panel__warning {
    padding: 0.75rem;
    background-color: $color-neutral-100;
    border-radius: 0.25rem;
  }
}
