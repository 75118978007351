.code-exam-detail {
  .code-exam-detail-header {
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
  }

  .code-exam-detail__banner {
    margin: 1.5rem 1.5rem 0;
  }

  .code-exam-detail__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 1.5rem;

    &.code-exam-detail__body__inner-scroll {
      overflow-y: auto;
    }

    .code-exam-edit__form {
      margin: 0 1.5rem 1.75rem;
    }
  }

  .code-c-sub-menu-container-left {
    .code-c-sub-menu {
      padding: 1.5rem;
    }
  }

  .code-exam-edit__drop-placeholder {
    visibility: hidden;
  }

  .code-exam-detail__header {
    padding: 1.5rem 1.5rem 0;

    .code-exam-detail__exam_id {
      margin-left: 0.5rem;
      font-size: 0.8125rem;
    }
  }

  .code-exam-detail__sub-menu-container {
    overflow: visible;
    border-top: none;

    &.code-exam-detail__auto-height {
      height: auto;
    }

    .code-exam-detail__sub-menu-container__left {
      min-width: $submission-menu-width;
      overflow: visible;
    }

    .code-exam-detail__sub-menu-container__menu {
      position: sticky;
      top: calc(#{$header-height} + #{$breadcrumb-height});
      height: calc(100vh - #{$header-height} - #{$breadcrumb-height});
      overflow-y: scroll;
    }
  }

  .code-exam-edit__edit-limited {
    position: relative;
    margin: 1rem 0;
  }

  .code-exam-detail-is-editing {
    margin-bottom: 4.25rem;
  }
}
