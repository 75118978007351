.submit-exam-panel {
  position: sticky;
  bottom: 0;
  z-index: $z-index-footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $submit-exam-panel-height;
  padding: 1rem;
  background-color: $color-white;
  border-top: 1px solid $color-border-gray;

  p {
    margin-bottom: 0.5rem;
  }
}
