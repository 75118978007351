.code-c-reviewer-row {
  display: flex;

  .code-c-avatar {
    margin-right: 1em;
  }
}

.code-c-reviewer__row-name {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
