.code-c-applicant-exam-status-display {
  align-items: center;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  .code-c-applicant-exam-status-display__header {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;

    .code-c-applicant-exam-status-display__header-right {
      align-self: center;
      margin-left: 1rem;
    }

    .code-c-applicant-exam-status-display__score-container {
      text-align: center;
    }

    .code-c-applicant-exam-status-display__score {
      @include fontSize("heading1", true);
    }

    .code-c-applicant-exam-status-display__score-base {
      margin-left: 0.25rem;
    }
  }

  .code-c-applicant-exam-status-display__body {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 1rem;
  }

  .code-c-applicant-exam-status-display__footer {
    width: 100%;

    button {
      width: 100%;
    }

    .code-c-applicant-exam-status-display__time-left {
      margin-top: 0.25rem;
      font-weight: bold;
      color: $color-error;
      text-align: center;
    }
  }
}
