.code-system-health-item {
  &.is-poor {
    .code-system-health-item__title {
      font-weight: bold;
      background: $color-error-background;

      .code-system-health-item__status-icon {
        color: $color-error;
      }

      &:hover {
        background: $color-error-background !important;
      }
    }
  }

  .code-system-health-item__error-detail {
    font-weight: normal;
    color: $color-warning;
    background: $color-warning-background;

    &:hover {
      background: $color-warning-background !important;
    }

    .code-c-column {
      position: relative;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .code-system-health-item__copy {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      color: $color-gray-super-dark;

      &[data-badge] {
        &.is-copied {
          &::after {
            position: absolute;
            top: 0;
            right: 1rem;
            padding: 0 0.5rem;
            font-size: smaller;
            color: $color-white;
            content: attr(data-badge);
            background: $color-primary;
            border-radius: 9px;
            animation: fadeIn 0.5s;
          }
        }
      }
    }
  }
}
