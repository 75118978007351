.code-c-progress-circle {
  $progress-stroke-width-small: 3px;
  $progress-size-small: 48px;
  $progress-stroke-width-medium: 3px;
  $progress-size-medium: 60px;
  $progress-stroke-width-large: 5px;
  $progress-size-large: 80px;

  position: relative;
  display: inline-block;
  width: $progress-size-small;
  height: $progress-size-small;

  .code-c-progress-circle__progress {
    width: $progress-size-small;
    height: $progress-size-small;
    transform: rotate(-90deg);
  }

  .code-c-progress-circle__background {
    fill: none;
    stroke: $color-gray-light;
    stroke-width: $progress-stroke-width-small;
  }

  .code-c-progress-circle__bar {
    fill: none;
    stroke: $color-primary;
    stroke-linecap: round;
    stroke-width: $progress-stroke-width-small;
    transition: stroke-dashoffset 1s ease-in-out;
  }

  .code-c-progress-circle__label {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 0.625rem;
    color: $color-primary;
    transform: translate(-50%, -50%);
  }

  &.is-medium {
    width: $progress-size-medium;
    height: $progress-size-medium;

    .code-c-progress-circle__progress {
      width: $progress-size-medium;
      height: $progress-size-medium;
    }

    .code-c-progress-circle__background,
    .code-c-progress-circle__bar {
      stroke-width: $progress-stroke-width-medium;
    }

    .code-c-progress-circle__label {
      font-size: small;
    }
  }

  &.is-large {
    width: $progress-size-large;
    height: $progress-size-large;

    .code-c-progress-circle__progress {
      width: $progress-size-large;
      height: $progress-size-large;
    }

    .code-c-progress-circle__background,
    .code-c-progress-circle__bar {
      stroke-width: $progress-stroke-width-large;
    }

    .code-c-progress-circle__label {
      font-size: small;
    }
  }
}
