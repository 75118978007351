$table-body-height: calc(100vh - 290px);

.code-c-submission-result-action-log {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  min-height: 716px;
  margin-top: 1.5rem;
  margin-bottom: 0;
  color: $color-text;

  &__webcam-multiple-people {
    display: block !important;

    &__image-container {
      display: inline-block;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 150px;
      margin-top: 8px;
      margin-right: 8px;
      overflow: hidden; 

      img {
        width: 100%; 
        height: 100%; 
        object-fit: cover; 
        object-position: center;
      }
    }
  }

  .code-c-submission-result-action-log__table {
    overflow-y: auto;
    border: 1px solid $color-border-gray;

    .code-c-submission-result-action-log__table-head {
      @include action-log-grid-template;

      position: sticky;
      top: 0;
      z-index: 1;
      padding: 0.75rem 1rem;
      font-weight: 600;
      color: $color-gray-700;
      background: $color-gray-super-light;
      border-bottom: 1px solid $color-border-gray;

      & > div:nth-child(1) {
        grid-column-start: 2;
      }
    }

    .code-c-submission-result-action-log__loading-list {
      position: relative;
      min-height: $table-body-height;
    }

    .code-c-submission-result-action-log__table-body {
      max-height: $table-body-height;

      & > * + *:nth-child(odd) {
        border-top: 1px solid $color-border-gray;

        &:last-child {
          border-top: 0;
        }
      }
    }
  }
}

.code-c-submission-result-action-log__banner {
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  color: $color-gray-800;
  background-color: $color-neutral-200;
  border-radius: 0.25rem;

  .code-c-icon {
    color: $color-neutral-800;
  }

  a {
    font-weight: 600;
  }
}

.code-c-submission-result-action-log__empty {
  padding: 5.5rem;
  font-weight: 600;
  color: $color-gray-900;
  text-align: center;
}

.code-c-submission-result-action-log__pagination {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-bottom: -1rem;
}

@media print {
  .code-c-submission-result-action-log {
    .code-c-submission-result-action-log__table {
      .code-c-submission-result-action-log__table-body {
        max-height: none;
      }
    }
  }
}
