.code-c-custom-form-preview-box {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: flex-start;

  .code-c-custom-form-preview-box__table {
    align-self: stretch;
    margin-bottom: 2rem;
  }

  .code-c-label {
    @include text-md;

    overflow-wrap: anywhere;
  }

  .code-c-label:not(:last-child) {
    margin-bottom: 0.6875rem;
  }
}
