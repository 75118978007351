.code-admin-top {
  .code-admin-top__menu {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    max-width: $hero-tile-max-width;
    margin: 3rem auto;

    .code-c-button:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
