.code-editor-message-bar {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  width: 100%;
  max-width: 30rem;

  .code-c-message-bar {
    animation: fadeIn 0.375s ease;
  }

  .code-c-message-bar__body {
    color: $color-text;
  }
}
