.code-editor {
  position: relative;

  .code-editor__menu {
    position: fixed;
    top: $header-height;
    left: 0;
    z-index: $z-index-menu;
    width: $menu-width;
    height: 100%;
  }

  .code-editor__main {
    min-height: calc(100vh - #{$header-height});
    padding-left: $menu-width;
    background: $color-white;
  }

  .code-editor__hidden-timer {
    display: none;
  }

  .code-editor-message-bar {
    position: fixed;
    top: calc(#{$header-height} + 1rem);
    right: 1rem;
    z-index: $z-index-message-bar;
  }

  .code-editor__bad-health {
    padding: 1rem;
    margin-left: 4rem;
  }

  .code-editor__tutorial-finished {
    $tutorial-finished-width: 640px;

    .modal-card {
      width: 100%;
      max-width: $tutorial-finished-width;
      height: 100%;
      max-height: 593px;
      background-color: $color-neutral-500;
      background-image: url("./assets/images/tutorial_finished.png");
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: $tutorial-finished-width;
    }

    .modal-card-head {
      text-align: center;
      background: transparent;
      border-bottom: 0;

      .modal-card-title {
        font-family: $font-family-heading;
        font-size: 28.98px;
        color: $color-white;
      }
    }

    .modal-card-body {
      height: 100%;
      background: transparent;
    }

    .modal-card-foot {
      border-top: 0;

      .code-c-button {
        height: 42px;
        color: $color-text;
        background-color: $color-primary;
      }
    }
  }
}
