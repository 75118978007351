.code-c-header {
  z-index: $z-index-header;
  padding: 0 1.5rem;
  margin-bottom: 0 !important;
  line-height: calc(#{$header-height} - 1px);
  color: $header-color;
  background: $header-background-color;
  border-bottom: 1px solid $color-gray-300;

  .code-c-header__left {
    &.code-c-left-block {
      align-items: center;
      align-self: center;
    }
  }

  .code-c-header__right {
    align-self: center;

    .code-c-header__right-item:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .code-c-header__custom-logo {
    &:hover {
      text-decoration: none;
    }
  }

  .code-c-select {
    max-width: 14rem;

    .select {
      vertical-align: middle;
    }
  }

  .code-c-header__language-switch {
    height: auto;

    .select {
      color: initial;
      background: initial;
    }
  }

  .code-c-header__admin-name {
    margin: 0 1rem 0 0.5rem;
  }

  .code-c-header__applicant {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .code-c-header__org-picker {
    margin-right: 0.5rem;
  }

  .code-c-header__account-menu {
    .code-c-header__system-admin {
      margin-right: 0.5rem;
      color: $color-white;
      background-color: $color-yellow;
    }
  }
}
