.code-account-menu {
  position: relative;

  .code-account-menu__trigger {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;

    .code-c-avatar {
      margin-right: 1rem;
    }
  }

  .code-account-menu__menu {
    position: absolute;
    top: calc(#{$header-height} - 0.15rem);
    right: 0;
    z-index: $z-index-account-menu;
    display: none;
    flex-direction: column;
    min-width: 10rem;
    max-width: 25rem;
    white-space: nowrap;
    background: $color-white;
    border: 1px solid $color-gray-light;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

    &.is-open {
      display: flex;
      animation: slideInDown 0.3s;
    }
  }

  .code-account-menu__menu-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0 1.75rem 0 1rem;
    line-height: 2.75rem;
    color: $color-text;
    transition: background-color 0.3s;

    &:hover {
      text-decoration: none;
      background: transparentize($color-gray-light, 0.7);
    }

    .badge::after {
      left: calc(100% + 0.25rem);
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background: $color-border-gray;
  }
}

@include mq(sm) {
  .code-account-menu .code-account-menu__trigger .code-c-avatar {
    margin-right: 0.5rem;
  }

  .code-c-icon {
    width: auto;
  }

  .dropdown {
    margin-left: 0.5rem;
  }
}
