.code-c-expand-contents {
  height: 0;
  overflow: hidden;
  font-size: 1em;
  vertical-align: top;
  border: 1px solid rgba(221, 230, 235, 0); // $color-neutral-200 with 0 alpha
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  transition: height 0.25s ease-in-out, border 0.25s step-end;


  &.is-expanded {
    height: var(--content-height, auto);
    border: 1px solid $color-neutral-200;
    border-top: none;
    transition: height 0.25s ease-in-out, border 0.25s step-start;

    &:not(.is-bordered) {
      border: 1px solid rgba(255,255,255,0); // transparent
      border-top: none;
    }
  }

  &.is-readonly {
    color: $color-gray-600;
    cursor: not-allowed;

    strong {
      color: $color-gray-600;
    }
  }

  .code-c-expand-contents__contents {
    padding: 1rem 1.5rem;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    &.is-visible {
      opacity: 1;
    }

    .code-c-form-group { // fix for showing form fields in the toggle container
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
