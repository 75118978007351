.code-exam-restriction {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .code-exam-restriction__input-container {
    margin-bottom: 1.5rem;

    .code-exam-restriction__input {
      font-size: 0.9rem;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .code-c-accordion__detail {
    min-height: 28rem;
  }

  .code-exam-restriction__exams {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .code-exam-restriction__exams__list {
      display: grid;
      grid-template-columns: max-content 1fr;
      row-gap: 0.5rem;
      align-items: flex-end;
      max-height: 20rem;
      overflow-y: scroll;

      .code-exam-restriction__exams__list__label {
        padding-left: 0.75rem;
        overflow: hidden;
        line-height: 1.25;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        &.disabled {
          color: $color-gray-600;
          cursor: not-allowed;
        }
      }
    }
  }
}
