.code-c-collapsible-challenge-column {
  position: relative;
  transition: background-color 0.2s;

  &:hover {
    background: transparentize($color-gray-100, 0.7);
  }

  .code-c-collapsible-challenge-column__toggle-wrapper {
    position: relative;

    // HACK: Adjust the toggle button swings during clicks
    padding: 0 0 0 1px;

    .code-c-collapsible-challenge-column__toggle {
      padding: 1.25rem 0.75rem;
    }
  }

  .code-c-collapsible-challenge-column__header {
    td.code-c-column {
      padding-bottom: 0.5rem;
      border-bottom: 0 !important;
    }

    &:hover {
      background: transparent !important;
    }
  }

  .code-c-collapsible-challenge-column__middle {
    td.code-c-column {
      padding-top: 0;
      border-top: 0 !important;
    }

    .code-c-collapsible-challenge-column__middle-inner {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }

    .code-c-collapsible-challenge-column__description {
      padding: 1rem;
      cursor: pointer;
      background: $color-gray-super-light;

      &.disabled {
        cursor: unset;
      }
    }

    .code-c-tag-cloud {
      margin-bottom: 0;
    }

    &:hover {
      background: transparent !important;
    }
  }

  &.is-pinned {
    .code-c-collapsible-challenge-column__header,
    .code-c-collapsible-challenge-column__middle {
      td:first-child {
        box-shadow: 3px 0 0 0 $color-yellow inset;
      }
    }

    .code-c-collapsible-challenge-column__toggle-wrapper {
      // HACK: Adjust the toggle button swings during clicks
      padding: 0;
    }
  }

  .code-c-collapsible-challenge-column__toggle-pin {
    .code-c-tooltip {
      z-index: unset;
    }
  }

  .code-c-collapsible-challenge-column__pinned {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 0.625rem;
    color: $color-gray;
    white-space: nowrap;

    .code-c-icon {
      color: $color-yellow;
      transform: rotate(45deg);
    }
  }
}
