.code-applicant-signin {
  .code-applicant-signin__exam {
    min-height: 4.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid $color-border-gray;
    border-radius: 6px;
  }

  .code-applicant-signin__text {
    margin-bottom: 1rem;
  }
}
