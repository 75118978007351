.code-c-ellipsis-middle {
  display: inline-flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;

  & > span {
    display: inline-block;

    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-child {
      max-width: 100%;
    }
  }
}
