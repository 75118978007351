.code-submission-timeline__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid $color-neutral-200;
}

.code-submission-timeline {
  min-width: 18.75rem;

  .code-submission-timeline-item {
    @include text-md;

    color: $color-gray-900;

    @media print {
      .code-c-button,
      .code-c-icon-button {
        display: none;
      }
    }
  }
}
