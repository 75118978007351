.code-c-walk-through-webcam {
  $walk-through-header-height: 62px;

  display: block;
  opacity: 0;
  transform: scale(0);

  &.is-open {
    animation: fadeIn 0.5s forwards;
  }

  .code-c-walk-through__close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    height: 24px;
    padding: 0 6px;
    font-size: 14px;
    background-color: transparent;
    border-radius: 50%;

    .code-c-icon {
      color: $color-gray-dark;
    }

    &.is-white {
      background-color: $color-gray-300;

      .code-c-icon {
        color: $color-white;
      }
    }

    &:hover {
      background-color: transparent;

      .code-c-icon {
        color: $color-text;
      }
    }

    &.is-white:hover {
      background-color: $color-gray-300;

      .code-c-icon {
        color: $color-white;
      }
    }
  }

  .code-c-walk-through__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .code-c-walk-through__skip {
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: 20px;
  }

  .code-c-walk-through__dots {
    display: flex;
    margin-bottom: 1.75rem;
  }

  .code-c-walk-through__dot {
    width: 6px;
    height: 6px;
    background-color: $color-gray;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &.is-current {
      width: 8px;
      height: 8px;
      background-color: $color-gray-dark;
    }
  }

  .code-c-walk-through__item {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    animation-name: walk-through-fade;
    animation-duration: 1.5s;

    &.is-current {
      display: flex;
    }
  }

  @keyframes walk-through-fade {
    from {
      opacity: 0.4;
    }

    to {
      opacity: 1;
    }
  }

  &.modal {
    .modal-card {
      top: 50%;
      margin: 0 auto;
      transform: translateY(-50%);
    }

    .modal-card-head {
      height: $walk-through-header-height;
      padding: 1.1875rem 1.5rem;
      text-align: center;
      background: $color-white;
      border-top: 8px solid $color-primary-500;
      border-bottom: 1px solid $color-gray-200;

      .modal-card-title {
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: $color-gray-800;
        text-align: start;
      }
    }

    .modal-card-body {
      position: relative;
      padding: 0;
    }

    .modal-card-foot {
      position: relative;
      flex-direction: column;
      justify-content: center;
      padding-top: 1.3125rem;
      padding-bottom: 1.25rem;
      background: $color-white;
      border-top: 1px solid $color-gray-200;

      .modal-card-foot__text {
        margin-bottom: 1rem;
        text-align: center;
      }

      .modal-card-foot__buttons {
        display: flex;

        .code-c-button {
          min-width: 7.75rem;
          height: unset;
          font-size: 1rem;

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
