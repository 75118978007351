.code-challenge-new {
  .code-challenge-new__right-label {
    line-height: 27px;

    .code-c-button {
      margin-left: 1rem;
    }
  }

  .code-challenge-new__right-table {
    width: 100%;
    border: 1px solid $color-border-gray;

    .table-icon {
      width: 4rem;
      padding-left: 0.5rem;
    }

    .table-id {
      width: 6rem;
      text-align: center;
      white-space: nowrap;
    }

    .table-title {
      width: 100%;
      padding: 0.5rem;
      word-break: break-all;
    }

    .table-buttons {
      width: 8rem;
      text-align: center;
    }

    .code-challenge-new__right-table-head {
      display: flex;
      align-items: center;
      height: 2rem;
      background: $color-gray-light;
    }

    .code-challenge-new__right-table-body {
      display: flex;
      align-items: center;
      min-height: 5rem;
      margin: 0 -1px -1px;
      border: 1px solid $color-border-gray;

      .code-challenge-new__right-table-body__status_tag {
        margin-left: 0.5rem;
      }
    }
  }

  .challenge-questions__action {
    cursor: pointer;
  }

  .challenge-questions__placeholder {
    visibility: hidden;
  }

  .code-c-tooltip-wrapper {
    margin-left: 0.5em;
  }
}
