.code-submission-list-table-row {
  .code-c-tooltip {
    z-index: unset;
  }

  .code-submission-list-table-row_submitted-column {
    width: 7rem;

    .code-c-tooltip-wrapper {
      width: inherit;

      .code-c-tag {
        width: inherit;
        margin-top: 0.5rem;
        font-weight: bold;
      }
    }
  }
}
