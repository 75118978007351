.code-c-submission-result-summary {
  .code-c-submission-result-summary__invalid-score {
    position: relative;

    .code-c-icon {
      position: absolute;
      top: -1rem;
      right: -1rem;
      color: $color-yellow;
    }
  }

  .code-c-submission-result-summary__invalid-score-ask {
    padding: 3px;
    margin: 0.5rem -0.5rem 0;
    font-size: smaller;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
  }
}

@include mq(sm) {
  .code-c-submission-result-summary .code-c-row {
    flex-direction: column;
  }
}
