.code-c-select {
  height: 2.25rem;

  select {
    height: 2.25rem;
  }

  select:required:invalid {
    color: $color-gray-dark;
  }

  .is-danger {
    border: 1px solid $color-red;
  }
}
