.code-c-radio {
  font-size: 1em;

  .code-c-radio__label {
    display: flex;
    gap: 0.5rem;

    &.is-readonly {
      color: $color-gray-600;
      cursor: not-allowed;

      strong {
        color: $color-gray-600;
      }
    }

    .code-c-radio__radio {
      flex-grow: 0;
      flex-shrink: 0;
      align-self: flex-start;
      margin-top: 2px;

      &[readonly] {
        cursor: not-allowed;
      }

      &.is-centered {
        align-self: center;
      }
    }

    .code-c-radio__label__text {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      align-items: center;
      word-break: break-word;
    }
  }

  &.is-new {
    .code-c-radio__label {
      cursor: pointer;

      &.is-readonly {
        cursor: not-allowed;
      }

      &:hover:not(.is-readonly) {
        .code-c-radio__checkmark-container {
          border-color: $color-neutral-400;
        }

        input:checked ~ .code-c-radio__checkmark-container {
          border-color: $color-primary-600;

          .code-c-radio__checkmark::after {
            background: $color-primary-600;
          }
        }
      }
    }

    .code-c-radio__radio {
      position: absolute;
      margin: 0;
      cursor: pointer;
      opacity: 0;
    }

    .code-c-radio__checkmark-container {
      position: relative;
      width: 1rem;
      height: 1rem;
      margin: 0.125rem;
      border: 1px solid $color-neutral-300;
      border-radius: 50%;

      .code-c-radio__checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &::after {
          position: absolute;
          content: "";
        }
      }
    }

    input {
      &[disabled] {
        & ~ .code-c-radio__checkmark-container {
          background-color: rgba($color-gray-100, 0.5);
          border-color: $color-gray-300;
        }
      }

      &:checked {
        & ~ .code-c-radio__checkmark-container {
          border-color: $color-primary-500;

          .code-c-radio__checkmark::after {
            width: 0.5rem;
            height: 0.5rem;
            background: $color-primary-500;
            border-radius: 50%;
          }
        }

        &[disabled] {
          & ~ .code-c-radio__checkmark-container {
            background-color: $color-white;
            border-color: $color-primary-300;

            .code-c-radio__checkmark::after {
              background: $color-primary-300;
            }
          }
        }
      }

      &:focus-visible {
        & ~ .code-c-radio__checkmark-container {
          &::before {
            position: absolute;
            inset: -0.25rem;
            content: "";
            border: 2px solid $color-primary-500;
            border-radius: 1rem;
          }
        }
      }
    }
  }

  &.is-stretch {
    width: 100%;
  }
}
