.code-c-submission-result-action-log__table-row-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
  min-height: 0;
  padding: 1rem;
  line-height: 1.125rem;
  background-color: $color-neutral-100;
  border-top: 1px solid $color-border-gray;

  &.is-collapse {
    height: 0;
    padding: 0;
    overflow: hidden;
    border-top-width: 0;
  }
}

.code-c-submission-result-action-log__table-row-detail::after {
  position: absolute;
  top: -0.5rem;
  left: 1.25rem;
  width: 1rem;
  height: 1rem;
  content: "";
  background-color: $color-neutral-100;
  border-top: 1px solid $color-border-gray;
  border-left: 1px solid $color-border-gray;
  transform: rotate(45deg);
}
