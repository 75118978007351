.code-exam-detail-top__cheat-monitor {
  border: 1px $color-neutral-200 solid;
  border-radius: 0.5rem;

  .code-c-submission-detail__cheat-monitor-header {
    display: inline-flex;
    align-items: center;
    min-width: max-content;

    h3 {
      @include fontSize("heading3", true);
    }
  }

  .code-c-submission-detail__cheat-monitor-helpbutton {
    font-size: 0.875rem;
    color: $color-neutral-500;
    cursor: pointer;

    &:hover {
      color: $color-neutral-700;
      border-radius: 0.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    }
  }

  .code-c-submission-detail__cheat-monitor-banner {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;

    .banner-link {
      vertical-align: middle;
    }
  }

  .code-submission-detail__entry-form-summary {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: $color-white;
    border: 0;
    border-radius: 0.5rem;

    &:hover {
      background: $color-neutral-100;
    }

    h3 > small {
      @include text-md;

      margin-left: 0.5rem;
      color: $color-gray-600;
    }

    &.code-c-accordion__bar__disabled:hover {
      background: $color-white; // Override hover for disabled
    }
  }

  .code-submission-detail__entry-form-detail {
    padding: 0.5rem 1rem 1.5rem;
    border: 0;
  }

  .code-c-custom-form-display {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 0;
    border: 1px solid $color-neutral-200;

    &:not(:last-child) {
      border-bottom: 0;
    }

    dt {
      padding: 0.5rem 1rem;
      margin-bottom: 0;
      background: $color-neutral-100;
    }

    dd {
      padding: 0.5rem 1rem;
      margin-bottom: 0;
    }
  }

  .code-c-custom-form-display__inlinelistwrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;

    & > div {
      display: flex;
      align-items: center;
    }

    .code-c-custom-form-display__inlinelistitem {
      display: inline-block;
      max-width: 8.5rem;
      padding-right: 0;
      margin-right: -0.25rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .code-c-submission-detail__cheat-monitor-sameip-info {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .code-c-submission-detail__cheat-monitor-sameip-info-button {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
    margin-left: 0.5rem;
  }
}
