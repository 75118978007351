.code-c-password-requirements {
  margin: 0 2rem;

  .code-c-password-requirements__passed {
    color: $color-success;
    white-space: nowrap;
  }

  .code-c-password-requirements__failed {
    color: $color-error;
    white-space: nowrap;
  }

  .code-c-password-requirements__title {
    margin-bottom: 0.5rem;
  }
}
