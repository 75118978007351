.code-user-container {
  .code-c-header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .code-user-container__body {
    min-height: 100vh;
    padding-top: $header-height;
    background: $color-white;
  }

  .code-c-service-terms,
  .code-c-privacy-policy {
    max-width: $hero-tile-max-width-large;
    margin: 5rem auto;
  }
}
