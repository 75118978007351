.code-review-drawer {
  display: flex;
  flex-direction: column;

  .code-review-drawer__header {
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid $color-neutral-200;

    .code-review-drawer__header__left {
      display: flex;
      column-gap: 0.75rem;

      .code-review-drawer__header__left__title {
        > *:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }

    .code-review-drawer__title {
      span {
        word-break: break-all;
      }

      .code-review-drawer__title__name {
        @include text-heading2;

        line-height: 1.75rem;
        cursor: pointer;

        &:hover {
          color: $color-neutral-900;
        }
      }

      .code-review-drawer__title__email {
        margin-left: 0.5rem;
        color: $color-gray-600;

        @include text-md;
      }
    }

    .code-review-drawer__evaluation {
      display: flex;
      gap: 0.5rem;
    }

    .code-review-drawer__header__right {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-end;
    }
  }

  .code-review-drawer__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 1rem;
    padding: 1rem;
    overflow: hidden;

    .code-review-drawer__review-summary {
      display: flex;
      gap: 0.5rem;

      h3 {
        @include text-lg;

        font-weight: 700;
      }

      .code-c-review-summary {
        span {
          @include text-md;
        }
      }
    }

    .code-review-drawer__scroll-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      overflow-y: auto;
    }
  }
}
