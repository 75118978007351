.code-c-project-kind-tag {
  display: inline-block;

  .code-c-project-kind-tag__1 {
    color: $color-black;
    background: $color-primary-500;
  }

  .code-c-project-kind-tag__2 {
    color: $color-black;
    background: #4BCACB;
  }

  .code-c-project-kind-tag__3 {
    color: $color-black;
    background: #ED4144;
  }

  .code-c-project-kind-tag__4 {
    color: $color-white;
    background: #1C4994;
  }

}
