.code-account-general {
  padding: 1.5rem;

  .code-account-general__form {
    padding: 0 0 1.75rem;
  }

  .code-account-general__label {
    display: flex;
    gap: 0.5rem;
  }

  .code-account-general__text {
    margin: 2rem 0;
  }

  .code-account-general__button {
    margin-top: 4rem;
  }

  .code-account-general__form-group {
    > div:not(:last-child) {
      margin-bottom: 1rem;
    }

    > div:last-child {
      margin-bottom: 1.875rem;
    }
  }
}
