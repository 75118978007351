.code-c-submission-exam-summary {
  display: flex;
  flex: 1;
  flex-basis: 400px;
  flex-flow: wrap;
  min-width: 400px;

  .chartjs-size-monitor-expand > div {
    width: 0;
    height: 0;
  }

  .submission-exam-summary__exam-legend {
    float: right;
    margin-top: 1rem;
    margin-right: 1rem;
    font-size: 14px;
    font-weight: normal;

    .submission-exam-summary__exam-legend__color {
      display: inline-block;
      width: 20px;
      height: 15px;
      margin-right: 0.5rem;
      vertical-align: middle;
      background: $color-green-500;
    }
  }

  .submission-exam-summary__exam-grades__box {
    padding: 1rem;
    margin: 0.1rem;
    background: $color-gray-super-light;
    border-radius: 5px;

    .result-value {
      font-size: 3rem;
      font-weight: bold;
      line-height: 3rem;

      .result-suffix {
        font-size: 1.5rem;
      }
    }

    .label {
      padding-bottom: 0.25rem;
      font-weight: bold;
    }
  }

  .submission-exam-summary__chart-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    direction: ltr;

    .submission-exam-summary__chart-placeholder__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 250%;
      height: 200%;
    }
  }

  .graph {
    position: relative;
    width: 100%;
  }

  .score {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .grade {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .ranking {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .averages {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;

    table {
      width: 100%;
      table-layout: fixed;

      td {
        padding: 0.5rem 0;
        text-align: center;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid $color-border-gray;
      }
    }
  }

  .rank-ss,
  .rank-s {
    color: $color-report-rank-ss;
  }

  .rank-a {
    color: $color-report-rank-a;
  }

  .rank-b,
  .rank-c {
    color: $color-report-rank-b;
  }

  .rank-d,
  .rank-e {
    color: $color-report-rank-d;
  }

  .rank-f {
    color: $color-report-rank-f;
  }
}
