.editor-file-uploader {
  display: flex;
  max-height: 15rem;
  padding: 1rem 0;
  border: 1px solid $color-border-gray;
  border-radius: 0.5rem;
}

.editor-file-uploader__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
  overflow: hidden;
  overflow-y: auto;
}

.editor-file-uploader__header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.editor-file-uploader__title {
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: $color-gray-900;
}

.editor-file-uploader__empty {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  background-color: $color-neutral-100;
  border-radius: 0.25rem;
}

.editor-file-uploader__empty-text {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: $color-neutral-500;
}

.editor-file-uploader__list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
