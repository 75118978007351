.code-admin-sso {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  border-top: 1px solid $color-border-gray;

  .code-admin-sso__container {
    display: flex;
    flex-direction: column;
    gap: 2rem
  }

  .code-admin-sso__main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem
  }

  .code-admin-sso__item {
    display: flex;
    flex-direction: column;
    gap: 1rem
  }

  .code-admin-sso__sso-enabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    color:  $color-success;
    background: $color-success-background;
    border: 1px solid $color-success;
    border-radius: 3px;
  }

  .code-admin-sso__metadata-ready {
    display: flex;
    gap: 2rem;
    align-items: center
  }

  .code-c-page-sub-title {
    margin-bottom: 0;
  }

  .code-c-copy-box {
    .code-c-input {
      width: 100%;
    }
  }
}