.code-exam-dashboard-outline {
  display: flex;
  flex-direction: column;
  min-width: 39rem;
  padding: 1rem;
  background-color: #f5f8fa80;
  border: 1px solid $color-neutral-200;
  border-radius: 0.5rem;

  .code-c-tag-cloud > .code-c-tag {
    margin-bottom: 0;
  }
}

.code-exam-dashboard-outline__header {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 0.75rem;

  > a {
    flex-shrink: 0;
  }
}

.code-exam-dashboard-outline__title {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  overflow: hidden;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.code-exam-dashboard-outline__sub-title {
  color: $color-gray-600;
}

.code-exam-dashboard-outline__tags {
  display: flex;
  gap: 0.5rem;
}

.code-exam-dashboard-outline__exam {
  display: flex;
  flex: 1;
  gap: 0.75rem;

  > div {
    flex-basis: 10.75rem;
  }

  @include mq-dashboard(md) {
    flex-direction: column;
  }
}

.code-exam-dashboard-outline__content-row {
  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
}

.code-exam-dashboard-outline__section-body {
  @include text-md;

  overflow: hidden;
  color: $color-gray-900;
  text-overflow: ellipsis;

  &.code-exam-dashboard-outline__section-body__left-margin {
    margin-left: 0.25rem;
  }
}

.code-exam-dashboard-outline__checkbox {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.code-exam-dashboard-outline__estimation {
  display: flex;
  align-items: flex-start;

  @include text-md;

  color: $color-gray-900;

  .icon {
    color: $color-gray-500;
  }
}

.code-exam-dashboard-outline__icon {
  margin-right: 0.25rem;
}

.code-exam-dashboard-outline__reviewer-list {
  @include text-md;

  display: -webkit-box;
  overflow: hidden;
  line-height: 1.25rem;
  color: $color-gray-900;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.code-exam-dashboard-outline__challenge-style {
  @include text-md;

  color: $color-gray-900;
}
