.code-editor-side-menu {
  $menu-width-expanded: 24.5rem;

  width: $menu-width;
  height: calc(100% - #{$header-height});
  overflow-y: auto;
  background: $color-gray-super-light;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  transition: width 0.125s;


  .code-editor-side-menu__list {
    position: relative;
    min-height: calc(100vh - #{$header-height});
  }

  .code-editor-side-menu__list-item {
    position: relative;
    min-height: 3rem;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background: transparentize($color-gray, 0.8);
    }

    &.is-steps {
      &::before {
        position: absolute;
        top: 0;
        bottom: 48px;
        left: 32px;
        content: "";
        border-right: 3px solid $color-gray-600;
        transform: translateX(-50%);
      }

      &::after {
        position: absolute;
        top: 48px;
        bottom: 0;
        left: 32px;
        content: "";
        border-right: 3px solid $color-gray-600;
        transform: translateX(-50%);
      }

      &.is-top-steps {
        &::before {
          border-right: 0;
        }
      }

      &.is-bottom-steps {
        &::after {
          border-right: 0;
        }
      }
    }
  }

  .code-editor-side-menu__list-item-icon {
    position: relative;
    flex-basis: $menu-width;
    flex-grow: 0;
    flex-shrink: 0;
    color: $color-white;
    text-align: center;

    .code-editor-side-menu__list-item-icon__step {
      position: absolute;
      top: 17px;
      left: 50%;
      font-family: $font-family-pop;
      line-height: 1;
      transform: translateX(-50%);
    }

    .code-editor-side-menu__list-item-icon__badge {
      position: absolute;
      line-height: 1;

      &.is-finished {
        top: 8px;
        left: 33px;
        color: $color-red;
      }

      &.is-started {
        top: 6px;
        left: 35px;
        color: $color-yellow;
      }
    }
  }

  .code-editor-side-menu__list-item-text {
    flex-grow: 1;
    padding: 0 0.5rem;
    overflow: hidden;
    line-height: 48px;
    color: $color-text;
    text-overflow: ellipsis;
    border-bottom: 1px solid transparentize($color-white, 0.25);

    &.has-close {
      display: flex;
      justify-content: flex-end;
      border-bottom-color: transparent;
    }

    .code-c-applicant-challenge-title-column {
      padding: 0.5em 0;
    }
  }

  .code-editor-side-menu__list-item-link {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
    }

    &.active {
      background: $color-gray-900;

      .code-editor-side-menu__list-item-text {
        background: $color-neutral-200;
      }

      .code-editor-side-menu__list-item-icon {
        color: $color-primary-400;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        content: "";
        border-left: 4px solid $color-primary-400;
      }
    }
  }

  .code-editor-side-menu__list-item-info {
    flex-grow: 1;
    flex-shrink: 1;
    color: $color-text;
  }

  .icon-bar,
  .icon-cross {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
  }

  .icon-bar {
    margin: 0 auto;
  }

  .icon-bar-line {
    width: 2rem;
    border: 1px solid $color-white;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .icon-cross {
    .icon-bar-line {
      border-color: $color-gray;
    }

    .icon-bar-line:first-child {
      position: absolute;
      margin: 0;
      transform: rotate(45deg);
    }

    .icon-bar-line:last-child {
      position: absolute;
      margin: 0;
      transform: rotate(-45deg);
    }
  }

  .code-editor-side-menu__dummy-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $menu-width;
    background: $color-gray-800;
  }

  &.is-open {
    width: $menu-width-expanded;

    .code-editor-side-menu__list-item {
      &.is-top {
        .code-c-icon {
          transform: scale(-1, 1);
        }
      }
    }
  }
}
