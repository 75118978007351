.code-c-button {
  min-width: 8.5rem;
  transition-duration: 0.25s;
  transition-property: color, background-color, border-color, traslate,
    box-shadow;

  &:hover {
    text-decoration: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

    &[disabled] {
      box-shadow: none;
      transform: none;
    }
  }

  &.is-stretch {
    width: 100%;
  }

  &.is-shrink {
    min-width: unset;
  }

  &.is-narrow {
    padding: 0.25rem;
  }

  &.is-primary {
    color: $color-black;

    &:active,
    &:focus,
    &:hover,
    &[disabled] {
      color: $color-black;
    }
  }

  &.is-primary.is-outlined {
    color: $color-primary-700;
    border-color: $color-primary-700;

    &:active,
    &:hover,
    &:focus,
    &[disabled] {
      color: $color-primary-700;
      background-color: inherit;
      border-color: $color-primary-700;
    }
  }

  &.is-warning {
    color: $color-white;
    background-color: $color-yellow;

    &:active,
    &:focus,
    &[disabled] {
      color: $color-white;
      background-color: $color-yellow;
    }

    &:hover {
      color: $color-white;
      background-color: darken($color-yellow, 1%);
    }

    &.is-loading::after {
      border-color: transparent transparent $color-white $color-white !important;
    }
  }

  &.is-secondary {
    padding: 0.375rem 0.75rem;
    color: $color-gray-800;
    border: 1px solid $color-gray-400;
    border-radius: 0.25rem;
  }

  &.is-radiotab {
    padding: 0.375rem 0.75rem;
    color: $color-gray-800;
    border: 1px solid $color-gray-200;
    border-radius: 0.25rem;
  }

  &.is-radiotab-selected {
    padding: 0.375rem 0.75rem;
    color: $color-gray-800;
    background-color: $color-gray-100;
    border: 1px solid $color-gray-200;
    border-radius: 0.25rem;
  }

  &.is-rounded {
    border-radius: 1.5rem;
  }
}
