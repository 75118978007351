.code-c-project-switch {
  vertical-align: middle;

  .code-c-button {
    display: block;
    color: $color-white;
    background: $color-gray-500;

    &:hover {
      transform: none;
    }
  }

  .dropdown-menu {
    top: calc(100% + 0.5rem);
    max-height: calc(100vh - #{$header-height + 0.5rem});
    padding-top: 0;
    overflow-y: auto;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.18);
  }

  &.is-active {
    .dropdown-menu {
      animation: slideInDown 0.3s;
    }
  }
}

@include mq("sm") {
  .code-c-project-switch .code-c-button {
    max-width: 190px;
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
