.code-c-submission-detail-question {
  display: flex;
  flex-direction: column;

  .code-c-submission-detail-question__header {
    padding: 0.75rem;
    background: $color-white;

    .code-c-submission-detail-question__status {
      display: flex;
      align-items: center;
      padding: 1rem;
      border-radius: 3px;

      .code-c-submission-detail-question__title {
        @include fontSize("large");

        margin-left: 0.5rem;
        text-decoration: underline;
      }
    }
  }

  .code-c-submission-detail-question__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 1rem 2rem 2rem;

    .code-c-submission-detail-question__sub-title {
      font-weight: bold;
    }

    .code-c-submission-detail-question__question {
    }

    .code-c-submission-detail-question__table-header {
      padding: 0.5rem;
      color: $color-gray-dark;
      background: $color-gray-light;
      border: 1px solid $color-border-gray;
    }

    .code-c-submission-detail-question__table-col {
      padding: 0.5rem;
      margin-bottom: 0.25rem;
      border-right: 1px solid $color-border-gray;
      border-bottom: 1px solid $color-border-gray;
      border-left: 1px solid $color-border-gray;
    }

    .code-c-submission-detail-question__status-icon {
      &.is-wrong {
        color: $color-error;
      }

      &.is-correct {
        color: $color-success;
      }
    }

    .code-c-submission-detail-question__choice {
      margin-left: 1.5rem;

      .code-c-submission-detail-question__choice__choice {
        word-break: break-all;
      }
    }

    .code-c-submission-detail-question__dummy-blank {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      border: 1px solid $color-gray-super-dark;
    }
  }

  .question-freetext-table {
    display: grid;
    grid-template-columns: 1.5rem auto;
    column-gap: 1rem;
  }

  .question-freetext-char-count {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75rem;
  }

  .question-fib-table {
    display: grid;
    grid-template-columns: 1.5rem 3rem 1fr 1fr;
    column-gap: 1rem;
  }

  .question-mcq-table-head {
    display: grid;
    grid-template-columns: 3rem 8rem 8rem auto;
    column-gap: 1rem;
  }

  .question-mcq-table-body1 {
    display: grid;
    grid-template-columns: 1.5rem auto;
    column-gap: 1rem;

    &.is-correct {
      .code-c-icon {
        color: $color-success;
      }
    }
  }

  .question-mcq-table-body2 {
    display: grid;
    grid-template-columns: 8rem 8rem auto;
    column-gap: 1rem;
  }


  &.is-behind {
    .code-c-submission-detail-question__header {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    }
  }

  &.is-wrong {
    .code-c-submission-detail-question__status {
      background: $color-error-background;
      border: 1px solid $color-error;

      .code-c-icon {
        color: $color-error;
      }
    }
  }

  &.is-correct {
    .code-c-submission-detail-question__status {
      background: $color-success-background;
      border: 1px solid $color-success;

      .code-c-icon {
        color: $color-success;
      }
    }
  }
}
