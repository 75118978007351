.code-c-linked-challenge {
  display: inline-flex;
  padding: 0.5rem;
  border: 1px solid $color-border-gray;
  border-radius: 3px;

  &.clickable {
    cursor: pointer;

    .code-c-linked-challenge__linked-link {
      color: $color-link;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .code-c-linked-challenge__linked-link {
    display: flex;
    margin-left: 0.5rem;
    color: $color-gray-700;
    cursor: text;

    &:hover {
      text-decoration: none;
    }
  }
}
