.code-submission-evaluation-status {
  &.code-submission-evaluate-button__disabled {
    pointer-events: none;
    cursor: auto;
    background-color: transparentize($color-gray-light, 0.5);
  }

  &.code-submission-evaluate-button__no-border, &.code-submission-evaluation-status__no-button {
    border: 0;
  }

  &.code-submission-evaluation-status__no-button {
    cursor: unset;
  }

  .code-submission-evaluation-status__pass {
    color: $color-green-500;
  }

  .code-submission-evaluation-status__fail {
    color: $color-red-500;
  }

  .code-submission-evaluation-status__not-evaluated {
    color: $color-gray;
  }
}
