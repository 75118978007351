.code-admin-member-list {
  .code-admin-member-list__header {
    padding: 1.5rem 1.5rem 0;

    .code-admin-member-list__header__search {
      width: 18rem;
      margin: unset;
      margin-right: 0.75rem;
      margin-left: 1rem;
    }
  }

  .code-admin-member-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-c-table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .system-admin_member__column {
    margin-bottom: 1rem;
  }
}
