.code-c-hero-tile {
  width: 100%;
  max-width: $hero-tile-max-width;
  padding: 2.5rem;
  background: $color-white;
  border-radius: 8px;

  &.is-large {
    max-width: $hero-tile-max-width-large;
  }
}
