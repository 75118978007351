.code-c-notepopover-content {
    max-height: 300px;
    overflow: auto;
}

div.code-c-notepopover-content__markdown {
    color: $color-gray-500;

    &.markdown {
        .code-c-rich-markdown__body {
            border: none;

            .code-c-markdown {
            padding: 0;
            }
        }
    }

    .markdown-body {
        font-family: inherit !important;
        overflow-wrap: anywhere;

        code {
        white-space: pre-wrap;
        }

        p {
        @include text-md;

        line-height: 1.25rem;
        }
    }
}
  


