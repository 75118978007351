.code-c-review-summary {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.25rem;
  column-gap: 1rem;
  background: inherit;

  &.is-column {
    flex-direction: column;
  }

  .code-c-review-summary__item {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.25rem;
    align-items: center;
    color: $color-neutral-700;

    .code-c-icon {
      width: auto;
    }

    &.is-strongly-approved {
      .code-c-icon {
        color: $color-green-700;
      }
    }

    &.is-approved {
      .code-c-icon {
        color: $color-green-500;
      }
    }

    &.is-rejected {
      .code-c-icon {
        color: $color-red-500;
      }
    }

    &.is-strongly-rejected {
      .code-c-icon {
        color: $color-red-700;
      }
    }

    &.is-not-reviewed,
    &.is-stale {
      .code-c-icon {
        color: $color-neutral-500
      }
    }
  }
}
