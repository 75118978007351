.code-editor-exam-detail__fairness-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  font-size: 1rem;
  text-align: center;

  &__icon {
    width: 100px;
    height: 100px;
  }

  &__title-container {
    margin-top: 1rem;
  }

  &__title {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
  }
}
