.code-admin-challenge-collection-list {
  .code-admin-challenge-collection-list__header {
    padding: 1.5rem 1.5rem 0;
  }

  .code-admin-challenge-collection-list__table {
    margin: 0 1.5rem;

    .code-admin-challenge-collection-list__title {
      span:not(:first-child) {
        margin-left: 0.5rem;
      }
    }

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .code-admin-challenge-collection-list__count {
    margin-left: 0.5rem;
    font-size: 0.8125rem;
  }

  .code-admin-challenge-collection-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-c-sub-menu {
    padding: 1.5rem;
  }
}
