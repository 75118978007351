.code-c-spinner {
  position: relative;
  z-index: $z-index-spinner;
  display: inline-block;
  margin: 0 auto;
  font-size: 10px;
  text-indent: -9999em;
  border-style: solid;
  border-radius: 50%;
  transform: translateZ(0);
  animation: spinRight 0.8s infinite linear;

  &::after {
    width: $spinner-width;
    height: $spinner-height;
    border-radius: 50%;
  }
}

.code-c-spinner--base {
  width: $spinner-width;
  height: $spinner-height;
  border-width: 1.1em;
}

.code-c-spinner--tiny {
  width: 1rem;
  height: 1rem;
  border-width: 0.125rem;
}

.code-c-spinner--small {
  width: 1.5rem;
  height: 1.5rem;
}

.code-c-spinner--primary {
  border-color: transparentize($color-white, 0.3);
  border-left-color: transparentize($color-primary, 0.1);
}

.code-c-spinner--gray {
  border-color: $color-gray-300;
  border-left: $color-gray-900 0.125rem solid;
}

@keyframes spinRight {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
