.code-challenge-set-select {
  .code-challenge-set-select__radio-group {
    margin-top: 1rem;
  }

  .code-challenge-set-select__radio-group__item {
    display: inline-flex;
    flex-direction: column;
  }

  .code-c-radio {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
}
