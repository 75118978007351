.code-editor-appeal-comment {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 3rem);

  &.is-ai {
    .code-editor-appeal-comment__codeblock {
      display: none !important;
    }

    .code-editor-appeal-comment__comment {
      max-width: 40rem;
      margin: 0 auto;

      .code-editor-appeal-comment__comment__header {
        padding: 0 !important;
      }
    }
  }

  .code-editor-appeal-comment__alert {
    margin: 1.5rem;
    margin-bottom: 0;
  }

  .code-editor-appeal-comment__title {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    padding-bottom: 0;
    margin-bottom: 1rem;

    .code-c-page-title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .code-editor-appeal-comment__content {
    display: flex;
    flex: 1;
    overflow: auto;

    .code-editor-appeal-comment__codeblock {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-x: auto;

      .code-editor-appeal-comment__codeblock-code {
        flex: 1;
        overflow-x: none;
        tab-size: 2;

        code,
        pre {
          font-family: Menlo, Monaco, "Courier New", monospace;
        }
      }

      .code-editor-appeal-comment__codeblock-header {
        display: flex;
        height: 3rem;

        .file-select {
          display: flex;
          align-items: center;
          max-width: 150px;
          height: 100%;

          /* Doesn't currently work in Chrome */
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .select {
            height: 2.5em;
          }

          > div {
            align-self: flex-start;
          }
        }

        .tab-scroll {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          font-size: 1.5rem;
          cursor: pointer;
        }

        .disabled {
          display: none;
        }

        .code-editor-appeal-comment__tabs {
          position: relative;
          flex: 1;
          margin: 0 0.5rem;
          overflow: hidden;

          .gradient-left {
            position: absolute;
            left: 0;
            z-index: 10;
            width: 70px;
            height: 100%;
            pointer-events: none;
            background: linear-gradient(
              to left,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
            );
          }

          .gradient-right {
            position: absolute;
            right: 0;
            z-index: 10;
            width: 70px;
            height: 100%;
            pointer-events: none;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
            );
          }

          .tab-list {
            position: absolute;
            bottom: 0;
            display: flex;
            transition: all 0.2s;
          }

          .file-tab {
            display: inline-flex;
            max-width: 150px;
            height: 40px;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            margin: 0 5px;
            color: $color-gray-super-dark;
            cursor: pointer;
            border: 1px solid $color-border-gray;
            border-bottom: none;
            border-radius: 3px 3px 0 0;
          }

          .tab-label {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            pointer-events: none;
          }

          .tab-close {
            margin-left: 1rem;
            font-weight: bold;
          }

          .selected-tab {
            color: $color-white;
            background: $color-gray-super-dark;
          }
        }
      }
    }

    .code-editor-appeal-comment__comment {
      display: flex;
      flex-basis: 35rem;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      padding: 1rem;
      padding-top: 1.5rem;
      overflow: hidden;

      &.code-editor-appeal-comment__comment__ai {
        flex-basis: 45rem;
      }

      .code-editor-appeal-comment__comment__button {
        height: 3rem;
      }

      .code-editor-appeal-comment__comment__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .code-editor-appeal-comment__comment__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        border: 1px solid $color-border-gray;
        border-radius: 0.5rem;

        .code-editor-appeal-comment__comment__title {
          font-size: 1.25rem;
        }
      }

      .code-c-rich-markdown {
        flex-grow: 1;
        overflow-y: hidden;
        border-radius: 0;
      }
    }
  }
}
