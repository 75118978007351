.code-admin-challenge-collection-new {
  .modal-card-body {
    padding-bottom: 0 !important;
  }

  .code-admin-challenge-collection-form__preview {
    position: sticky;
    top: 2rem;
  }

  .code-c-challenge-collection-card {
    cursor: inherit;
  }
}
