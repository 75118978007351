.code-deliver-id {
  .modal-card-body {
    min-height: 300px;
  }

  .code-deliver-id__settings {
    padding: 1rem;
    margin-bottom: 1.875rem;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
  }

  .code-deliver-id__already-delivered {
    padding: 1rem;
    margin-top: 1rem;
    background-color: $color-warning-background;
    border: 1px solid $color-warning;
  }

  .code-deliver-id__language {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .code-deliver-id__already-delivered-header {
    margin-bottom: 1rem;
    font-weight: bold;
    color: $color-warning;
    text-decoration: underline;
  }

  .code-c-copy-box {
    .code-c-input {
      width: 100%;
      max-width: 30rem;
    }
  }
}

.code-deliver-id__applicant-counter {
  flex: 1 1 auto;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  text-align: right;
}
