.code-exam-dashboard {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  padding: 0 1.5rem 1.5rem;

  & > * {
    grid-column: span 5;

    // outline panel
    &:nth-child(3) {
      grid-column: span 3;
      min-width: 36rem;
    }

    // applicant funnel
    &:nth-child(4) {
      grid-column: span 2;
      min-width: 20rem;
    }

    @include mq-dashboard(md) {
      // outline panel and applicant funnel
      &:nth-child(3),
      &:nth-child(4) {
        grid-column: span 5;
        min-width: auto;
      }
    }
  }

  & > .code-exam-detail-header {
    padding: 0;
    margin: -1.5rem 0 0;
  }

  .code-exam-dashboard__panel {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    border: 1px $color-neutral-200 solid;
    border-radius: 0.5rem;

    .code-exam-dashboard__panel-header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table {
    border-collapse: collapse;
  }

  > .tags.code-c-exam-segment-tags {
    margin: 0;
  }
}
