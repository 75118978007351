.code-editor-exam-detail__camera-permission-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;

  &__spinner {
    margin-bottom: 0.625rem;
  }

  &__message {
    font-weight: 700;
  }

  &__external-link {
    text-align: center;
  }
}
