.code-diff-viewer-header {
  z-index: $z-index-header;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  height: calc(#{$header-height} - 1px);
  padding: 0 1.5rem;
  color: $header-color;
  background: $header-background-color;
  border-bottom: 1px solid $color-gray-300;

  .code-diff-viewer-header__left {
    display: flex;
    flex-grow: 1;
    gap: 1rem;
    align-items: center;
    min-width: 0;
    white-space: nowrap;
  }

  .code-diff-viewer-header__right {
    display: flex;
    flex-shrink: 0;
    gap: 1rem;
    align-items: center
  }

  .code-diff-viewer-header__title {
    @include fontSize("large", true);
  }

  .code-diff-viewer-header__score {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .code-c-icon {
      color: $color-gray;
    }
  }

  .code-diff-viewer-header__score-badge {
    display: flex;
    align-items: baseline;
    padding: 0.25rem 0.5rem;
    background: $color-gray-light;
    border-radius: 8px;
  }

  .code-diff-viewer-header__score-bold {
    font-weight: bold;
  }

  .code-diff-viewer-header__score__divider {
    color: $color-gray-200;
  }
}
