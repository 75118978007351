.auditlog {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem 1.5rem 0;
  overflow: hidden;

  .auditlog__td--small,
  td {
    min-width: unset;
  }

  .auditlog__table table.table {
    table-layout: auto;

    & td {
      white-space: nowrap;
    }
  }

  .auditlog__tag--primary.tag {
    color: $color-white;
    background-color: $color-primary;
  }

  .auditlog__tag--error.tag {
    color: $color-white;
    background-color: $color-error;
  }

  .auditlog__block-detail {
    padding: 1rem;
  }

  .auditlog__block-detail-td {
    position: relative;
    background: $color-gray-light;
    border: 1px solid $color-border-gray;

    &::before {
      position: absolute;
      top: -5px;
      left: 20px;
      width: 0;
      height: 0;
      content: "";
      border-color: transparent transparent $color-border-gray transparent;
      border-style: solid;
      border-width: 0 5px 5px;
    }
  }

  .auditlog__block-detail-item {
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 2;
  }

  .auditlog__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-c-table {
    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }
}
