.code-c-applicant-exam-header {
  display: flex;

  .code-c-applicant-exam-header__image {
    margin-right: 0.5rem;
  }

  .code-c-applicant-exam-header__orgname {
    margin-bottom: 0.5rem;
    word-break: break-all;
  }

  .code-c-applicant-exam-header__title {
    @include fontSize("heading2", true);

    line-height: 1.1;
    word-break: break-all;
  }
}
