.code-applicant-challenge-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
  height: $header-height;
  padding: 0 1rem;
  white-space: nowrap;
  background: $header-background-color;
  border-bottom: 1px solid $color-gray-300;

  .code-applicant-challenge-header__left {
    overflow: hidden;
  }

  .code-applicant-challenge-header__title {
    overflow: hidden;
    text-align: center;
  }

  .code-applicant-challenge-header__right {
    display: grid;
    grid-template-columns: repeat(5, auto);
    column-gap: 0.75rem;
    align-items: center;
    justify-content: end;
    justify-self: end;
  }

  .code-applicant-challenge-header__timeleft {
    .last-saved-at {
      font-size: 0.625rem;
      text-align: center;
    }
  }
}
