.code-official-exam-panel {
  background: $color-white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  .code-official-exam-panel__contents-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    overflow: auto;

    .code-official-exam-panel__contents-wrapper__cancel {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem;

      .tag-language {
        font-size: 0.75rem;
        background: $color-primary-200;
        border-radius: 15px;
      }
    }

    .code-official-exam-panel__contents-wrapper__exam-info {
      margin-bottom: 1rem;

      .code-c-page-title {
        width: 100%;
        padding-bottom: 0.5rem;
        margin: 0.5rem 0;
        border-bottom: 1px solid $color-border-gray;
      }

      .date-info-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 0.5rem;

        .date-info-title {
          margin-right: 0.5rem;
          font-weight: bold;
        }

        .separator-line {
          margin-right: 0.5rem;
          margin-left: 0.5rem;
          font-weight: bold;
        }
      }

      .exam-info-container {
        .exam-info-container__section {
          margin-bottom: 1rem;
        }

        .exam-info-title {
          margin-bottom: 0.75rem;
          font-weight: 600;
        }

        .exam-info-item-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;

          .code-c-icon {
            display: contents;
            color: $color-success;

            .fa-check {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .code-official-exam-panel__contents-wrapper__buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1.5rem;

      .sample-test-button {
        margin-right: 0.75rem;
      }
    }

    .code-official-exam-panel__contents-wrapper__challenge-list {
      margin-bottom: 1rem;

      .challenge-list-table {
        margin-bottom: 1.875rem;
        overflow: visible;

        > table {
          border-collapse: collapse;
        }

        .challenge-list-table__head {
          white-space: nowrap;
        }
      }
    }
  }
}
