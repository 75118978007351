.code-exam-reviewers-header {
  display: flex;
  flex: 1;
  gap: 1.5rem;
  align-items: center;
  max-width: 50rem;

  &.code-exam-reviewers-header__spacing {
    align-items: stretch;
  }

  .code-exam-reviewers-header__search-box {
    width: 28rem;
    margin: unset;
  }
}
