.code-c-evaluation-point {
  .code-c-evaluation-point__table {
    border: 1px solid $color-border-gray;
  }

  .code-c-evaluation-point__row {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid $color-border-gray;
    }
  }

  .code-c-evaluation-point__category {
    display: flex;
    flex-basis: 15rem;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    background: $color-gray-light;
    border-right: 1px solid $color-border-gray;

    .code-c-evaluation-point__title {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      font-weight: bold;
    }

    .code-c-evaluation-point__count {
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .code-c-evaluation-point__testcases-summary {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    > div {
      display: flex;
      align-items: center;
    }

    > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  .code-c-evaluation-point__testcases {
    flex-grow: 1;
    padding: 1rem;

    .code-c-evaluation-point__testcase {
      display: flex;
      margin-bottom: 0.25rem;
    }

    .code-c-evaluation-point__testcase-secret {
      flex-basis: 1.5rem;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .code-c-evaluation-point__testcase-number {
      flex-basis: 1.5rem;
      flex-shrink: 0;
      margin-right: 0.5rem;
      font-weight: bold;
      text-align: right;
    }
  }

  .code-c-evaluation-point__secret-icon {
    width: 20px;
    height: 20px;
    margin-right: 0.25rem;
    background: $color-border-gray;
    border-radius: 100%;
  }

  .code-c-evaluation-point__warning {
    position: relative;
    padding: 0.5rem;
    font-weight: bold;
    color: $color-warning;
    text-decoration: underline;
    background-color: $color-warning-background;
    border: 1px solid $color-warning;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
