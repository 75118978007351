.code-c-guest-top {
  min-height: 100vh;
  background: $color-gray-light;

  .code-c-header {
    z-index: 5;
  }

  .code-c-guest-top__tab-header {
    margin: 0 1.5rem;
    margin-bottom: 1rem;
  }

  .code-c-guest-top__container {
    max-width: 1200px;
    min-height: calc(100vh - 3.25rem);
    padding: 4rem 0;
    margin: 0 auto;
    background: $color-white;
  }

  .code-c-guest-top__header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    padding: 0.5rem 1.5rem 2.5rem;

    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &:nth-child(2) {
        gap: 0.5rem;
      }
    }

    @include mq-max(994) {
      grid-template-columns: 1fr;
    }
  }

  .code-c-guest-top__report-id {
    padding-right: 1rem;
    margin-top: 2rem;
    font-size: 0.8125rem;
    font-style: italic;
    color: $color-gray;
    text-align: right;
  }

  .code-exam-detail-top__panel {
    padding: 1rem;
    border: 1px $color-neutral-200 solid;
    border-radius: 0.5rem;
  }

  .code-c-guest-top__row__split {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include mq-max(994) {
      flex-direction: row;
    }
  }
}
