.code-exam-copy-modal {
  .code-exam-copy-modal__modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .code-exam-copy-modal__select {
    color: $color-gray-500;
  }
}
