.code-system-health {
  .code-c-poor-health {
    margin-bottom: 1rem;
  }

  .code-system-health-item__status-icon {
    color: $color-success;
  }

  .code-system-health__item {
    .code-system-health__item-dropdown-icon {
      font-weight: bold;
      color: $color-gray-super-dark;
      cursor: pointer;
    }

    &.is-poor {
      font-weight: bold;
      background: $color-error-background;

      &:hover {
        background: $color-error-background !important;
      }

      .code-system-health-item__status-icon {
        color: $color-error;
      }
    }
  }
}
