.code-c-exam-challenge-print {
  max-width: 1240px;
  padding: 0 1rem;
  margin: 0 auto;

  .code-c-exam-challenge-print__print-button {
    text-align: right;
  }

  .code-c-exam-challenge-print__exam-title {
    @include fontSize("heading2");

    margin-bottom: 1.5rem;
  }

  .code-c-exam-challenge-print__challenge-set {
    margin-bottom: 1rem;

    .code-c-exam-challenge-print__challenge-set-title {
      @include fontSize("heading3");

      padding: 0.5rem;
      margin-bottom: 1rem;
      color: $color-white;
      background: $color-neutral-800;
      border-radius: 3px;
    }
  }

  .code-c-exam-challenge-print__challenge {
    margin-bottom: 2rem;

    .code-c-exam-challenge-print__challenge-header {
      border-top: 1px solid $color-border-gray;
      border-right: 1px solid $color-border-gray;
      border-left: 1px solid $color-border-gray;
    }

    .code-c-exam-challenge-print__challenge-title {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      background: $color-gray-super-light;
      border-bottom: 1px solid $color-border-gray;

      .code-c-exam-challenge-print__challenge-title__left {
        display: flex;
        padding-right: 1rem;
        line-height: 1.5rem;

        > *:not(:last-child) {
          margin-right: 0.5rem;
        }
      }

      .code-c-exam-challenge-print__challenge-title__right {
        flex-shrink: 0;
        padding-left: 1rem;
        text-align: right;

        > *:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }

    .code-c-exam-challenge-print__challenge-description {
      padding: 1rem;
      border-bottom: 1px solid $color-border-gray;
    }

    .code-c-exam-challenge-print__challenge-body {
      position: relative;
      padding: 1rem 2rem;
    }
  }

  .code-c-exam-challenge-print__question {
    display: flex;

    .code-c-question {
      flex-grow: 1;
      padding: 0;
      margin-top: 2px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .code-c-exam-challenge-print__question-index {
      @include fontSize("large", true);

      margin-right: 1rem;
    }
  }

  .code-c-exam-challenge-print__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
  }
}

@media print {
  body {
    background: $color-white;
  }

  .is-dummy-link,
  .code-c-exam-challenge-print__print-button {
    display: none;
  }

  .code-c-exam-challenge-print__challenge {
    page-break-after: always;
  }

  .code-c-exam-challenge-print__question {
    page-break-inside: avoid;
  }
}
