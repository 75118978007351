.code-reset-challenge {
  .code-reset-challenge__body {
    display: flex;
  }

  .code-reset-challenge__item:not(:last-child) {
    margin-right: 1rem;
  }

  .code-c-icon {
    color: $color-yellow;
  }
}
