.code-root {
  height: 100vh;
  background: $color-white;

  .code-c-header {
    position: fixed;
    z-index: 2;
    width: 100%;
  }

  .code-root-container {
    padding-top: $header-height;
  }

  .code-c-breadcrumbs {
    position: sticky;
    top: $header-height;
    z-index: 1;
  }
}

@include mq(sm) {
  .code-c-header {
    padding: 0 0.5rem;
  }
}
