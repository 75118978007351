.code-submission-timeline-item__default {
  @include text-sm;

  display: flex;
  gap: 0.25rem;

  &.items-center {
    align-items: center;
  }

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  .code-submission-timeline-item__default-label {
    color: $color-gray-600;
    white-space: nowrap;

    &.colon::after {
      color: $color-gray-600;
      content: ":";
    }
  }

  .code-submission-timeline-item__default-content {
    width: 100%;
    word-break: break-word;
  }

  .code-submission-timeline-item__link {
    @include text-sm;

    margin-top: 0.25rem;

    @media print {
      display: none;
    }
  }
}
