.code-exam-select {
  .radio-group {
    display: inline-flex;
    flex-direction: column;
    margin-top: 1rem;

    .radio-container {
      margin-top: 16px;
    }

    .code-c-radio {
      margin-left: 0 !important;

      &:first-child {
        margin-bottom: 1rem;
      }
    }

    .radio-group__item {
      display: inline-flex;
      flex-direction: column;
    }
  }

  .code-exam-select__enable-multiLanguage {
    margin-top: 0.5rem;
    margin-left: 1rem;

    .code-c-beta-tag {
      margin-left: 0.5rem;
    }

    .code-c-checkbox__label {
      align-items: center;
    }
  }
}
