.code-c-submission-detail-sub-header {
  display: grid;
  grid-template-columns:
    minmax(15rem, auto) minmax(4rem, 4rem) minmax(8rem, 11rem) minmax(
      auto,
      6rem
    )
    minmax(auto, 8rem);
  column-gap: 1rem;
  padding: 1rem;
  cursor: pointer;
  background: $color-white;
  border-top: 3px solid $color-neutral-600;
  border-bottom: 1px solid $color-border-gray;

  &:hover {
    background: $color-gray-super-light;
  }

  .code-c-submission-detail-sub-header__time-spent,
  .code-c-submission-detail-sub-header__preview {
    display: flex;
    gap: 0.5rem;
    justify-self: end;
    text-align: right;
  }

  .code-c-submission-detail-sub-header__score {
    position: relative;
    text-align: center;

    .code-c-submission-detail-sub-header__score-chart {
      position: absolute;
      top: -5px;
      left: 0;

      &.code-c-progress-circle {
        .code-c-progress-circle__label {
          font-weight: bold;
          color: $color-gray-900;
        }
      }
    }
  }

  .code-c-submission-detail-sub-header__time-extended-icon {
    width: 12px;
    height: 12px;
    background: transparent url("./assets/images/icon_clock-rotate.svg")
      no-repeat;
    background-size: contain;
  }

  .code-c-submission-detail-sub-header__correct {
    position: relative;

    .code-c-submission-detail-sub-header__total-test-case {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      white-space: nowrap;
    }

    .code-c-submission-detail-sub-header__correct-number {
      font-weight: bold;
    }

    .code-c-submission-detail-sub-header__public-test-case {
      margin-top: 0.2rem;
      font-size: smaller;
      color: $color-gray-800;
      white-space: nowrap;

      .public-test-case-label {
        margin-right: 0.5rem;
      }
    }

    .code-c-submission-detail-sub-header__ai-test-case {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      .code-c-icon {
        width: 1rem;
      }
    }

    .code-c-submission-detail-sub-header__score-invalid {
      position: absolute;
      top: -4px;
      display: flex;
      gap: 0.5rem;
      padding: 0.5rem;
      border: 1px solid $color-border-gray;
      border-radius: 3px;

      @include fontSize("small");

      .code-c-submission-detail-sub-header__score-invalid-icon {
        color: $color-yellow;
      }
    }
  }
}
