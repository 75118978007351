.code-c-tada-mini {
  $tada-mini-image-size: 110px;
  $tada-mini-icon-position: 10rem;
  $tada-mini-score-position: 10rem;

  position: fixed;
  right: 3rem;
  bottom: 2rem;
  z-index: $z-index-overlay;
  display: none;

  .code-c-tada-mini__icon {
    position: relative;
    right: 0.5rem;
    bottom: 0;
    width: $tada-mini-image-size;
    height: $tada-mini-image-size;
    transform: translateY($tada-mini-icon-position);
  }

  .code-c-tada-mini__body {
    position: absolute;
    right: 0;
    bottom: 0;
    width: $tada-mini-image-size;
    height: $tada-mini-image-size;
    background: transparent url("./assets/images/tada_body.svg") no-repeat;
  }

  .code-c-tada-mini__dust {
    position: absolute;
    right: 0;
    bottom: 0;
    width: $tada-mini-image-size;
    height: $tada-mini-image-size;
    background: transparent url("./assets/images/tada_dust.svg") no-repeat;

    &::before {
      position: absolute;
      top: -1em;
      right: 5em;
      font-family: $font-family-pop;
      font-size: 1.25em;
      color: $color-white;
      content: "High score!!";
    }
  }

  .code-c-tada-mini__score {
    position: relative;
    right: 0;
    bottom: 0;
    transform: translateX($tada-mini-score-position);
  }

  .code-c-tada-mini__score-top {
    position: relative;
    right: 3rem;
    bottom: 0;
    font-family: $font-family-pop;
    font-size: 3.5rem;
    line-height: 2rem;
    color: $color-white;
    letter-spacing: -0.1em;
  }

  .code-c-tada-mini__score-border {
    position: absolute;
    right: 1rem;
    bottom: 5rem;
    display: inline-block;
    width: 3rem;
    border-bottom: 1px solid $color-white;
    transform: rotate(-40deg);
  }

  .code-c-tada-mini__score-bottom {
    position: relative;
    right: 1rem;
    bottom: 0;
    font-family: $font-family-pop;
    color: $color-white;
    text-align: right;
  }

  &.is-animate {
    display: inline-flex;

    .code-c-tada-mini__icon {
      animation-name: tadaIcon;
      animation-duration: 2s;
      animation-timing-function: cubic-bezier(0.31, 0.23, 0, 1);
      animation-fill-mode: forwards;
    }

    .code-c-tada-mini__dust {
      animation-name: tadaDust;
      animation-duration: 2s;
      animation-timing-function: cubic-bezier(0, 0.27, 0, 1.01);
      animation-fill-mode: forwards;
    }

    .code-c-tada-mini__score {
      animation-name: tadaScore;
      animation-duration: 2s;
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      animation-fill-mode: forwards;
    }

    &.is-black {
      .code-c-tada-mini__score-border {
        border-color: $color-gray-super-dark;
      }

      .code-c-tada-mini__dust,
      .code-c-tada-mini__score-top,
      .code-c-tada-mini__score-bottom {
        color: $color-gray-super-dark;
      }
    }
  }

  @keyframes tadaIcon {
    0% {
      transform: translateY($tada-mini-icon-position);
    }

    25%,
    75% {
      transform: translateY(0);
    }

    100% {
      transform: translateY($tada-mini-icon-position);
    }
  }

  @keyframes tadaDust {
    0%,
    25% {
      opacity: 100;
      transform: scale(0);
    }

    50% {
      opacity: 100;
      transform: scale(1);
    }

    75% {
      opacity: 30;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  @keyframes tadaScore {
    0% {
      transform: translateX($tada-mini-score-position);
    }

    10%,
    85% {
      transform: translateX(0);
    }

    100% {
      transform: translateX($tada-mini-score-position);
    }
  }
}
