.code-registration {
  &__button {
    margin-top: 4rem;
  }

  .code-c-form {
    max-width: unset;

    .form-email {
      word-break: break-all;
    }

    .code-c-form-group {
      width: 100%;
    }

    .code-c-left-block {
      min-width: 18rem;
      margin: 0 1rem 1.875rem 0;
    }

    .code-c-right-block {
      padding-left: 0;
    }

    .code-c-password-requirements {
      margin: 0 0 1.875rem;
    }

    form {
      flex: 1;
    }
  }
}
