.code-c-review {
  padding: 1rem;
  border: 1px solid $color-border-gray;
  border-radius: 3px;

  &.is-strongly-approved {
    .code-c-review__score-tag {
      color: $color-white;
      background: $color-green-700;
    }
  }

  &.is-not-reviewed {
    background: $color-neutral-100;
    border-color: transparent;
  }

  &.is-stale {
    .code-c-review__avatar,
    .code-c-review__reviewer-name-tag,
    .code-c-review__score-tag,
    .code-c-review__not-reviewed-tag,
    .code-c-review__reviewed-at,
    .code-c-review__feedback {
      opacity: 0.5;
    }
  }

  &.is-approved {
    .code-c-review__score-tag {
      color: $color-white;
      background: $color-green-500;
    }
  }

  &.is-rejected {
    .code-c-review__score-tag {
      color: $color-white;
      background: $color-red-500;
    }
  }

  &.is-strongly-rejected {
    .code-c-review__score-tag {
      color: $color-white;
      background: $color-red-700;
    }
  }

  .code-c-review__banner {
    padding: 0.5rem;
    margin-bottom: 1rem;

    .code-c-review__banner__text {
      color: $color-neutral-700;
    }

    a {
      text-decoration: underline;
    }

    .code-c-review__banner__a-disabled {
      a {
        font-weight: 400;
        color: $color-neutral-500;
        text-decoration: none;
      }

      a:hover {
        cursor: not-allowed;
      }
    }
  }

  .code-c-review__user-status {
    margin-bottom: 0.25rem;
  }

  .code-c-review__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .code-c-review__top {
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
    }

    .code-c-tooltip {
      z-index: unset;
      align-self: flex-start;
    }

    .code-c-review__profile {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    .code-c-review-avatar {
      flex-basis: 2rem;
      flex-shrink: 0;
    }

    .code-c-review__reviewer {
      display: flex;
      flex-direction: column;

      .code-c-review__reviewer-name-tag {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .code-c-review__reviewer-name-container {
          min-width: 0;
          word-break: break-word;

          @include text-lg;

          .code-c-review__reviewer-name__name {
            font-weight: 700;
            color: $color-gray-900;
          }

          .code-c-review__reviewer-name__you {
            color: $color-gray-500;
          }
        }
      }
    }

    .code-c-review__status {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center;
      align-self: flex-start;
      justify-content: flex-end;

      .code-c-review__score-tag {
        gap: 0.25rem;
        padding: 0.25rem 0.5rem;
      }
    }

    .code-c-review__reviewed-at {
      color: $color-gray-500;

      @include text-sm;
    }

    .code-c-review__feedback {
      margin-left: 2.5rem;
      white-space: pre-line;
    }

    .code-c-review__buttons {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;

      .code-c-review__edit-icon {
        width: 1.5rem;
        height: 1.5rem;
        color: $color-neutral-600;
        cursor: pointer;
        border-radius: 3px;
      }

      .code-c-review__ping-icon {
        width: 1.5rem;
        height: 1.5rem;
        color: $color-white;
        cursor: pointer;
        background: $color-neutral-500;
        border-radius: 3px;

        &.is-disabled {
          cursor: not-allowed;
          background: $color-neutral-200;
        }
      }
    }
  }

  .code-c-review__stale-tag {
    color: $color-neutral-500;
    white-space: nowrap;

    @include fontSize("xsmall");
  }

  .code-c-review__not-reviewed-tag {
    color: $color-neutral-500;
    white-space: nowrap;

    @include fontSize("xsmall");
  }
}
