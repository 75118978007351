.code-exam-list-table-row {
  transition: background-color 0.2s;

  &:hover {
    background: transparentize($color-gray-100, 0.7);
  }

  tr {
    &:hover {
      background: transparent !important;
    }

    &:nth-child(1) {
      td.code-c-column {
        padding-bottom: 0.75rem;
        border-bottom: none;
      }
    }

    &:nth-child(2) {
      td.code-c-column {
        padding-top: 0;
      }
    }
  }

  .code-exam-list-table-row__tags {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-start;
  }
}
