.code-switch-language {
  .code-switch-language__body {
    display: flex;
    margin-bottom: 1rem;
  }

  .code-switch-language__item {
    margin-right: 1rem;

    .code-c-icon {
      color: $color-yellow;
    }
  }

  .code-supported-programming-languages-link {
    margin-bottom: 1rem;
  }
}
