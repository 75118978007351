.code-c-sample {
  position: relative;
  overflow: auto;

  > *:not(:last-child) {
    opacity: 0.3;
  }

  .code-c-sample__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .code-c-sample__overlay__panel {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      width: 24rem;
      padding: 1em 1.4em;
      margin: auto;
      margin-top: 8rem;
      font-size: 0.9rem;
      background-color: $color-white;
      border: 1px solid $color-border-gray;

      a {
        text-decoration: underline;
      }
    }
  }
}
