.code-c-poor-health {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0;
  color: $color-error;
  background-color: $color-error-background;
  border: 1px solid $color-error;

  .code-c-poor-health__left {
    flex-shrink: 1;
    padding-left: 3rem;
    margin-right: 1rem;
  }

  .code-c-poor-health__title {
    font-weight: bold;
    text-decoration: underline;
  }

  .code-c-poor-health__poor-icon {
    position: absolute;
    top: 0.75rem;
    left: 1rem;
    width: 32px;
    height: 32px;
    background: transparent url("./assets/images/stop-mark.svg") no-repeat;
  }
}
