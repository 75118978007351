body {
  height: 100%;
  min-height: 100vh;
  font-size: 14px;
  color: $color-text-vivid;
  letter-spacing: 0.02em;
  background: $app-background-color;

  &.modal-open {
    position: fixed;
    width: 100%;
    overflow: hidden;
  }

  .is-cursor-pointer {
    cursor: pointer;
  }
}

@include mq("sm") {
  body {
    height: auto;
    min-height: 100%;
  }
}

a {
  transition: all 0.5s;

  &.is-bold {
    margin-bottom: 0.5em;
    font-family: $font-family-heading;
    font-size: 1em;
    color: $color-link;
  }

  &:hover {
    text-decoration: underline;
  }
}

dt {
  margin-bottom: 0.5em;
  font-weight: bold;
}

dd {
  margin-bottom: 1em;
}

// Hide header and sidebars from printing.
@media print {
  .navbar,
  .code-c-header,
  .code-c-breadcrumbs,
  .code-c-menu,
  .code-c-sub-menu-container-left {
    display: none !important;
  }

  body {
    -webkit-print-color-adjust: exact;

    .code-orgs-container {
      padding: 0 !important;
      margin: 0 !important;

      .code-orgs-container__body {
        padding-top: 0 !important;
        padding-left: 0 !important;
      }
    }

    .code-c-sub-menu-container {
      height: auto !important;
      overflow: auto !important;
    }

    .is-nomedia {
      display: none !important;
    }

    th.code-c-header-column.is-5 {
      width: auto;
    }

    .code-exam-detail {
      .code-c-sub-menu-container-right,
      .code-exam-detail__body {
        width: 100%;
      }
    }

    .code-exam-report-challenge-detail {
      break-inside: avoid;

      .challenge-graph {
        min-width: 300px !important;

        canvas {
          width: 100% !important;
        }
      }
    }
  }
}

.is-clickable {
  cursor: pointer !important;
}

.has-text-yellow-500 {
  color: $color-yellow-500;
}

.has-text-yellow-700 {
  color: $color-yellow-700;
}

.has-text-secondary-700 {
  color: $color-secondary-700;
}

.has-text-neutral-200 {
  color: $color-neutral-200;
}

.has-text-neutral-300 {
  color: $color-neutral-300;
}

.has-text-neutral-500 {
  color: $color-neutral-500;
}

.has-text-gray-500 {
  color: $color-gray-500;
}

.has-text-gray-600 {
  color: $color-gray-600;
}

.has-text-success-500 {
  color: $color-success-500;
}

.has-text-error-500 {
  color: $color-error-500;
}

.has-text-error-700 {
  color: $color-error-700;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-bold {
  font-weight: bold !important;
}

.mt-4 {
  margin-top: 1rem;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  cursor: ew-resize;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
}

.capitalize {
  text-transform: capitalize;
}

.opacity-80 {
  opacity: 0.8;
}

.overflow-hidden {
  overflow: hidden;
}

.disable-overscroll-y {
  overscroll-behavior-y: none;
}
