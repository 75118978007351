.code-c-applicant-challenge {
  position: relative;
  background: $color-white;
  border: 1px solid $color-border-gray;

  .code-c-applicant-challenge__container {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.5rem;
  }

  .code-c-applicant-challenge__left {
    flex-grow: 1;
    margin-right: 0.5rem;
  }

  .code-c-applicant-challenge__right {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;
    text-align: right;

    .code-c-count-down-timer {
      white-space: nowrap;
    }
  }

  .code-c-applicant-challenge__title {
    @include fontSize("large", true);

    margin-bottom: 1.25rem;
    word-break: break-all;
    white-space: pre-wrap;
  }

  .code-c-applicant-challenge__subtitle {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .code-c-applicant-challenge__extend-info {
    .code-c-cli-support-badge {
      margin-bottom: 0.5rem;
    }

    margin-top: 0.5rem;
  }

  .code-c-applicant-challenge__status {
    margin-bottom: 0.5rem;
  }

  &.is-running {
    border-color: $color-primary;
    box-shadow: inset 0 0 0 2px $color-primary;
  }

  &.is-finished {
    background: $color-gray-super-light;

    .code-c-applicant-challenge__left,
    .code-c-applicant-challenge__status {
      opacity: 0.6;
    }
  }
}
