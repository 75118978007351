.text-heading1 {
  @include text-heading1;
}

.text-heading2 {
  @include text-heading2;
}

.text-heading3 {
  @include text-heading3;
}

.text-title {
  @include text-title;
}

.text-xl {
  @include text-xl;
}

.text-lg {
  @include text-lg;
}

.text-md {
  @include text-md;
}

.text-sm {
  @include text-sm;
}

.text-xs {
  @include text-xs;
}

.text-xxs {
  @include text-xxs;
}

.is-column {
  flex-direction: column;
}

.align-right {
  text-align: right !important;

  .code-c-sortable-text & {
    text-align: right !important;
  }
}

.align-center {
  text-align: center !important;

  .code-c-sortable-text & {
    text-align: center !important;
  }
}

.is-keep-all {
  word-break: keep-all !important;
}

.is-break-all {
  word-break: break-all !important;
}

.is-break-word {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.is-wrap {
  word-break: break-all;
  white-space: pre-line;
}

.is-nowrap {
  white-space: nowrap;
}

.is-text-ellipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-no-box-shadow {
  box-shadow: none !important;
}

.link-disabled {
  color: $color-gray !important;
  pointer-events: none;
  cursor: not-allowed;
}

.is-bold {
  font-weight: bold;
}

.has-error {
  color: $color-red !important;
}

.has-warning {
  color: $color-yellow !important;
}

.clamp-1 {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.clamp-2 {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.reader-preview-mode {
  position: fixed;
  right: 1rem;
  bottom: 3.5rem;
  z-index: $z-index-preview-mode;
  padding: 0 3rem;
  color: $color-white;
  pointer-events: none;
  background: transparentize($color-red, 0.75);
  border-radius: 3px;
}

@include mq(lg) {
  @for $i from 1 through 10 {
    .is-mt#{$i * 1}-lg {
      margin-top: #{$i * 1}rem;
    }
    .is-mb#{$i * 1}-lg {
      margin-bottom: #{$i * 1}rem;
    }
    .is-pt#{$i * 1}-lg {
      padding-top: #{$i * 1}rem;
    }
    .is-pb#{$i * 1}-lg {
      padding-bottom: #{$i * 1}rem;
    }
  }
}

@include mq(sm) {
  @for $i from 1 through 10 {
    .is-mt#{$i * 1}-sm {
      margin-top: #{$i * 1}rem;
    }
    .is-mb#{$i * 1}-sm {
      margin-bottom: #{$i * 1}rem;
    }
    .is-pt#{$i * 1}-sm {
      padding-top: #{$i * 1}rem;
    }
    .is-pb#{$i * 1}-sm {
      padding-bottom: #{$i * 1}rem;
    }
  }
}

@include spacing(".m-", "margin");
@include spacing(".mt-", "margin-top");
@include spacing(".mr-", "margin-right");
@include spacing(".mb-", "margin-bottom");
@include spacing(".ml-", "margin-left");
