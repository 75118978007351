.challenge-result-preview__code-explanation-button {
  position: fixed;
  top: 3rem;
  right: 0;
  z-index: 6;
  height: 40px;
  margin: 0 1rem;
  line-height: 40px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;

    a {
      color: $color-gray !important;
      cursor: not-allowed;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.tooltip {
    &::before {
      position: fixed;
      inset: 1.5rem 0.5rem auto auto;
      z-index: 9999999;
      display: none;
      max-width: calc(100vw - 2rem);
      padding: 0.5em 1em;
      font-weight: initial;
      color: $color-white;
      text-align: initial;
      content: attr(data-text);
      background:  $color-black;
      border-radius: 2px;

      @include fontSize("xsmall");
    }

    /* Arrow */
    &::after {
      position: absolute;
      top: 0;
      right: 50%;
      display: none;
      width: 8px;
      height: 8px;
      content: "";
      background: $color-black;
      transform: rotate(45deg);
    }

    &:hover::before, &:hover::after {
      display: block;
    }
  }
}

.challenge-result-preview__code-explanation-drawer {
  position: fixed;
  top: calc(3rem + 40px);
  right: 0;
  bottom: 3.125rem;
  z-index: 20;
  display: none;
  flex-direction: column;
  width: 100%;
  max-width: 22rem;
  height: calc(100vh - 3rem - 40px);
  padding: 1rem;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  .challenge-result-preview__code-explanation-drawer__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    line-height: 1.5;
  }

  .markdown-body {
    flex-grow: 1;
    min-height: 3rem;
    padding: 1rem;
    overflow-y: auto;
    background: $color-white;
    border: 1px solid $color-border-gray;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}
