.code-c-card {
  position: relative;
  display: flex;
  padding: 0.75rem 1rem;
  background: $color-neutral-100;
  border: 1px solid $color-neutral-200;
  border-radius: 0.25rem;
}

.code-c-card--clickable {
  cursor: pointer;
}

.code-c-card__content {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
}

.code-c-card__content--column {
  flex-direction: column;
}
