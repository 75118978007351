.code-submission-review-popup {
  .score-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    justify-content: space-between;

    .code-c-tooltip-wrapper {
      width: 8rem;
      min-width: 4rem;
      min-height: 7.5rem;
    }

    .score-button {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      row-gap: 0.5rem;
      align-items: center;
      justify-content: center;
      width: 5.5rem;
      min-width: 5.5rem;
      min-height: 7.5rem;
      padding: 0.8rem 0.5rem;
      cursor: pointer;
      background: $color-white;
      border: 1px solid $color-border-gray;
      border-radius: 4px;
      transition:
        background-color 0.25s,
        border-color 0.25s;

      &.is-checked,
      &:hover {
        padding: 0.8rem 0.4375rem;
        margin-top: -1px;
        margin-bottom: -1px;
      }

      .score-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      .score-text {
        flex-grow: 1;
        max-height: 50%;
        font-weight: 600;
        text-align: center;
      }

      &.is-strongly-approved {
        color: $color-green-700;

        &.is-checked,
        &:hover {
          background: $color-green-300;
          border: 2px solid $color-green-700;
        }
      }

      &.is-approved {
        color: $color-green-500;

        &.is-checked,
        &:hover {
          background: $color-green-100;
          border: 2px solid $color-green-500;
        }
      }

      &.is-rejected {
        color: $color-red-500;

        &.is-checked,
        &:hover {
          background: $color-red-100;
          border: 2px solid $color-red-500;
        }
      }

      &.is-strongly-rejected {
        color: $color-red-700;

        &.is-checked,
        &:hover {
          background: $color-red-300;
          border: 2px solid $color-red-700;
        }
      }

      &.is-disabled {
        opacity: 0.6;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  textarea {
    height: unset !important;
    min-height: 30vh !important;
  }
}
