.code-c-tag-picker {
  $input-height: 2.25em;

  position: relative;

  &__input {
    margin-bottom: 0.5em;

    input {
      font-size: 1rem;
    }
  }

  .code-c-tag-picker__suggest-item {
    display: inline-flex;
    padding: 0.25rem 0.75em;
    margin: 0.2rem;
    overflow-x: auto;
    font-size: 0.75rem;
    white-space: nowrap;
    cursor: pointer;
    background: $color-white;
    border: 1px solid $color-gray-light;
    border-radius: 3px;
    transition: background-color 0.3s;

    &:hover {
      background: $color-gray-light;
    }
  }

  .code-c-tag-picker__suggest-item-selected {
    background: $color-gray-light;
  }
}
