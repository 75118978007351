tr.code-c-row {
  &:hover {
    background-color: inherit;
  }

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
