.code-c-tabs {
  overflow-x: auto;
  font-size: 14px;

  .code-c-tabs__item {
    &:first-child {
      margin-left: 2rem;

      &::-moz-focus-inner {
        border: 0;
      }
    }

    &.disabled {
      a {
        color: $color-gray;
        cursor: not-allowed;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }

  a {
    color: $color-gray-dark;
    background-color: transparentize($color-gray-super-light, 0.5);
    border-bottom-color: $color-border-gray !important;

    &:hover {
      text-decoration: none;
    }

    // Note: You can add a .no-underline class to child elements of the anchor tag to prevent the underline from appearing on hover.
    &:hover > :not(.no-underline) {
      text-decoration: underline;
    }
  }
}
