
.code-editor-help {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  &.sticky {
    position: sticky;
    top: calc(#{$header-height} + 1rem);
  }

  .code-editor-help-section-title {
    margin-bottom: 1rem;
    font-size: 1.125rem;
  }

  .code-editor-help-section-link {
    margin-bottom: 0.75rem;
  }

  .code-editor-help-section {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .code-editor-help-cli {
    margin-left: 1rem;
    list-style: circle;

    .code-c-cli-support-badge {
      margin-right: 0.25rem;
    }
  }

  .code-c-button {
    width: 100%;
  }

  ul {
    margin-left: 1rem;
    list-style: inherit;
  }
}
