.code-challenge-result-preview-header {
  z-index: $z-index-header;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  height: calc(#{$header-height} - 1px);
  padding: 0 1.5rem;
  color: $header-color;
  background: $header-background-color;
  border-bottom: 1px solid $color-gray-300;

  .code-challenge-result-preview-header__left {
    display: flex;
    flex-shrink: 2;
    gap: 1rem;
    align-items: center;
    min-width: 0;
    white-space: nowrap;
  }

  .code-challenge-result-preview-header__right {
    display: flex;
    flex-shrink: 1;
    gap: 1rem;
    align-items: center;
    min-width: 0;
  }

  .code-challenge-result-preview-header__title {
    @include fontSize("large", true);
  }

  .code-challenge-result-preview-header__item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    white-space: nowrap;
  }

  .code-challenge-result-preview-header__score-badge {
    display: flex;
    align-items: baseline;
    padding: 0.25rem 0.5rem;
    background: $color-gray-light;
    border-radius: 8px;
  }

  .code-challenge-result-preview-header__score-bold {
    font-weight: bold;
  }
}
