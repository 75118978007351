.code-c-applicant-challenge-title-column {
  display: flex;
  flex-direction: column;

  .code-c-applicant-challenge-title-column__title {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    line-height: 1.5;
    text-overflow: ellipsis;
  }

  .code-c-applicant-challenge-title-column__row {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
  }

  .code-c-applicant-challenge-title-column__info-bottom {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: small;
    line-height: 1
  }

  .code-c-applicant-challenge-title-column__timer {
    @include text-xs;

    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .code-c-applicant-challenge-title-column__timer-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .code-c-applicant-challenge-title-column__question {
    @include text-xs;
  }

  .code-c-applicant-challenge-title-column__info-title {
    max-width: 10rem;
  }

  .code-c-applicant-challenge-title-column__status {
    flex-basis: 5.75rem;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1;
    text-align: right;
  }

  .code-c-applicant-challenge-title-column__status-badge {
    padding: 1px 5px;
    font-size: small;
    color: $color-white;
    background: $color-green-500;
    border-radius: 3px;
  }
}
