.code-ip-address-badge-hover-box {
  z-index: $z-index-overlay + 1;
  max-width: 20rem;
  padding: 0.75rem;
  background: $color-white;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  .code-ip-address-badge-hover-box__container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
  }
}