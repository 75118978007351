.code-c-challenge-title-column {
  width: 100%;

  .code-c-challenge-title-column__title {
    display: block;
    margin-bottom: 0.5em;

    .code-c-challenge-title-column__not-available {
      display: block;
      justify-content: unset;
      width: fit-content;
      max-width: 100%;
      margin-bottom: 0.5rem;
      overflow: hidden;
      color: $color-red-500;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: $color-white;
      border: 1px solid $color-red-500;
    }

    .code-c-challenge-title-column__tags {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      cursor: default;

      .code-c-challenge-title-column__draft {
        color: $color-white;
        background: $color-gray-dark;
      }
    }

    .code-c-challenge-title-column__challenge-title__body {
      display: inline-flex;
      color: $color-gray-700;
      text-decoration: none;
      cursor: text;

      .code-c-challenge-title-column__challenge-title__title {
        font-weight: bold;
        text-decoration: none;
        word-break: break-word;
        overflow-wrap: anywhere;
      }

      .code-c-challenge-title-column__detail-button {
        width: 21px;
        height: 21px;
      }

      &.clickable {
        color: $color-link;
        cursor: pointer;

        &:hover {
          text-decoration: none;

          .code-c-challenge-title-column__challenge-title__title {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .code-c-challenge-title-column__description {
    display: inline-block;
    margin-bottom: 1em;
    word-break: break-word;
    overflow-wrap: anywhere;
  }
}
