.code-multiple-manage-modal {
  .code-multiple-manage-modal__count {
    margin-left: 0.5rem;
  }

  .code-multiple-manage-modal__text {
    margin-bottom: 1rem;
  }

  .title-step {
    margin-right: 1rem;
    font-weight: bold;
  }

  .code-c-form-group {
    max-width: none;
  }

  .radio-group {
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    .code-c-radio {
      margin-left: 0 !important;
    }
  }

  .form-group-container {
    display: none;

    &.is-show {
      display: block;
    }
  }

  .code-multiple-manage-modal__table-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;
    white-space: nowrap;

    .code-multiple-manage-modal__search {
      > input {
        min-width: 20rem;
      }
    }
  }

  .code-multiple-manage-modal__pagination {
    position: sticky;
    bottom: -3.5rem;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-c-table {
    margin: unset !important;

    .title-column {
      display: flex;
      gap: 0.5rem;
    }

    .code-multiple-manage-modal__version-no {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .code-c-update-available-tag {
        width: fit-content;
      }
    }

    .challenge-column {
      background-color: $color-neutral-200;
    }
  }

  .target-challenge-container {
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    padding: 0.5rem 1rem;
    background-color: $color-neutral-200;

    .code-c-version-code-tag {
      background: $color-white !important;
    }
  }

  .code-multiple-manage-modal__form-state-box {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    margin-bottom: 2rem;
    border: 1px solid $color-border-gray;

    .divider {
      height: auto;
      border-left: 1px solid $color-border-gray;
    }
  }

  .code-c-alert {
    width: fit-content;
    margin-bottom: 1rem;
  }

  .code-multiple-manage-modal__submission-list-container {
    .code-multiple-manage-modal__submissions-bar {
      width: 100%;
      padding: 0.5rem;
      text-align: unset;
      cursor: pointer;
      background: $color-neutral-100;
      border: 1px solid $color-border-gray;
    }

    .code-multiple-manage-modal__submission-table-wrapper {
      padding: 1rem;
      border: 1px solid $color-border-gray;
      border-top: 0;

      .code-multiple-manage-modal__search {
        margin-bottom: 1rem;
      }
    }
  }

  .code-multiple-manage-modal__datepicker-wrapper {
    width: fit-content;
    padding: 1rem;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
  }

  .checkbox-wrapper {
    position: relative;
    width: fit-content;
    margin: auto;

    .code-c-tooltip-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
    }
  }
}
