.code-c-search-select {
  position: relative;

  &.code-c-search-select__auto-width {
    position: unset;
  }

  .code-c-search-select__input-wrapper {
    display: flex;
    gap: 0.25rem;

    .code-c-input {
      width: 100%;
    }

    .code-c-search-select__input {
      width: 100%;
      border-style: none;

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }

    .code-c-search-select__clear-hidden {
      visibility: hidden;
    }
  }

  .code-c-search-select__dropdown {
    position: absolute;
    z-index: $z-index-menu;
    max-height: 50vh;
    margin-top: 0.25rem;
    overflow: auto;
    background: $color-white;
    border: 1px solid $color-border-gray;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  }

  .code-c-search-select__option {
    padding: 0 0.5rem;
    word-break: break-all;
    user-select: none;
    transition: all 0.2s;

    &.code-c-search-select__option-selected {
      font-weight: bold;
      color: $color-primary-900;
    }

    &:hover {
      cursor: pointer;
      background: $color-gray-100;
    }
  }
}
