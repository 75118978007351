.code-c-stacked-bar-chart {
  svg {
    font-family: $font-family-base;
  }

  .c3-grid {
    .c3-ygrids {
      .c3-ygrid {
        opacity: 0.5;
        stroke: $color-border-gray;
        stroke-dasharray: none;
      }
    }
  }

  .c3-axis-x {
    path.domain {
      stroke: $color-border-gray;
      stroke-width: 2px;
    }
  }

  .c3-axis-y {
    path.domain {
      opacity: 0;
    }
  }

  .c3-brush {
    .background {
      visibility: visible !important;
      fill: $color-neutral-500;
      fill-opacity: 0.1;
      stroke: $color-neutral-200;
      stroke-width: 2px;
    }

    .extent {
      fill: $color-primary-500;
      fill-opacity: 0.3;
    }

    .resize {
      > rect {
        visibility: visible !important;
        fill: $color-primary-500;
      }
    }
  }

  .tick {
    line {
      opacity: 0;
    }

    text {
      font-size: 0.75rem;
      fill: $color-gray-700;
    }
  }

  .code-c-stacked-bar-chart__region-line-x {
    line {
      stroke: $color-gray-400;
    }

    text {
      fill: $color-gray-600;
    }
  }

  .code-c-stacked-bar-chart__legend {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    justify-content: flex-end;

    // only allow blur if hovered label does not have a child with "toggled" class property
    &:has(
        .code-c-stacked-bar-chart__legend__label:hover:not(
            .code-c-stacked-bar-chart__legend__toggled
          )
      ) {
      &:hover {
        .code-c-stacked-bar-chart__legend__label:not(:hover) {
          opacity: 0.5;
        }
      }
    }

    .code-c-stacked-bar-chart__legend__toggled {
      opacity: 0.5;
    }

    .code-c-stacked-bar-chart__legend__label {
      display: flex;
      gap: 0.35rem;

      @include text-md;

      user-select: none;
      background-color: unset;

      &:hover {
        cursor: pointer;
      }

      .code-c-stacked-bar-chart__legend__color {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-top: 3px;
      }
    }
  }

  &.code-c-stacked-bar-chart__analysis,
  &.code-c-stacked-bar-chart__submission-score-summary {
    .c3-axis-y {
      &-label {
        font-size: 0.75rem;
        fill: $color-gray-600;
      }
    }

    .c3-axis-x {
      &-label {
        font-size: 0.75rem;
        fill: $color-gray-600;
      }
    }

    .tick {
      text {
        font-size: 0.75rem;
        fill: $color-gray-600;
      }
    }

    .c3-legend-item {
      color: $color-gray-600;
      cursor: default !important;
    }

    & .c3-tooltip-container {
      border: 1px solid $color-neutral-200;
      border-radius: 0.125rem;
      box-shadow: 0 2px 16px 0 rgba(20, 20, 19, 0.16);

      .c3-tooltip {
        box-shadow: none;
        opacity: 1;
      }

      tbody {
        tr {
          border: none;
        }

        tr:first-child th {
          padding: 0.5rem 0.75rem;
          color: $color-gray-900;
          background-color: $color-neutral-100;
        }

        tr:nth-child(n + 2) {
          td {
            @include text-md;

            padding: 0.25rem 0.75rem;
            color: $color-gray-800;
            background-color: $color-white;
            border: none;
          }

          td:first-child {
            padding-right: 0.5rem;
          }

          td:nth-child(2) {
            padding-left: 0.5rem;
          }
        }

        tr:nth-child(2) td {
          padding-top: 8px;
        }

        tr:last-child td {
          padding-bottom: 8px;
        }
      }
    }
  }

  &.code-c-stacked-bar-chart__submission-score-summary {
    .tick {
      text {
        fill: $color-gray-400;
      }
    }

    .c3-axis-y-label,
    .c3-axis-x-label {
      fill: $color-gray-400;
    }
  }

  &.code-c-stacked-bar-chart__tooltip_no-color-icon {
    & .c3-tooltip-container {
      td.name > span {
        display: none;
      }
    }
  }
}
