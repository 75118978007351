.code-official-exam-list {
  padding: 1.5rem;

  .panel-overlay.code-c-overlay {
    background: transparentize($color-gray-super-dark, 0.6);
  }

  .search-results {
    margin-left: 0.5rem;
    font-size: 0.8125rem;
  }

  .code-official-exam-list__header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, max-content));
    grid-gap: 0.5rem;
    width: 100%;
    margin-bottom: 3rem;

    @include mq(xl) {
      padding-right: unset;
    }

    .code-official-exam-list__header-item {
      .select {
        width: 100%;
      }

      input {
        height: 2em;
      }
    }
  }

  .code-official-exam-list__card-container {
    display: flex;
    flex-wrap: wrap;

    .code-c-official-exam-card {
      margin: 0 2rem 2rem 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    }

    .card-wrapper {
      cursor: pointer;
    }
  }

  .code-official-exam-list__no-result {
    width: 100%;
    margin: 10rem 0;
    text-align: center;
  }

  .code-official-exam-list__pagination {
    width: 100%;
    margin: 2rem 0;
    text-align: center;
  }

  .code-official-exam-panel {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: 22;
    width: 100%;
    max-width: 58rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .code-official-exam-deliver {
    z-index: 25;
  }
}
