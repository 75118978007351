.code-challenge-result-preview {
  .code-challenge-result-preview__body {
    position: fixed;
    top: calc(#{$header-height} - 1px);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - (#{$header-height} - 1px));
    vertical-align: top;
    background-color: rgba(15, 71, 141, 0.15);

    .code-challenge-result-preview__iframe-wrapper {
      position: relative;
      flex-grow: 1;
      min-width: 760px;

      .code-challenge-result-preview__iframe {
        width: 100%;
        height: 100%;
      }

      .reader-preview-mode {
        position: absolute;
        padding: 0.75rem 3rem;
      }
    }

    .challenge-result-preview__code-explanation-drawer,
    .gutter {
      display: none;
    }

    &.is-right-drawer-open {
      .challenge-result-preview__code-explanation-drawer {
        display: flex;
      }

      .gutter {
        display: unset;
      }
    }

    .challenge-result-preview__code-explanation-drawer {
      position: relative;
      top: $code-explanation-button-height;
      right: unset;
      bottom: unset;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
      min-width: 200px;
      max-width: unset;
      height: calc(
        100vh - #{$header-height} - #{$code-explanation-button-height}
      );
      padding: 1rem;
      background-color: $color-white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

      .challenge-result-preview__code-explanation-drawer__header {
        display: flex;
        justify-content: space-between;
        line-height: 1.5;
      }

      .markdown-body {
        flex-grow: 1;
        min-height: 3rem;
        padding: 0.5rem 0.75rem;
        overflow-y: auto;
        background: $color-white;
        border: 1px solid $color-border-gray;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      .challenge-result-preview__code-explanation-drawer__section {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border: 1px solid $color-gray-200;
        border-radius: 0.5rem;

        &.code-explanation-section {
          flex: 2.5;
          overflow: hidden;
        }

        &.local-code-files-section {
          display: flex;
          flex: 1;
          flex-direction: column;
          min-height: 220px;
          overflow: hidden;

          .code-c-local-code-files {
            padding: 0 1rem;
            margin: 0 -1rem;
            overflow-y: auto;
          }
        }
      }
    }
  }
}
