.code-c-toggle-pin {
  cursor: pointer;

  .code-c-icon {
    color: $color-gray;
    transform: rotate(45deg);
  }

  &[aria-checked="true"] {
    .code-c-icon {
      color: $color-yellow;
    }
  }
}
