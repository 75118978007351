.code-c-tag {
  &:not(body) {
    height: inherit;
    min-height: 2em;
  }

  &.white {
    background: $color-white;
    border: 1px solid $color-border-gray;
  }

  &.has-delete {
    padding-right: 0;
  }

  &__delete-icon {
    cursor: pointer;
  }

  &.code-c-tag__cursor-pointer {
    cursor: pointer;
  }

  .code-c-tag__label {
    flex: 1;
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &.wrap {
      text-wrap: wrap;
    }
    
  }

  &.color-neutral {
    color: $color-neutral-800;
    background: $color-neutral-200;
  }

  &.color-neutral-light {
    color: $color-neutral-900;
    background: $color-neutral-100;
  }

  &.color-green {
    color: $color-green-700;
    background: $color-green-300;

    &.dark-font {
      color: $color-green-900;
    }
  }

  &.color-yellow {
    color: $color-yellow-700;
    background: $color-yellow-100;
  }

  &.color-yellow-light {
    color: $color-yellow-500;
    background: $color-yellow-100;
  }

  &.color-red {
    color: $color-red-700;
    background: $color-red-300;

    &.dark-font {
      color: $color-red-900;
    }
  }

  &.color-gray {
    color: $color-gray-100;
    background-color: $color-gray-500;
  }

  &.color-gray-lightest {
    color: $color-gray-700;
    background-color: $color-gray-100;
  }

  &.color-gray-darkest {
    color: $color-gray-100;
    background-color: $color-gray-900;
  }

  &.color-pink {
    color: $color-secondary-900;
    background: $color-secondary-300;
  }

  > * + * {
    margin-left: 0.25rem;
  }
}
