.code-c-divider {
  &.vertical {
    height: 100%;
    border-left: 1px solid $color-border-gray;
  }

  &:not(.vertical) {
    border-bottom: 1px solid $color-border-gray;
  }
}
