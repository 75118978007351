.code-c-icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  color: $color-gray-900;
  cursor: pointer;
  background-color: transparent;
  border: 0;

  &:hover {
    color: $color-black;
  }

  &--gray {
    color: $color-white;
    background-color: $color-gray-300;

    &:hover {
      color: $color-white;
      background-color: $color-gray-400;
    }
  }

  &--text-gray {
    color: $color-gray-600;

    &:hover {
      color: $color-gray-900;
    }
  }

  &.is-rounded {
    border-radius: 50%;
  }

  &.is-disabled {
    cursor: not-allowed;
  }
}
