.code-c-steps-header {
  .code-c-steps-header__next {
    text-align: right;
  }

  .steps {
    width: 100%;
  }

  .code-c-steps-header__item {
    &.is-active.has-error::before {
      color: $color-red;
    }
  }

  .steps-marker {
    cursor: pointer;
    border: none;

    &.is-disabled {
      cursor: not-allowed;
    }

    &.has-error {
      color: $color-white !important;
      background-color: $color-red !important;
    }
  }

  .steps-content {
    display: block;

    &.is-disabled {
      cursor: not-allowed;
    }

    .steps-content__link {
      display: inline-block;
      color: $color-text;
    }
  }
}
