.code-challenge-detail {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background: $color-white;

  .code-challenge-detail__header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid $color-border-gray;

    .code-challenge-detail__header-left {
      display: flex;

      .code-challenge-detail__title {
        font-size: 1rem;
        font-weight: bold;
      }

      .code-challenge-detail__pin {
        padding-top: 0.125rem;
      }

      & > *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    .code-challenge-detail__header-right {
      display: flex;
      gap: 0.75rem;
      align-items: center;
    }
  }

  main {
    display: flex;
    flex-grow: 1;
    overflow-y: hidden;

    .code-challenge-detail__description {
      padding: 1rem;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    .code-challenge-detail__body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;

      .code-c-tabs {
        position: sticky;
        top: 0;
        z-index: 2;
        background: $color-white;
      }

      .code-challenge-detail__tab-contents {
        padding: 1rem;
      }
    }

    aside {
      flex-shrink: 0;
      min-width: 13rem;
      padding: 1rem;
      overflow-y: auto;
      border-left: 1px solid $color-border-gray;

      .code-c-form-group {
        margin-bottom: 1rem;
      }

      .code-challenge-detail__categories {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .code-c-new {
        margin-left: 0.25rem;
      }
    }
  }

  .code-c-evaluation-point {
    .code-c-tooltip {
      z-index: 1;
    }
  }
}
