.code-c-accordion {
  .code-c-accordion__bar {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    padding: 0.25rem;
    text-align: unset;
    cursor: pointer;
    background: $color-gray-light;
    border: 1px solid $color-border-gray;

    &__disabled {
      cursor: auto;
    }
  }

  .code-c-accordion__detail {
    padding: 1rem;
    border: 1px solid $color-border-gray;
    border-top: 0;
  }
}

@media print {
  .code-c-accordion {
    .code-c-icon {
      display: none;
    }
  }
}
