.code-submission-detail__tab {
  margin-left: -0.75rem;

  @media print {
    display: none;
  }

  .code-submission-detail__action-log-tab {
    display: flex;

    // Add spacing if followed by a tag
    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    .code-c-new {
      height: 21px;
      margin-right: 0;
    }
  }
}

.code-submission-detail__tab-body {
  padding: 0 1.5rem 6rem;
}
