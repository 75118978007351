.code-c-modal {
  display: flex;
  overflow: auto;
  overscroll-behavior: contain;
  opacity: 0;
  transform: scale(0);

  &.is-open {
    animation: fadeIn 0.5s forwards;
  }

  &.is-medium {
    > .modal-card {
      width: 100%;
      max-width: 720px;
    }
  }

  &.is-large {
    > .modal-card {
      width: 100%;
      max-width: 960px;
    }
  }

  &.is-x-large {
    > .modal-card {
      width: 100%;
      max-width: 1280px;
    }
  }

  &.is-full {
    > .modal-card {
      width: 100%;
      max-width: 95%;
    }

    > .modal-card-body {
      padding: 0;
      margin: 0;
    }
  }

  &.is-submenu {
    .modal-card {
      height: 100%;
    }

    .modal-card-body {
      position: relative;
      overflow: hidden;
    }

    .code-c-sub-menu-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      min-height: auto;
    }

    .code-c-sub-menu-container-left,
    .code-c-sub-menu-container-right {
      overflow-y: auto;
    }
  }

  &.hide-close {
    .modal-card-head .delete {
      display: none;
    }
  }

  &.modal {
    $modal-header-border-height: 0.5rem;

    .modal-background {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-card-head__top-border {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: $modal-header-border-height;
      background-color: $color-primary-500;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .modal-card-head {
      z-index: 2;
      padding-top: calc(20px + #{$modal-header-border-height});
      background: $color-white;

      .modal-card-title {
        flex-shrink: 1;
        font-size: 1.25em;
        line-height: 1.2;
      }
    }

    .modal-card-body {
      position: relative;
      padding-bottom: 4em;
      overscroll-behavior: contain;
    }

    .modal-card-foot {
      flex-direction: column;
      justify-content: center;
      background: $color-white;

      .modal-card-foot__text {
        margin-bottom: 1rem;
        text-align: center;
      }

      .modal-card-foot__buttons {
        display: flex;
      }
    }
  }

  textarea {
    z-index: 1;
  }
}
