.code-exam-detail-update-available-box {
  .code-exam-detail-update-available-box__container {
    margin-bottom: 1rem;
  }

  .code-exam-detail-update-available-box__header {
    border-top: 0.3em solid $color-yellow;
    border-radius: 3px 3px 0 0;
  }

  .code-exam-detail-update-available-box__body {
    padding: 1rem;
    font-size: smaller;
    border: 1px solid $color-border-gray;
    border-top: none;
    border-radius: 0 0 3px 3px;

    > p {
      margin-bottom: 0.5rem;
    }
  }
}
