.code-exam-detail-top__custom-form-panel {
  border: 1px $color-neutral-200 solid;
  border-radius: 0.5rem;

  .code-submission-detail__entry-form-summary {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: $color-white;
    border: 0;
    border-radius: 0.5rem;

    &:hover:not(.disabled) {
      background: $color-neutral-100;
    }

    h3 > small {
      @include text-md;

      margin-left: 0.5rem;
      color: $color-gray-600;
    }
  }

  .code-submission-detail__entry-form-detail {
    padding: 0.5rem 1rem 1.5rem;
    border: 0;
  }

  .code-c-custom-form-display {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 0;
    border: 1px solid $color-neutral-200;

    &:not(:last-child) {
      border-bottom: 0;
    }

    dt {
      padding: 0.5rem 1rem;
      margin-bottom: 0;
      background: $color-neutral-100;
    }

    dd {
      padding: 0.5rem 1rem;
      margin-bottom: 0;
    }
  }
}
