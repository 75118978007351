.code-c-history-graph__region-line-x {
  &.submitted-at {
    line {
      stroke: $color-gray-400;
    }

    text {
      fill: $color-gray-600;
    }
  }

  &.extended-at {
    line {
      stroke: $color-neutral-600;
    }

    text {
      fill: $color-neutral-600;
    }
  }
}

.code-c-history-graph__region-line-y {
  line {
    stroke: $color-gray-400;
  }

  text {
    fill: $color-gray-600;
  }

  &.is-perfect-score {
    line {
      stroke: $color-success;
    }

    text {
      fill: $color-success;
    }
  }
}