.code-c-history-graph {
  svg {
    font-family: $font-family-base;
  }

  .c3-line-score {
    stroke-width: 2px;
  }

  .c3-axis-y-label {
    font-size: 1.125em;
  }

  &.c3 {
    .c3-shapes {
      .c3-shape.c3-shape.c3-area.c3-area-value {
        /* stylelint-disable-next-line function-url-quotes */
        fill: url(#gradient-style) !important;
      }

      circle[data-status="error"] {
        fill: $color-red-500 !important;
      }

      circle[data-status="success"] {
        &._expanded_ {
          fill: $color-book-modal-background !important;
        }
      }
    }

    .c3-grid {
      .c3-ygrids {
        .c3-ygrid {
          opacity: 0.5;
          stroke: $color-border-gray;
          stroke-dasharray: none;
        }
      }
    }

    .c3-axis {
      text {
        font-weight: 500;
        fill: $color-gray-600;
      }

      &.c3-axis-y {
        path.domain {
          stroke: none;
        }

        line {
          stroke: $color-white;
        }
      }
    }

    .c3-axis-x {
      text {
        font-weight: 500;
        fill: $color-gray-600;
      }

      path.domain {
        stroke: $color-border-gray;
        stroke-width: 2px;
      }

      line {
        stroke: $color-border-gray;
      }
    }
  }
}
