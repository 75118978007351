.code-c-avatar__wrapper {
  position: relative;
  display: block;
  width: fit-content;
  padding: 0;
  margin: 0;
}

.code-c-avatar {
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;

  .code-c-avatar__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 6px;
  }

  &.is-small {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
  }

  &.is-medium {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
  }

  &.is-large {
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
  }

  &.is-xlarge {
    width: 8rem;
    min-width: 8rem;
    height: 8rem;
  }

  &.is-xxlarge {
    width: 12.5rem;
    min-width: 12.5rem;
    height: 12.5rem;
  }

  &.is-xxlarge-landscape {
    width: 12.5rem;
    min-width: 12.5rem;
    height: 6.875rem;
  }
}

.code-c-avatar__defaultmessage {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0.8125rem;
  font-weight: 400;
  color: $color-neutral-500;
  text-align: center;
}
