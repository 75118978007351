.code-c-collapsible-question-column {
  .code-c-collapsible-question-column__contents {
    background-color: $color-gray-light;
    transition: all 0.25s;

    &:hover {
      background-color: $color-gray-light !important;
    }

    .code-c-collapsible-question-column__contents__container {
      margin: 1rem;
      overflow-x: auto;
      background-color: $color-white;
      border: 1px solid $color-gray-200;
    }

    td {
      overflow: auto;
    }
  }

  .code-c-collapsible-question-column__sticky-header {
    position: fixed;
    z-index: 1;
    padding: 1rem;
    margin-left: -0.75rem;
    background: $color-white;
    border-bottom: 1px solid $color-border-gray;
  }

  .code-c-question-title-column__toggle {
    transition: all 0.25s;
  }

  &.is-close {
    .code-c-collapsible-question-column__contents {
      display: none;
    }

    .code-c-question-title-column__toggle {
      transform: rotate(0deg);
    }
  }

  &.is-open {
    .code-c-question-title-column__toggle {
      transform: rotate(-180deg);
    }
  }

  .code-c-more-link {
    margin-left: 0.5rem;
  }
}

.challenge-column__draft {
  color: $color-gray;
  background: $color-gray-light;
}

@include mq(sm) {
  .code-c-collapsible-question-column tr.code-c-row {
    flex-direction: column;
    border-bottom: 1px solid $color-border-gray;
  }

  .table.is-bordered .code-c-collapsible-question-column td.code-c-column {
    border: none;
  }

  table.table.is-bordered td.code-c-column {
    border-bottom: none;
  }

  .code-c-row {
    border-bottom: 1px solid $color-border-gray;
  }
}
