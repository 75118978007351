.code-programming-language-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  padding: 0 4rem;
  font-size: 1rem;

  .code-programming-language-panel__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
