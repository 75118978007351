.code-c-radio-panel {
  width: 100%;
  height: inherit;
  font-size: 1em;

  &.is-checked {
    .code-c-radio-panel__label {
      background-color: $color-neutral-100;
    }
  }

  .code-c-radio-panel__label {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    padding: 1rem;
    cursor: pointer;
    border: 1px solid $color-gray-200;
    border-radius: 0.5rem;

    &.is-readonly {
      color: $color-gray-600;
      cursor: not-allowed;

      strong {
        color: $color-gray-600;
      }

      .code-c-switch-panel-content { // Override the colors of the switch panel content component if used
        .code-c-switch-panel-content__title {
          color: $color-gray-600;
        }

        .code-c-switch-panel-content__text {
          color: $color-gray-600;
        }
      }
    }

    &.is-danger {
      border-color: $color-error-500;
    }

    &.is-align-top {
      align-items: flex-start;
    }

    &.is-align-center {
      align-items: center;
    }

    &.is-align-bottom {
      align-items: flex-end;
    }

    &:hover:not(.is-readonly) {
      background-color: $color-neutral-100;

      .code-c-radio-panel__checkmark-container {
        border-color: $color-neutral-400;
      }

      input:checked ~ .code-c-radio-panel__checkmark-container {
        border-color: $color-primary-600;

        .code-c-radio-panel__checkmark::after {
          background: $color-primary-600;
        }
      }
    }

    // Hide the original radio button
    .code-c-radio-panel__radio {
      position: absolute;
      flex-grow: 0;
      flex-shrink: 0;
      align-self: flex-start;
      margin: 0;
      margin-top: 2px;
      cursor: pointer;
      opacity: 0;

      &[readonly] {
        cursor: not-allowed;
      }
    }

    .code-c-radio-panel__label__text {
      flex-grow: 1;
      flex-shrink: 1;
      word-break: break-word;
    }
  }

  // Style the checkmark

  .code-c-radio-panel__checkmark-wrapper {
    display: flex;
    align-items: center;
  }

  .code-c-radio-panel__checkmark-container {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: 0.125rem;
    border: 1px solid $color-neutral-300;
    border-radius: 50%;

    .code-c-radio-panel__checkmark {
      &::after {
        position: absolute;
        content: "";
      }
    }
  }

  input {
    &[disabled] {
      & ~ .code-c-radio-panel__checkmark-container {
        background-color: rgba($color-gray-100, 0.5);
        border-color: $color-gray-300;
      }
    }

    &:checked {
      & ~ .code-c-radio-panel__checkmark-container {
        border-color: $color-primary-500;

        .code-c-radio-panel__checkmark::after {
          inset: 0.188rem;
          width: 0.5rem;
          height: 0.5rem;
          background: $color-primary-500;
          border-radius: 50%;
        }
      }

      &[disabled] {
        & ~ .code-c-radio-panel__checkmark-container {
          background-color: $color-white;
          border-color: $color-primary-300;

          .code-c-radio-panel__checkmark::after {
            background: $color-primary-300;
          }
        }
      }
    }

    &:focus-visible {
      & ~ .code-c-radio-panel__checkmark-container {
        &::before {
          position: absolute;
          inset: -0.25rem;
          content: "";
          border: 2px solid $color-primary-500;
          border-radius: 1rem;
        }
      }
    }
  }
}
