$exam-challenge-table-grid-template-columns: minmax(auto, 3rem)
  minmax(10rem, auto) minmax(auto, 6rem) minmax(auto, 6rem) minmax(auto, 6rem)
  minmax(auto, 6rem) minmax(auto, 2rem) minmax(auto, 2rem);
$exam-challenge-set-header-height: 4rem;

// Styles for ExamChallengeSet
.code-exam-edit__challenge {
  &.is-edit {
    .code-exam-edit__challenge-table-body {
      &:hover {
        background-color: $color-yellow-100;
        transition: background-color 0.5s;
      }
    }
  }

  .code-exam-edit__challenge__switch-linked-challenge {
    margin-top: 0.5rem;

    .code-c-button {
      margin-bottom: 0;
    }

    .code-c-linked-challenge-item {
      position: relative;
      z-index: $z-index-overlay + 1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

      &:hover {
        background-color: $color-gray-super-light;
        transition: background-color 0.5s;
      }
    }
  }

  .code-exam-edit__challenge-table {
    display: grid;

    .code-exam-edit__challenge-table-header {
      display: grid;
      grid-template-columns: $exam-challenge-table-grid-template-columns;
      grid-column-gap: 1rem;
      align-items: flex-end;
      padding: 0.5rem 0.75rem;
      background: $color-gray-super-light;
      border: 1px solid $color-border-gray;

      .code-exam-edit__challenge-table-header__title {
        display: flex;
        align-items: center;

        .code-c-button {
          margin-left: 0.5rem;
        }
      }
    }

    .code-exam-edit__challenge-table-body {
      position: relative;
      display: grid;
      grid-template-areas:
        "A B . . . . . ."
        "A B C C C C C C";
      grid-template-rows: 3rem auto;
      grid-template-columns: $exam-challenge-table-grid-template-columns;
      grid-row-gap: 1rem;
      grid-column-gap: 1rem;
      padding: 1.25rem 0.75rem;
      border: 1px solid $color-border-gray;
      border-top: none;

      &.has-two-tags {
        grid-template-rows: 5rem auto;
      }

      &.is-pinned {
        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 3px;
          content: "";
          background: $color-yellow;
        }
      }

      .code-exam-edit__challenge-table__actions {
        visibility: hidden;
        opacity: 0;
        transition:
          visibility 0s,
          opacity 0.5s;
      }

      &:hover {
        background: hsla(0, 0%, 93%, 0.25);
        transition: background-color 0.5s;

        .code-exam-edit__challenge-table__actions {
          visibility: visible;
          opacity: 1;
        }
      }

      .code-exam-edit__challenge-table__pinned {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        font-size: 0.625rem;
        color: $color-gray;

        .code-c-icon {
          color: $color-yellow;
          transform: rotate(45deg);
        }
      }

      .code-exam-edit__tags {
        display: flex;
        gap: 0.5rem;
      }
    }

    .code-exam-edit__challenge-table__actions-container {
      grid-area: A;

      .code-c-tooltip {
        z-index: unset;
      }
    }

    .code-exam-edit__challenge-table__title {
      display: flex;
      flex-direction: column;
      grid-area: B;
      row-gap: 0.5rem;
      align-items: flex-start;
    }

    .code-exam-edit__challenge-table__version-box {
      display: flex;
      grid-area: C;
      justify-content: flex-end;
    }
  }

  .code-exam-edit__challenge-table__error {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.5rem 0.75rem;
    font-weight: bold;
    color: $color-error;
    background: $color-error-background;
    border: 1px solid $color-border-gray;
    border-top: none;
  }

  .code-exam-edit__challenge__no-version {
    color: $color-yellow;
  }

  .code-exam-edit__challenge__detail-panel {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: $z-index-loading + 1;
    width: 100%;
    max-width: 65rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .code-exam-edit__challenge__overlay {
    background: transparentize($color-gray-super-dark, 0.5);
  }
}

// Styles for ExamChallenges
.code-exam-edit__challenges {
  .code-exam-edit__challenges__scroll-container {
    overflow: visible;

    .code-exam-edit__exam-challenge-set-header {
      position: sticky;
      top: 0;
      z-index: 2;
      height: $exam-challenge-set-header-height;
    }

    .code-exam-edit__challenge-table-header {
      position: sticky;
      top: $exam-challenge-set-header-height;
      z-index: 1;
    }

    .code-exam-edit__challenge-table__timeLimit {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-end;
    }
  }

  .code-exam-edit__challenges__error-message {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: $color-error;
    background: $color-error-background;
    border: 1px solid $color-error;
  }
}
