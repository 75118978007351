.code-c-copy-box {
  .code-c-button {
    min-width: 2rem;
  }

  .badge {
    &::after {
      animation: fadeIn 0.5s;
    }
  }

  .code-c-input {
    flex: 1;
  }

  .code-c-copy-box__button {
    &.is-scale {
      border: 1px solid $color-gray-200;
      border-left: 0;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}
