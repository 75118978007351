.code-exam-challenge-edit {
  .code-exam-challenge-edit__sub-text {
    margin-top: 0.5rem;
    color: $color-gray-dark;
  }

  .code-exam-challenge-edit__time-limit {
    display: inline-flex;

    > div:first-child {
      margin-right: 1rem;
    }

    .code-c-checkbox {
      margin-top: 0.5rem;
    }
  }

  .code-exam-challenge-edit__programming-languages {
    display: inline-flex;

    > div:first-child {
      margin-right: 1rem;
    }

    .code-c-checkbox__label {
      display: inline-flex;
    }
  }

  .code-c-label {
    &.has-error-icon {
      display: flex;
      align-items: center;
    }

    .code-c-icon {
      margin-left: 0.25rem;
    }
  }

  .code-c-validation-message {
    white-space: nowrap;
  }

  .code-exam-challenge-edit__warning {
    margin-top: 0.25rem;
    color: $color-yellow;
  }

  .code-exam-challenge-edit__playback-warning {
    margin-bottom: 0.5rem;
  }
}
