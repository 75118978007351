.code-submission-evaluation-dropdown {
  span {
    @include text-sm;

    font-weight: 700;
    text-transform: uppercase;
  }

  &.code-submission-evaluation-dropdown__button {
    gap: 0.5rem;
    justify-content: space-between;
    min-width: initial;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;

    .code-c-icon {
      width: 0.75rem;
      height: 0.75rem;
      margin: 0 !important;
    }
  }

  .pass {
    color: $color-success-500;
  }

  .fail {
    color: $color-error-500;
  }

  .not-evaluated {
    color: $color-gray-500;
  }

  .code-submission-evaluation-dropdown__item {
    cursor: pointer;

    &:hover {
      background-color: $color-neutral-100;
    }

    &.selected {
      cursor: not-allowed;
      background-color: $color-neutral-100;
    }
  }

  .code-submission-evaluation-dropdown__label {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .code-c-icon {
      width: 0.5rem;
      height: 0.5rem;
      font-size: 0.5rem;
    }
  }
}
