.code-setting-member-pending {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .code-setting-member-pending__header {
    align-items: center;
    padding: 1.5rem 1.5rem 0;
    white-space: nowrap;

    .code-c-left-block {
      margin-right: 2rem;
    }

    .code-c-right-block {
      flex-grow: 1;
      justify-content: flex-end;

      > :not(:last-child) {
        margin-right: 1rem;
      }

      .code-c-input {
        width: 100%;
        max-width: 30rem;
      }
    }

    .code-setting-member-pending__header__count {
      margin-left: 0.5rem;
      font-size: 0.8125rem;
    }
  }

  .code-setting-member-pending__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-setting-member-pending__email {
    margin-bottom: 1rem;
    word-break: break-all;
  }

  .code-setting-member-pending__roles {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .code-setting-member-pending__roles__project-name {
      margin-bottom: 0.25rem;
      font-size: 0.8rem;
    }

    .code-setting-member-pending__roles__role-names {
      margin-left: 0.5rem;
    }
  }

  .code-setting-member-pending__actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }

  .code-c-table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }

    .code-c-table-body {
      .code-c-tag__expired {
        margin-bottom: 0.5rem;
        color: $color-white;
        background: $color-gray-dark;
      }
    }
  }
}
