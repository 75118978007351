th.code-c-header-column {
  padding: 0.5rem 0.75rem;
  font-weight: normal;
  word-break: break-word;
  vertical-align: middle;
  border: 0;

  &.no-padding-x {
    padding-right: 0;
    padding-left: 0;
  }

  &.no-padding-left {
    padding-left: 0;
  }

  &.no-padding-right {
    padding-right: 0;
  }

  .table.is-bordered & {
    border-bottom: 1px solid $color-neutral-200;
  }

  .table.is-inside-bordered &:not(:last-child) {
    border-right: 1px solid $color-neutral-200;
  }

  .code-c-button {
    min-width: unset;
    margin: 0 1rem;
    vertical-align: middle;
  }

  &.is-1 {
    width: 3rem;
    min-width: unset;
  }

  &.is-2 {
    width: 6rem;
    min-width: unset;
  }

  &.is-3 {
    width: 9rem;
    min-width: unset;
  }

  &.is-4 {
    width: 12rem;
    min-width: unset;
  }

  &.is-5 {
    width: 15rem;
    min-width: unset;
  }

  &.is-6 {
    width: 18rem;
    min-width: unset;
  }

  &.is-7 {
    width: 21rem;
    min-width: unset;
  }

  &.is-8 {
    width: 24rem;
    min-width: unset;
  }

  &.is-absolute-1 {
    width: 1rem;
    min-width: unset;
  }

  &.is-absolute-2 {
    width: 2rem;
    min-width: unset;
  }

  &.is-absolute-3 {
    width: 3rem;
    min-width: unset;
  }

  &.is-absolute-4 {
    width: 4rem;
    min-width: unset;
  }

  &.is-absolute-5 {
    width: 5rem;
    min-width: unset;
  }

  &.is-absolute-6 {
    width: 6rem;
    min-width: unset;
  }

  &.is-absolute-7 {
    width: 7rem;
    min-width: unset;
  }

  &.is-absolute-8 {
    width: 8rem;
    min-width: unset;
  }

  &.is-absolute-9 {
    width: 9rem;
    min-width: unset;
  }

  &.is-absolute-10 {
    width: 10rem;
    min-width: unset;
  }

  &.is-absolute-11 {
    width: 11rem;
    min-width: unset;
  }

  &.is-absolute-12 {
    width: 12rem;
    min-width: unset;
  }
}
