.code-c-submission-detail-challenge-title {
  .code-c-submission-detail-challenge-title__title {
    @include fontSize("heading3");

    display: flex;
    flex-grow: 1;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .code-c-submission-detail-challenge__toggle {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .code-c-submission-detail-challenge-title__options {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;

    .code-c-submission-detail-challenge-title__status {
      @include fontSize("small");

      color: $color-gray;
    }
  }
}
