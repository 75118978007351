.code-c-switch-panel {
  font-size: 1em;
  vertical-align: top;

  &.is-checked {
    .code-c-switch-panel__label {
      background-color: $color-neutral-100;
    }
  }

  .code-c-switch-panel__label {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    cursor: pointer;
    border: 1px solid $color-gray-200;
    border-radius: 0.5rem;
    transition: border-radius 0.25s step-end;

    &.is-readonly {
      color: $color-gray-600;
      cursor: not-allowed;

      strong {
        color: $color-gray-600;
      }
    }

    &.is-expanded-below {
      border-radius: 0.5rem 0.5rem 0 0; // Remove rounding from bottom corners.
      transition: border-radius 0.25s step-start;
    }

    &:hover:not(.is-readonly) {
      background-color: $color-neutral-100;
    }

    &.is-danger {
      border-color: $color-error-500;
    }

    &.is-align-top {
      align-items: flex-start;
    }

    &.is-align-center {
      align-items: center;
    }

    &.is-align-bottom {
      align-items: flex-end;
    }

    // Hide the original checkbox
    .code-c-switch-checkbox {
      position: absolute;
      flex-grow: 0;
      flex-shrink: 0;
      align-self: flex-start;
      margin: 0;
      margin-top: 2px;
      cursor: pointer;
      opacity: 0;

      &[readonly] {
        cursor: not-allowed;
      }
    }

    .code-c-switch-panel__label__text {
      flex-grow: 1;
      flex-shrink: 1;
      word-break: break-word;
    }
  }

  // Style the checkmark

  .code-c-switch-panel__checkmark-wrapper {
    display: flex;
    align-items: center;
    height: 1rem; // Note: can be overridden using the initialLineHeight prop
  }

  .code-c-switch-panel__checkmark-container {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 1.25rem;
    margin: 0.125rem;
    background-color: $color-neutral-200;
    border-radius: 1rem;
    transition: background-color 0.1s ease-in;

    .code-c-switch-panel__checkmark {
      &::after {
        position: absolute;
        top: 0.125rem;
        left: 0.125rem;
        width: 1rem;
        height: 1rem;
        content: "";
        background: $color-white;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(39, 39, 39, 0.10);
        transition: transform 0.1s ease-in;
      }
    }
  }

  input {
    &[disabled] {
      & ~ .code-c-switch-panel__checkmark-container {
        background-color: $color-gray-200;
      }
    }

    &:checked {
      & ~ .code-c-switch-panel__checkmark-container {
        background-color: $color-primary-500;

        .code-c-switch-panel__checkmark::after {
          transform: translateX(1rem);
        }
      }

      &[disabled] {
        & ~ .code-c-switch-panel__checkmark-container {
          background-color: $color-primary-300;
        }
      }
    }

    &:focus-visible {
      & ~ .code-c-switch-panel__checkmark-container {
        &::before {
          position: absolute;
          inset: -0.25rem;
          content: "";
          border: 2px solid $color-primary-500;
          border-radius: 1rem;
        }
      }
    }
  }
}
