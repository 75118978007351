.code-member-role-form {
  display: flex;
  flex-direction: column;

  .code-member-role-form__item {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .invalid-tier {
      color: $color-gray;

      .checkbox:hover {
        color: $color-gray;
      }
    }
  }
}