.code-c-challenge {
  display: block;
  $code-c-challenge-max-height: 20rem;

  .code-c-challenge__container {
  }

  .code-c-challenge__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .code-c-challenge__header-left {
  }

  .code-c-challenge__header-right {
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .code-c-challenge__body {
  }

  .code-c-challenge__question {
    display: flex;
    margin-bottom: 1rem;
  }

  .code-c-challenge__question-no {
    flex-grow: 0;
    min-width: 2rem;
  }

  .code-c-challenge__question-item {
    flex-grow: 1;
    border: 1px solid $color-border-gray;
  }
}
