.code-coding-checklist-panel-new {
  display: flex;
  flex-direction: column;
  padding: 0;
  font-size: 1rem;

  .code-coding-checklist-panel__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.5rem;
    padding: 0;
  }

  .code-coding-checklist-panel__item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0 !important;
  }

  .code-coding-checklist-panel__check {
    .code-c-checkbox__label__text {
      font-size: 0.875rem;
    }
  }

  .code-coding-checklist-panel__doc-link {
    display: inline-block;
  }
}
