.code-c-dropdown {
  position: absolute;
  z-index: $z-index-loading + 1;
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 0.125rem;
  box-shadow: 0 1px 16px 0 rgba(20, 20, 19, 0.16);
  opacity: 0;
  animation: slideOutUp 0.15s ease-in;

  &.hidden {
    display: none;
  }

  &.active {
    opacity: 1;
    animation: slideInDown 0.25s ease-in;
  }
}

.code-c-dropdown__overlay {
  display: inline-block;

  & > button {
    & > .code-c-icon:not(:last-child) {
      margin-right: 0.25rem;
    }
  }
}
