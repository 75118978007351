.code-exam-dashboard-outline__section {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.75rem;
  background-color: $white;
  border-radius: 0.25rem;
}

.code-exam-dashboard-outline__section-content {
  flex: 1;
}

.code-exam-dashboard-outline__section-header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.75rem;
  overflow: hidden;
  color: $color-primary-800;
  white-space: nowrap;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    background-color: $color-primary-200;
    border-radius: 0.25rem;
  }
}

.code-exam-dashboard-outline__section-footer {
  padding-top: 0.5rem;
  border-top: 1px solid $color-neutral-200;
}
