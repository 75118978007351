.code-c-image-upload {
  display: inline-block;

  &__drag-area {
    position: relative;
    cursor: pointer;
    border: 2px dashed $color-gray-dark;
    border-radius: 6px;

    &.is-disabled {
      border-color: $color-gray-light;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
