.code-admin-challenge-collection-detail {
  border-top: 1px solid $color-border-gray;

  .code-admin-challenge-collection-form__preview {
    position: sticky;
    top: 7rem;
  }

  .code-c-challenge-collection-card {
    cursor: inherit;
  }
}
