.code-c-question-title-column {
  .code-c-question-title-column__title {
    margin-bottom: 0.5em;
    word-break: break-all;
  }

  .hasClick {
    cursor: pointer;
  }
}
