.code-exam-challenge-set-reorder {
  .challenge-set-card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5rem;
    padding: 1rem;
    margin-bottom: -1px;
    border: 1px solid $color-border-gray;

    .challenge-set-card__title-container {
      flex-grow: 2;
      padding: 0 1rem;

      .challenge-count {
        color: $color-gray-dark;
      }
    }

    .arrow-button-container {
      position: relative;
      width: 5rem;
      height: 2.5rem;

      .arrow-button-container__down {
        position: absolute;
        left: 0;
      }

      .arrow-button-container__up {
        position: absolute;
        right: 0;
      }
    }
  }
}
