.code-start-challenge-walk-through {
  .modal-card-body {
    display: flex;
    height: 31rem;
  }

  .code-c-walk-through__item {
    position: relative;
    display: flex;
    padding: 0;

    &.with-card-padding {
      padding: 1rem 1.5rem;
    }

    .code-c-walk-through__item__inner {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
    }

    .code-c-walk-through__caution {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .code-c-walk-through__solving {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
    }

    .code-c-walk-through__item__subtitle {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.5rem;
    }

    .code-c-radio-tabs {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-items: flex-start;
      width: 100%;
    }

    .code-c-code-radiotab-icon {
      & > :first-child {
        width: 1.5rem;

        // Bottom of stack
        font-size: 1.5rem;
        color: $color-primary-200;
      }

      & > :last-child {
        // Top of stack
        font-size: 0.75rem;
        color: $color-primary;
      }

      @media screen and (width <= 414px) {
        // Override unwanted icon sizing on mobile that affects layout of icon and following text
        width: 1.5rem !important;
      }
    }

    .code-c-radiotab-aftericon {
      padding-left: 0.5rem;
    }
  }
}
