.code-editor-exam-detail__face-verification {
  &__icon-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    &__icon {
      position: relative;
      width: 200px;
      height: 178px;
      background-repeat: no-repeat;

      &__banner {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.75rem;
        font-weight: 700;
        color: $color-white;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        &--green {
          background-color: $color-primary-500;
        }

        &--red {
          background-color: $color-secondary-500;
        }
      }

      &--good {
        background-image: url("./assets/images/face-verification-good.svg");
      }

      &--bad {
        background-image: url("./assets/images/face-verification-bad.svg");
      }
    }
  }

  &__list {
    margin-left: 1.5rem;
  }

  &__warning-container {
    display: flex;
    padding: 0.5rem;
    margin-top: 1.5rem;
    background-color: $color-warning-100;

    &__title {
      font-size: 1rem;
      font-weight: 700;
    }

    &__text {
      font-size: 1rem;
      font-weight: 400;
    }

    &__icon {
      margin-right: 0.25rem;
      color: $color-warning-500;
    }
  }
}
