.code-exam-delivery-detail-modal {
  .modal-card-body {
    padding: unset;
  }

  .code-exam-delivery-detail-modal__applicant-container {
    padding: 20px; // unset padding of the modal and set it again
    padding-bottom: 30px;
  }

  .code-exam-delivery-detail-modal__edit-container {
    position: relative;
    padding: 20px;
    padding-top: 30px;
    border-top: solid 1px $color-border-gray;

    .code-exam-delivery-detail-modal__edit-container__toolbar {
      display: flex;
      justify-content: flex-end;
    }

    .code-exam-delivery-detail-modal__add-applicants {
      .code-c-tooltip-wrapper {
        display: block;
      }
    }
  }

  .code-exam-delivery-list__preview {
    $preview-min-height: 118px; // auto calculated by Textarea component.

    .code-exam-delivery-list__preview__input {
      width: 50%;

      .code-c-textarea {
        width: 100%;
      }
    }

    .code-exam-delivery-list__preview__window {
      width: 50%;
      margin-left: 1rem;
    }
  }

  .code-exam-delivery-list__preview__window__block {
    padding: 1rem;
    overflow: auto;
    word-break: break-all;
    white-space: pre-wrap;
    user-select: none;
    background: $color-gray-light;
    border: 1px solid $color-gray;
    border-radius: 3px;

    &.is-full-width {
      width: 100%;
      min-height: 118px;
    }
  }

  .code-exam-delivery-list__preview__window__text {
    margin-top: 0.75rem;
    margin-left: 0.5rem;
  }

  .code-c-form-group {
    max-width: unset;
  }

  .delivery-tag {
    margin-left: 0.5rem;
  }

  .code-exam-delivery-detail-modal__language {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
