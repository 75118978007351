
div[role="tooltip"].code-c-tooltip-notes {
  width: 300px;            /* Set a specific width */
  min-height: 100px;           /* Set a specific height */
  padding: 10px;           /* Optional: add padding for better spacing */
  color: black;            /* Set the text color */
  background-color: white; /* Set the background color */
  border-radius: 5px;      /* Optional: add rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Optional: add shadow for depth */
}

.code-c-submission-title-column {
  .code-c-submission-title-column__applicant-email {
    margin-bottom: .5rem;
  }

  .code-c-submission-title-column__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .code-c-submission-title-column__sample-tag {
      color: $color-white;
      background: $color-gray-500;
    }

    .code-c-submission-title-column__needs-review-tag {
      color: $color-white;
      background: $color-neutral-800;
    }
  }


  .code-c-submission-title-column__name {
    display: inline;
  }
}
