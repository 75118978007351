.code-exam-edit__outline {
  .code-exam-edit__outline__name-container {
    display: flex;
    gap: 1rem;
    max-width: $breakpoint-xl;

    .code-exam-edit__outline__name {
      flex-grow: 1;
      flex-shrink: 0;
      max-width: 50rem;
      padding: 1rem;
      background: $color-neutral-100;
      border: 1px solid $color-border-gray;
      border-radius: 3px;

      > div:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &.is-multi-lang {
      .code-exam-edit__outline__name {
        width: 50%;
        max-width: 50%;
      }
    }
  }

  .code-exam-edit__outline__label-container {
    margin-bottom: 0.75rem;

    .code-exam-edit__outline__label {
      @include text-sm;

      margin-bottom: 0.25rem;
    }

    .code-exam-edit__outline__sub-label {
      @include text-xs;

      color: $color-gray-600;
    }
  }

  .code-c-rich-markdown {
    height: 11rem;

    &.is-preview {
      .code-c-markdown,
      .code-c-rich-markdown__body {
        color: #7a7a7a !important;

        // NOTE emulating default text disable style
        background-color: whitesmoke;
      }
    }
  }

  .code-exam-edit__outline__next-step-container {
    padding: 1rem;
    background: $color-neutral-100;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
  }

  .code-exam-edit__outline__job-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid $color-border-gray;
    border-radius: 3px;

    .code-exam-edit__outline__job-container__info-icon {
      margin-right: 0.25rem;
      font-size: 1rem;
      color: $color-neutral-700;
    }

    .code-exam-edit__outline__job-container__select-container {
      display: flex;
      gap: 1rem;
      margin-bottom: 0.25rem;

      > div {
        flex-basis: 50%;

        .select,
        select {
          width: 100%;
        }
      }
    }
  }

  .code-exam-edit__outline__memo {
    height: 10rem;

    textarea {
      height: 10rem !important;
    }
  }

  @include mq(lg) {
    .code-exam-edit__outline__name-container {
      flex-direction: column;

      .code-exam-edit__outline__name {
        max-width: 50rem;
      }
    }
  }
}
