.select-floating {
    padding: 0.5rem;   
    background-color: $color-white;
    border: 1px solid $color-gray-300;
    border-radius: 4px;

    .no-matches {
        padding-top: 0.25rem;
        padding-right: 0.75rem;
        padding-bottom: 0.25rem;
        padding-left: 0.75rem;
    }
}