.code-challenge-copy-modal {
  padding: 1rem;

  .code-challenge-copy-modal__removed-question-message {
    display: flex;

    .code-c-icon {
      margin-right: 0.5rem;
      color: $color-yellow;
    }
  }

  .code-challenge-copy-modal__removed-question-title {
    li {
      margin-bottom: 0.5rem;
      margin-left: 1rem;
    }

    margin-top: 1rem;
    word-break: break-all;
    list-style-type: disc;
  }

  .code-challenge-copy-modal__challenge-title {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
  }
}
