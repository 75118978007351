.code-challenge-select {
  .code-c-sub-menu-container {
    height: calc(
      100vh - #{$header-height} - #{$breadcrumb-height} - #{$modal-footer-height}
    );
    border-top: 0;
  }

  .code-c-sub-menu-container-left {
    z-index: 2;
  }

  .code-c-sub-menu-container-right {
    .code-challenge-collection {
      padding: 1.5rem 1.5rem 0;
    }

    .code-challenge-select__table {
      margin: 1.5rem 1.5rem 0;
      overflow: visible;

      .code-c-table-head {
        tr {
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }

    .code-challenge-select__pagination {
      position: sticky;
      bottom: 0;
      z-index: $z-index-tooltip;
      display: flex;
      justify-content: center;
      padding: 2rem 1.5rem;
      background: $color-white;
    }
  }

  .code-challenge-select__review {
    > .modal-card {
      height: 100%;

      > .modal-card-body {
        padding: 0;
      }
    }

    .code-challenge-select__review-text {
      margin: 1.5rem;
    }

    .code-challenge-select__review-table {
      margin: 0 1.5rem 1.5rem;
      overflow: visible;

      .code-c-table-head {
        tr {
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }
  }

  .code-challenge-detail {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: 21;
    width: 100%;
    max-width: 65rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .code-challenge-select__overlay {
    z-index: 20;
    background: transparentize($color-gray-super-dark, 0.5);
  }
}
