.code-c-stacked-icon {
  // MEMO: If sizes are not included on the icons, StackedIcon doesn't automatically style the icons to be stacked on top of each other. Custom css is required.

  &__strike1 {
    top: 0.0625rem; // Approx 1px at 16px font size;
    color: $color-white;
  }

  &__strike2 {
    top: 0;
  }

  .code-c-stacked-icon-exclamation {
    // Works at most standard font sizes
    top: 50%;
    left: 50%;
    display: flex;
    width: 1em;
    height: 1em;
    font-size: 0.6em;

    &-border {
      position: absolute;
      top: -10%;
      left: 0;
      width: 100%;
      height: 100%;
      color: $color-white;
      transform: scale(1.25);
    }

    &-inner {
      position: absolute;
      top: -10%;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
