.code-submission-detail-deadline {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0;
  color: $color-gray-900;

  .code-submission-detail-deadline__panel {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
}
