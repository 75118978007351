.code-c-action-log-select {
  display: block;
  width: 100%;
  min-width: 0;

  .dropdown-trigger {
    .button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      min-width: 0;
      padding: 0.5rem;
      font-size: unset;

      &:active, &:focus {
        border-color: $color-neutral-700;
      }

      .current-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon {
        flex-shrink: 0;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    border: 1px solid $color-neutral-700;
    border-radius: 4px;
  }

  .dropdown-item {
    cursor: pointer;

    &.is-disabled {
      color: $color-gray-400;
      cursor: not-allowed;

      &:hover, &.is-active {
        color: $color-gray-500;
      }
    }

    &:hover, &.is-active {
      color: $color-gray-900;
      background-color: $color-gray-100;
    }

    label {
      display: flex;
      flex-wrap: nowrap;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: inherit;
    }
  }

  &.is-multiple {
    .dropdown-menu {
      top: 110%;
      padding-top: 0;
    }

    .dropdown-item {
      label {
        column-gap: 0.75rem;

        input[type="radio"] {
          flex-shrink: 0;
        }
      }
    }
  }

  &.is-single {
    .dropdown-menu {
      top: 0;
      padding-top: 0;
    }

    .dropdown-item {
      label {
        column-gap: 0;

        input[type="radio"] {
          display: none;
        }

        .icon {
          flex-shrink: 0;
          height: 1.3rem;
        }

        .hidden {
          visibility: hidden;
        }
      }
    }
  }

  .dropdown-divider {
    margin: 0.5rem 1rem;
    background-color: $color-neutral-200;
  }

  .code-c-tooltip-wrapper {
    display: block;
  }

}
