.code-dashboard-score-distribution {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @include mq-dashboard(md) {
    grid-template-columns: 1fr;
  }

  .code-dashboard-score-distribution__panel {
    overflow-x: hidden;

    &.code-exam-dashboard__panel {
      gap: 0;
      padding: 0;
    }

    > div {
      padding: 1rem;
    }

    .code-dashboard-score-distribution__chart {
      padding-bottom: 0.5rem;
    }

    .code-dashboard-score-distribution__stats {
      padding-top: 0.5rem;
    }
  }

  .code-dashboard-score-distribution__header {
    border-bottom: 1px $color-neutral-200 solid;

    a {
      color: $color-gray-700;
      text-decoration: none;
      cursor: text;
    }

    a.clickable {
      color: $color-neutral-900;
      cursor: pointer;

      &:hover {
        text-decoration: none;

        .code-dashboard-score-distribution__header__challenge-title {
          text-decoration: underline;
        }
      }
    }

    .code-dashboard-score-distribution__header__challenge-title {
      @include text-lg;

      display: block;
      margin-bottom: 0.5rem;
      overflow: hidden;
      font-weight: 700;
      line-height: 1.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .code-dashboard-score-distribution__header__tags {
      display: flex;
      gap: 0.5rem;
    }
  }

  .code-dashboard-score-distribution__drawer-overlay {
    background: transparentize($color-gray-super-dark, 0.5);
  }

  .code-dashboard-score-distribution__drawer {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: $z-index-loading + 1;
    width: 100%;
    max-width: 65rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }
}
