.code-c-popover {
    width: max-content;
    max-width: calc(100vw - 2rem);
    padding: 0.5em 1em;
    font-weight: initial;
    color: $color-black;
    text-align: left;
    overflow-wrap: anywhere;
    background: $color-white;
    border: 1px solid $color-black;
    border-radius: 2px;

    &:focus {
      outline: 1px solid black;
    }
  }

  .code-c-popover-trigger {
    cursor: pointer;
  }

  .code-c-popover__anchor {
    &:hover {
        cursor: pointer;
    }
  }

  .code-c-popover-content {
    &.small {
      max-width: 5rem;
    }
  
    &.medium {
      max-width: 10rem;
    }
  
    &.large {
      max-width: 16rem;
    }
  
    &.xlarge {
      max-width: 24rem;
    }
  }
