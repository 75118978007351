.code-c-sub-menu-checkbox-item {
  position: relative;
  padding: 0.125em 0.75em;
  font-size: 14px;
  word-break: break-all;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .code-c-sub-menu-checkbox-item__count {
    position: absolute;
    top: 0.25em;
    right: 0.75em;
  }
}
