.code-c-icon {
  &.is-smedium {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
  }

  &.is-5xl {
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
  }
}

.code-c-icon__text-base {
  width: 1rem;
  height: 1rem;
}

.code-c-icon__text-large {
  font-size: 1.5rem;
}

.code-c-icon__user-exclamation {
  background: transparent url("./assets/images/icon-user-exclamation.svg")
    no-repeat;
  background-size: contain;
}

.code-c-icon__user-exclamation-gray {
  background: transparent url("./assets/images/icon-user-exclamation-gray.svg")
    no-repeat;
  background-size: contain;
}

.code-c-icon__green-tick {
  background: transparent url("./assets/images/green-tick.svg") no-repeat;
  background-size: contain;
}
