.code-c-action-popover-button {
    padding-top: 6px;
    padding-right: 2px;
    padding-bottom: 6px;
    padding-left: 2px;
    

    
}

.code-c-action-popover-trigger {
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: #E0E1DF80;
    }

    &[data-state='open'] {
        background-color: #E0E1DF80;
    }

    
}

.code-c-action-popover-content {
    width: max-content;
    max-width: calc(100vw - 2rem);
    padding: 8px;
    font-weight: initial;
    color: $color-black;
    text-align: left;
    overflow-wrap: anywhere;
    background: $color-white;
    border: 1px solid $color-gray-300;
    border-radius: 2px;

    &:focus {
        outline: none;
    }

    &__item {
        padding-top: 8px;
        padding-right: 12px;
        padding-bottom: 8px;
        padding-left: 12px;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            background-color: #F5F8FA;
        }
    }

    &__label {
        font-size: 0.875rem;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}