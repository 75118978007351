.code-code-playback {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .code-code-playback__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .code-code-playback__iframe {
    flex-grow: 1;
    border: none;
  }
}
