.code-challenge-list {
  height: $challenge-list-container-height;

  .code-c-sub-menu-container {
    height: $challenge-list-container-height;
    border-top: 0;
  }

  .code-challenge-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-challenge-collection {
    padding: 1.5rem 1.5rem 0;
    margin-bottom: 1.5rem;
  }

  .code-c-filter-conditions {
    padding: 0 1.5rem;
  }

  .code-challenge-list-header {
    padding: 0 1.5rem;
  }

  .code-challenge-list__table {
    margin: 0 1.5rem;

    .code-c-table {
      overflow: visible;
    }

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .code-challenge-list__detail-panel {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: $z-index-loading - 1;
    width: 100%;
    max-width: 65rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .code-challenge-list__overlay {
    background: transparentize($color-gray-super-dark, 0.5);
  }
}
