.code-c-release-note {
  .code-c-release-note__body {
    list-style: none;
  }

  .code-c-release-note__item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .code-c-release-note__item-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
    border-bottom: 1px solid $color-border-gray;

    .code-c-release-note__release-at {
      font-size: 0.8125rem;
    }
  }

  .code-c-release-note__note {
    padding-left: 0.5rem;
    word-break: break-word;
    white-space: pre-line;
  }
}
