.code-c-sortable-text {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;

  .code-c-sortable-text__text {
    color: $color-text-vivid;
    text-align: inherit;
    text-decoration-color: $color-text-vivid;
    word-break: break-word;
  }

  .code-c-sortable-text__icon {
    .code-c-icon {
      color: $color-text-vivid;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
