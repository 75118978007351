.code-webcam-event-handler {
  display: flex;
  gap: 0.25rem;
  background-color: $color-black;

  video {
    display: none;
    width: 640px;
    height: 480px;
  }

  // this only sets external resolution
  // internal resolution can be set in useWebcam()
  canvas {
    display: none;
    width: 640px;
    height: 480px;
  }
}
