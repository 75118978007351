.code-exam-challenge-update-available-box {
  width: 18rem;

  .code-exam-challenge-update-available-box__header {
    border-top: 0.3em solid $color-yellow;
    border-radius: 3px 3px 0 0;
  }

  .code-exam-challenge-update-available-box__body {
    padding: 1rem;
    font-size: smaller;
    background: $color-white;
    border: 1px solid $color-border-gray;
    border-top: none;
    border-radius: 0 0 3px 3px;

    > div:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .code-exam-challenge-update-available-box__bottom {
    display: flex;
    column-gap: 1rem;
    align-items: flex-end;
    justify-content: space-between;
  }

  .code-exam-challenge-update-available-box__versions {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 0.5rem;

    .code-exam-challenge-update-available-box__versions-link {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      justify-content: space-between;

      &:hover {
        text-decoration: none;

        .clamp-1 {
          text-decoration: underline;
        }
      }
    }
  }

  .code-c-tooltip {
    z-index: unset;
  }
}
