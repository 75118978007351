.code-c-table {
  overflow-x: auto;

  &.is-bordered {
    border: 1px solid $color-border-gray;

    &.is-gray-light {
      border: 0;
    }
  }

  .table {
    margin-bottom: 0;
    table-layout: fixed;
    border-collapse: separate;

    &.is-hoverable {
      tr.code-c-row:hover {
        background: transparentize($color-gray-light, 0.75);
      }
    }

    &.is-gray-light {
      &.is-bordered {
        border: 1px solid $color-neutral-200;
      }
    }
  }
}
