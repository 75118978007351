.code-custom-form-edit {
  &__inline {
    display: flex;
  }

  &__type {
    margin-right: 1rem;
  }

  &__item-preview {
    background: transparentize($color-gray-light, 0.7);
  }

  &__add-button {
    justify-content: center;
  }

  .code-c-row {
    margin-bottom: 0;
  }
}
