.code-c-applicant-challenge-mini {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px dotted $color-border-gray;

  .code-c-applicant-challenge-mini__left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;

    .code-c-applicant-challenge-mini__left__number {
      flex-basis: 2rem;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: right;
    }

    .code-c-applicant-challenge-mini__left__title {
      flex-grow: 1;
      margin-left: 0.5rem;
    }
  }

  .code-c-applicant-challenge-mini__right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    > *:not(:last-child) {
      flex-grow: 1;
      margin-right: 0.5rem;
    }

    .code-c-applicant-challenge-mini__right__time {
      flex-basis: 7rem;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &.is-small {
    .code-c-applicant-challenge-mini__left {
      font-size: unset;
      font-weight: normal;
    }
  }
}
