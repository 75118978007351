.bg-primary-900 {
  color: $color-white;
  background-color: $color-primary-900;
}

.bg-primary-800 {
  color: $color-white;
  background-color: $color-primary-800;
}

.bg-primary-700 {
  color: $color-white;
  background-color: $color-primary-700;
}

.bg-primary-600 {
  color: $color-white;
  background-color: $color-primary-600;
}

.bg-primary-500 {
  background-color: $color-primary-500;
}

.bg-primary-400 {
  background-color: $color-primary-400;
}

.bg-primary-300 {
  background-color: $color-primary-300;
}

.bg-primary-200 {
  background-color: $color-primary-200;
}

.bg-primary-100 {
  background-color: $color-primary-100;
}

.bg-secondary-900 {
  color: $color-white;
  background-color: $color-secondary-900;
}

.bg-secondary-800 {
  color: $color-white;
  background-color: $color-secondary-800;
}

.bg-secondary-700 {
  color: $color-white;
  background-color: $color-secondary-700;
}

.bg-secondary-600 {
  color: $color-white;
  background-color: $color-secondary-600;
}

.bg-secondary-500 {
  background-color: $color-secondary-500;
}

.bg-secondary-400 {
  background-color: $color-secondary-400;
}

.bg-secondary-300 {
  background-color: $color-secondary-300;
}

.bg-secondary-200 {
  background-color: $color-secondary-200;
}

.bg-secondary-100 {
  background-color: $color-secondary-100;
}


.bg-neutral-900 {
  color: $color-white;
  background-color: $color-neutral-900;
}

.bg-neutral-800 {
  color: $color-white;
  background-color: $color-neutral-800;
}

.bg-neutral-700 {
  color: $color-white;
  background-color: $color-neutral-700;
}

.bg-neutral-600 {
  color: $color-white;
  background-color: $color-neutral-600;
}

.bg-neutral-500 {
  background-color: $color-neutral-500;
}

.bg-neutral-400 {
  background-color: $color-neutral-400;
}

.bg-neutral-300 {
  background-color: $color-neutral-300;
}

.bg-neutral-200 {
  background-color: $color-neutral-200;
}

.bg-neutral-100 {
  background-color: $color-neutral-100;
}

.bg-gray-900 {
  color: $color-white;
  background-color: $color-gray-900;
}

.bg-gray-800 {
  color: $color-white;
  background-color: $color-gray-800;
}

.bg-gray-700 {
  color: $color-white;
  background-color: $color-gray-700;
}

.bg-gray-600 {
  color: $color-white;
  background-color: $color-gray-600;
}

.bg-gray-500 {
  background-color: $color-gray-500;
}

.bg-gray-400 {
  background-color: $color-gray-400;
}

.bg-gray-300 {
  background-color: $color-gray-300;
}

.bg-gray-200 {
  background-color: $color-gray-200;
}

.bg-gray-100 {
  background-color: $color-gray-100;
}

.bg-error-900 {
  color: $color-white;
  background-color: $color-error-900;
}

.bg-error-800 {
  color: $color-white;
  background-color: $color-error-800;
}

.bg-error-700 {
  color: $color-white;
  background-color: $color-error-700;
}

.bg-error-600 {
  color: $color-white;
  background-color: $color-error-600;
}

.bg-error-500 {
  background-color: $color-error-500;
}

.bg-error-300 {
  background-color: $color-error-300;
}


.bg-error-100 {
  background-color: $color-error-100;
}


.bg-warning-900 {
  color: $color-white;
  background-color: $color-warning-900;
}

.bg-warning-800 {
  color: $color-white;
  background-color: $color-warning-800;
}

.bg-warning-700 {
  color: $color-white;
  background-color: $color-warning-700;
}

.bg-warning-600 {
  color: $color-white;
  background-color: $color-warning-600;
}

.bg-warning-500 {
  background-color: $color-warning-500;
}

.bg-warning-300 {
  background-color: $color-warning-300;
}


.bg-warning-100 {
  background-color: $color-warning-100;
}


.bg-success-900 {
  color: $color-white;
  background-color: $color-success-900;
}

.bg-success-800 {
  color: $color-white;
  background-color: $color-success-800;
}

.bg-success-700 {
  color: $color-white;
  background-color: $color-success-700;
}

.bg-success-600 {
  color: $color-white;
  background-color: $color-success-600;
}

.bg-success-500 {
  background-color: $color-success-500;
}

.bg-success-300 {
  background-color: $color-success-300;
}


.bg-success-100 {
  background-color: $color-success-100;
}
