.code-c-contents-search-form {
  padding: 1.5rem;

  .code-c-form-group:not(:last-child) {
    margin-bottom: 0.75rem !important;
  }

  .code-c-column {
    padding: 0;
  }

  .code-c-contents-search-form__filter {
    margin-top: 1em;

    .code-c-more-link {
      color: $color-link;
    }

    .code-c-more-link:hover {
      color: unset;
      background-color: unset;
    }
  }

  .code-c-contents-search-form__keyword-hint {
    @include fontSize("xsmall");

    margin-top: 0.25rem;
    color: $color-gray-dark;
  }

  .code-c-contents-search-form__radio {
    position: relative;
    padding: 0.25em 0.75em;
    font-size: 14px;
    word-break: break-all;
  }
}
