.code-deliver-email__confirm__mail {
  padding: 1rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  user-select: none;
  border: 1px solid $color-gray;
  border-radius: 3px;
}

.code-deliver-email__text {
  float: right;
}
