.code-c-banner {
  display: inline-flex;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  color: $color-gray-800;
  border-radius: 0.25rem;

  .icon {
    width: 1rem;
    height: 21px;
  }

  &.is-success {
    background-color: $color-success-100;

    .icon {
      color: $color-success-500;
    }

    a,
    a > .icon {
      font-weight: bold;
      color: $color-success-700;
    }
  }

  &.is-warning {
    background-color: $color-warning-100;

    .icon {
      color: $color-warning-500;
    }

    a,
    a > .icon {
      font-weight: bold;
      color: $color-warning-700;
    }
  }

  &.is-warning-strong {
    background-color: $color-warning-100;

    .icon {
      color: $color-warning-500;
    }

    a,
    a > .icon {
      font-weight: bold;
      color: $color-warning-700;
    }
  }

  &.is-warning-gray {
    background-color: $color-neutral-100;

    .icon {
      color: $color-neutral-700;
    }

    a,
    a > .icon {
      font-weight: bold;
      color: $color-neutral-700;
    }
  }

  &.is-error {
    background-color: $color-error-100;

    .icon {
      color: $color-error-500;
    }

    a,
    a > .icon {
      font-weight: bold;
      color: $color-error-700;
    }
  }

  &.is-info {
    background-color: $color-neutral-200;

    .icon {
      color: $color-neutral-600;
    }

    a,
    a > .icon {
      font-weight: bold;
      color: $color-neutral-800;
    }
  }

  &.is-transparent {
    gap: 0.25rem;
    padding: 0;
    background-color: transparent;
  }
}
