.code-supported-programming-languages-link {
  > span {
    display: inline-flex;
    gap: 0.25rem;
  }

  .code-c-icon {
    width: unset;
    height: unset;
    font-size: 0.7em;
    color: $color-link;
  }

  a {
    text-decoration: underline;
  }
}
