.code-submission-detail-score-chart-section {
  .tag-container {
    display: flex;
    gap: 0.6275rem;

    .tag-container__sample-tag {
      color: $color-white;
      background: $color-gray-500;
    }
  }

  .applicant-name {
    @include text-heading2;

    margin-bottom: 0.25rem;
    line-height: 1.75rem;
    word-break: break-word;

    small {
      @include text-md;

      margin-left: 0.25rem;
      color: $color-gray-600;
    }
  }

  .applicant-metadata {
    @include text-sm;

    color: $color-gray-600;

    .applicant-metadata__item {
      &:not(:last-child) {
        padding-right: 0.25rem;
      }

      &:not(:first-child) {
        padding-left: 0.25rem;
        border-left: 1px solid $color-gray-100;
      }

      .language-tag {
        height: 1.25rem;
      }
    }
  }

  .main-score-container {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    margin-top: 1rem;

    @include mq-max(1021) {
      flex-direction: column;
    }

    @media print {
      flex-direction: column;
    }
  }

  .multiple-exam-banner {
    margin-top: 1rem;

    .banner-link {
      cursor: pointer;

      span {
        font-weight: bold;
        color: $color-warning-700;
        text-decoration: underline;
        text-wrap: nowrap;
      }
    }
  }
}
