.code-c-rich-textarea {
  position: relative;
  border: 1px solid $color-border-gray;

  .code-c-rich-textarea__header {
    padding: 0.5rem;
    color: $color-gray-dark;
    text-align: right;
    background: $color-gray-super-light;
    border-bottom: 1px solid $color-border-gray;

    .input-group {
      display: inline-block;
    }

    .input-group:not(:last-child) {
      margin-right: 1rem;
    }

    a {
      color: $color-gray-dark;
    }

    a:hover {
      color: $color-gray;
      text-decoration: none;
    }
  }

  .code-c-textarea {
    padding: 0.5rem;
  }
}

.code-c-rich-textarea__custom-actions {
  margin-top: 1.875rem;
}
