.code-c-difficulty-tag {
  display: inline-block;

  .code-c-difficulty-tag__1 {
    color: $color-white;
    background: $color-green-500;
  }

  .code-c-difficulty-tag__2 {
    color: $color-white;
    background: $color-yellow-500;
  }

  .code-c-difficulty-tag__3 {
    color: $color-white;
    background: $color-red-500;
  }
}
