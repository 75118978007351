.code-exam-create__settings {
  .code-c-form-group {
    max-width: 61rem; // Override default max-width from the form-group
  }

  .code-exam-create__settings__publish-status {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;

    > div {
      flex: 1;
    }

  }
}
