.code-c-markdown {
  &.markdown-body {
    textarea.fib {
      width: 100%;
      height: 200px;
      min-height: 200px;
      margin-top: 1rem;
    }

    input.fib {
      width: 3rem;
      margin: 0.1em 0.5em;
      font-family: $font-family-base;
      line-height: 1.5;
      color: $color-text;
      text-align: center;
      background: white;
      border: 1px solid $color-text-vivid;
    }
  }

  ul {
    padding-left: 2em;
    margin: 0.5rem 0;
    list-style: disc;

    ul {
      list-style-type: circle;

      ul {
        list-style-type: square;
      }
    }
  }

  ol {
    padding-left: 2em;
    margin: 0.5rem 0;
    list-style: decimal;

    ol {
      list-style-type: lower-roman;

      ol {
        list-style-type: lower-latin;
      }
    }
  }

  p {
    white-space: pre-wrap;
  }
}
