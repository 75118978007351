.code-c-history-graph-tooltip {
  padding: 1rem;
  background: $color-gray-super-light;
  border: 1px solid $color-border-gray;
  border-radius: 12px;

  &.has-error {
    color: $color-text !important;
    border: 1px solid $color-red-500;

    .code-c-tag {
      color: $color-red !important;
      background: $color-red-100;
    }
  }

  .code-c-history-graph-tooltip__row {
    th {
      @include fontSize("small");

      padding: 0.25rem 1rem;
      color: $color-gray-600;
    }

    td {
      padding: 0.25rem 1rem;
      font-weight: bold;
      overflow-wrap: break-all;

      @include fontSize("large");
    }
  }
}