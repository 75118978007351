.code-editor-exam-detail {
  .code-editor-exam-detail__container {
    display: flex;
    max-width: $applicant-container-max-width;
    padding: 3rem 1rem;
    margin: 0 auto;
  }

  .code-editor-exam-detail__main {
    width: 75%;
    padding-right: 2rem;

    .code-c-applicant-exam-header {
      margin-bottom: 1rem;
    }
  }

  .code-editor-exam-detail__aside {
    width: 25%;
  }

  h2 {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .code-editor-exam-detail__section {
    margin-bottom: 2.5rem;

    .code-c-form-group {
      max-width: unset;
    }
  }

  .code-editor-exam-detail__sample-delivery-note {
    position: fixed;
    top: #{$header-height + 0.5rem};
    left: calc(50% + #{$menu-width / 2});
    z-index: $z-index-header + 1;
    width: calc(100% - 8rem);
    max-width: 40rem;
    padding: 0.5rem;
    color: $color-white;
    text-align: center;
    background: $color-error;
    border-radius: 3px;
    opacity: 0.75;
    transform: translateX(-50%);
  }

  .code-editor-exam-detail__sub-list {
    margin-left: 2rem;
  }

  .code-editor-exam-detail__cautions {
    h2 {
      margin-bottom: 1.5rem;
    }

    .code-editor-exam-detail__cautions__sub-container {
      margin-bottom: 2.5rem;
      margin-left: 1.5rem;

      > div {
        margin-bottom: 1.5rem;
      }
    }

    .code-editor-exam-detail__cautions__environment {
      margin-left: 1.5rem;

      ul {
        margin-bottom: 1.5rem;
      }
    }

    ul {
      list-style-type: disc;
    }

    li {
      margin-left: 1.25rem;
    }

    .code-editor-exam-detail__cautions__title {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .code-editor-exam-detail__environment {
    .code-editor-exam-detail__environment-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      background: $color-error-background;
      border: 1px solid $color-error;
    }

    .code-editor-exam-detail__environment-left {
      display: flex;
      align-items: center;
    }

    .code-editor-exam-detail__environment-icon {
      margin-right: 0.5rem;
      font-size: 2rem;
      color: $color-error;
    }

    &.all-green {
      .code-editor-exam-detail__environment-container {
        background: transparentize($color-success, 0.9);
        border-color: $color-success;
      }

      .code-editor-exam-detail__environment-icon {
        color: $color-success;
      }
    }
  }

  .code-editor-exam-detail__entry-form {
    padding: 1rem 1rem 1.75rem;
    margin-bottom: 3rem;
    background: $color-gray-super-light;

    .code-editor-exam-detail__update-form {
      margin-top: 1.875rem;
    }

    .code-editor-exam-detail__general-form-error-banner {
      margin-top: 1.875rem;
    }
  }

  .code-editor-exam-detail__start-button {
    margin-top: 3rem;
  }

  .code-editor-exam-detail__expired {
    position: relative;
    padding: 1rem 1rem 1rem 3.5rem;
    margin-bottom: 3rem;
    font-weight: bold;
    color: $color-error;
    text-decoration: underline;
    background-color: $color-error-background;
    border: 1px solid $color-error;

    .stop-icon {
      position: absolute;
      top: 0.75rem;
      left: 1rem;
      width: 32px;
      height: 32px;
      background: transparent url("./assets/images/stop-mark.svg") no-repeat;
    }
  }

  .code-editor-exam-detail__deadline-container {
    color: $color-error;

    .code-editor-exam-detail__deadline {
      font-size: 1rem;

      strong {
        color: $color-error;
      }
    }
  }
}
