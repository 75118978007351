.code-admin-project-migrate {
  .code-admin-project-migrate__download-button {
    min-width: auto;
  }

  .download-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
