.code-c-custom-form-configure-box {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  .code-c-custom-form-configure-box__actions {
    display: flex;
    column-gap: 0.5rem;
  }

  .code-c-custom-form-configure-box__table {
    margin-bottom: 2rem;

    .code-c-draggable-item {
      margin-bottom: -1px;
      border: 1px solid $color-border-gray;
    }
  }
}
