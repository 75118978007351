.code-c-empty-state {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 10rem;
  color: $color-neutral-500;
  background-color: $color-neutral-100;

  .icon {
    color: $color-neutral-200;
  }

  &.code-exam-dashboard__empty-state__applicant-funnel {
    height: 17rem;
  }

  &.code-exam-dashboard__empty-state__score-distribution {
    height: 16rem;
  }

  &.code-exam-dashboard__empty-state__challenge-analysis {
    height: 100%;
    min-height: 30rem;
  }
}
