.code-programming-language-panel-new {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.75rem;
  padding: 0;
  font-size: 0.875rem;

  .code-programming-language-panel__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.875rem;

    select {
      min-width: 20rem;
      font-size: 0.875rem;
    }
  }
}
