.code-c-question {
  padding: 1rem;
  word-break: break-word;
  overflow-wrap: break-word;

  .code-c-question__status_tag {
    margin-bottom: 0.5rem;
  }

  &.has-error {
    border-left: 5px solid $color-error;
  }

  .code-c-question__header {
    margin-bottom: 1rem;
  }

  .code-c-question__title {
    margin-bottom: 0.5em;
    font-size: 1.1618em;
  }

  .code-c-question__body {
    .is-correct-answer {
      background: $color-primary-200;
    }

    .code-c-checkbox {
      padding: 0 0.1em;

      .code-c-checkbox__label {
        display: flex;
        align-items: flex-start;
      }

      .code-c-checkbox__checkbox {
        margin-top: 0.4em;
      }

      // NOTE: Safari makes weird line-break before the checkbox
      .code-c-markdown::before {
        content: unset;
      }
    }

    ol.code-c-question__mcq_container {
      margin-left: 2rem;

      ::marker {
        font-family: Menlo, Monaco, "Courier New", monospace;
      }
    }

    .code-c-row {
      line-height: 1.5rem;

      &.is-correct-answer:hover {
        background: $color-primary-200 !important;
      }
    }

    .correct-column,
    .answer-column {
      width: 5rem;
    }

    .blank-column {
      width: 4rem;
    }

    dt {
      margin-bottom: 1rem;
    }

    dd {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: unset;
      }
    }

    .code-c-table-head tr {
      position: unset !important;
    }
  }

  .code-c-question__insights__freetext {
    width: fit-content;
  }

  .code-c-question__freetext {
    word-break: break-word;
  }

  td.code-c-column {
    padding: 0.25rem 0.75rem;
  }

  .code-c-question__fib-item {
    &.is-wrong-answer {
      background: $color-error-background;
    }
  }

  .code-c-markdown {
    // orverride track-markdown style
    textarea {
      height: 100px !important;
      min-height: 100px !important;
      margin-top: 0 !important;
      resize: none;
    }
  }

  .code-c-loading {
    position: relative;
    min-height: 200px;
  }
}
