.code-exam-list {
  .code-exam-list__loadinglist {
    position: relative;
    min-height: 300px;
    margin-bottom: -300px;
    pointer-events: none;
  }

  .code-c-sub-menu-list {
    margin-bottom: 0;
  }

  .code-exam-list-header {
    padding: 1.5rem 1.5rem 0;
  }

  .code-exam-list__table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .code-exam-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-exam-list__modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
}

.code-exam-list__keyword {
  padding-bottom: 1rem;
}

.code-exam-list__status {
  padding: 0 1.5rem;
}

.code-exam-list__filter {
  padding-top: 0;
}

.code-exam-list__badges-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
}
