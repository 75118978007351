.code-c-liveness-walkthrough {
  .modal-card-body {
    width: 40rem !important;
    min-height: 20rem;
  }

  .modal-card-head {
    z-index: 0 !important;
  }

  .code-c-walk-through__container {
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 1.5rem;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 20rem;
  }

  .amplify-select {
    padding-right: 2.5rem;
    padding-left: 0.75rem;
    font-size: 0.875rem;

    &:focus {
      border-color: $color-gray-400;
      box-shadow: none;
    }
  }

  #amplify-liveness-error-message {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
  }

  .liveness-detector-check {
    .amplify-text {
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 1.5rem;

      &:first-of-type {
        font-weight: 700 !important;
      }
    }

    > div:first-of-type {
      height: 0;
      overflow: hidden;
    }

    .amplify-liveness-hint {
      .amplify-liveness-toast--primary {
        background-color: $color-primary-500;
      }
    }

    .amplify-liveness-cancel-container {
      display: none;
    }
  }

  .amplify-button {
    @include text-lg;

    min-width: 8.5rem;
    padding: 0.375rem 0.75rem;
    color: $color-primary-900;
    cursor: pointer;
    border: none;
    border-radius: 4px;

    &.amplify-button--primary {
      --amplify-internal-button-background-color: #{$color-primary-500};
      --amplify-internal-button-disabled-background-color: #{$color-gray-100};
      --amplify-internal-button-disabled-color: #{$color-gray-600};
      --amplify-internal-button-loading-background-color: #{$color-neutral-300};

      &:hover {
        --amplify-internal-button-background-color: #{$color-primary-400};
      }

      &:active,
      &:focus {
        border: 1px solid $color-primary-600;
      }
    }

    &.amplify-button--link {
      --amplify-internal-button-background-color: transparent;
      --amplify-internal-button-color: #{$color-primary-900};
      --amplify-internal-button-disabled-background-color: transparent;
      --amplify-internal-button-disabled-color: #{$color-gray-500};
      --amplify-internal-button-loading-background-color: transparent;

      &:active,
      &:hover,
      &:focus {
        border: 1px solid $color-primary-600;
      }
    }

    &.amplify-button--loading {
      color: $color-gray-600;
      background-color: var(--amplify-internal-button-loading-background-color);
      border: none;

      &:active,
      &:hover,
      &:focus {
        color: $color-gray-600;
        background-color: var(
          --amplify-internal-button-loading-background-color
        );
        border: none;
      }
    }
  }

  .liveness-detector .amplify-button--primary:focus {
    outline: solid 2px $color-neutral-200;
    outline-offset: 2px;
    box-shadow: unset;
  }

  .amplify-liveness-camera-module {
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 22.5rem;
    background-color: $color-white;
  }

  .amplify-liveness-toast__message {
    flex-direction: column;
    font-size: 1.125rem;
    font-weight: 700;
    color: $color-gray-900;
    text-align: center;
  }

  .amplify-liveness-toast--primary {
    background-color: $color-primary-500;
  }

  .amplify-liveness-toast--primary .amplify-liveness-toast__message {
    flex-direction: column;
    color: $color-white;
    text-align: center;
  }

  .amplify-liveness-toast--error .amplify-liveness-toast__message {
    color: $color-gray-900;
  }

  .amplify-liveness-overlay {
    border: 1px solid $color-neutral-200;
  }

  .amplify-loader {
    stroke: transparentize($color-white, 0.3);
  }

  .amplify-loader circle:last-of-type {
    stroke: $color-primary;
  }

  .amplify-loader line:last-of-type {
    stroke: $color-primary;
  }

  .amplify-loader__label {
    fill: $color-neutral-900;
  }

  .amplify-liveness-match-indicator__bar::after {
    background: $color-primary-500;
    transition: $color-primary-500;
  }
}
