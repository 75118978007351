.code-admin-organization-list {
  .code-admin-organization-list__header {
    padding: 1.5rem 1.5rem 0;
  }

  .code-c-sub-menu-container-left {
    .code-c-sub-menu {
      padding: 1.5rem 1.5rem 0;
    }
  }

  .code-organization-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-c-table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .code-organization-list__icon {
    width: 18px;
    height: 18px;
  }

  i.archive {
    background: transparent url("./assets/images/icon-archive.svg") no-repeat;
    background-size: contain;
  }

  i.unarchive {
    background: transparent url("./assets/images/icon-unarchive.svg") no-repeat;
    background-size: contain;
  }

  .code-admin-actions {
    text-align: left;
    white-space: nowrap;
  }

  .code-c-icon {
    vertical-align: middle;
  }

  .code-admin-organization-list__verify-org {
    color: $color-white;
    background: $color-gray-dark;
  }

  .code-admin-organization-list__sharing-group {
    display: flex;
    column-gap: 0.5rem;
  }

  .code-admin-organization-list__name {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
