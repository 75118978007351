.code-submission-detail__floating-button-group {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: $z-index-header; // Above the header but below the modal
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  max-height: 3rem;

  .code-submission-detail__floating-button {
    height: 3rem;
    font-size: 1rem;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.18);
    opacity: 0;
    animation: fadeUp 0.375s forwards;

    &.is-stale {
      > .code-c-icon {
        color: $color-warning;
      }
    }

    .code-c-warning-icon {
      width: 2em;
    }
  }
}
