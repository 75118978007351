.code-c--floating-ui-tooltip {
  box-sizing: border-box;
  width: max-content;
  max-width: calc(100vw - 2rem);
  padding: 0.5em 1em;
  font-weight: initial;
  color: $color-white;
  text-align: initial;
  overflow-wrap: anywhere;
  background: $color-black;
  border-radius: 2px;


  @include fontSize("xsmall");
}

.code-c--floating-ui-tooltip-content {
  &.small {
    max-width: 5rem;
  }

  &.medium {
    max-width: 10rem;
  }

  &.large {
    max-width: 16rem;
  }

  &.xlarge {
    max-width: 24rem;
  }
}

.code-c-tooltip {
  max-width: calc(100vw - 2rem);
  padding: 0.5em 1em;
  font-weight: initial;
  color: $color-white;
  text-align: initial;
  overflow-wrap: anywhere;
  background: $color-black;
  border-radius: 2px;

  @include fontSize("xsmall");

  .code-c-tooltip__arrow,
  .code-c-tooltip__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .code-c-tooltip__arrow {
    visibility: hidden;
  }

  .code-c-tooltip__arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }

  &[data-popper-placement^="top"] > .code-c-tooltip__arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > .code-c-tooltip__arrow {
    top: -4px;
  }

  &[data-popper-placement^="left"] > .code-c-tooltip__arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > .code-c-tooltip__arrow {
    left: -4px;
  }

  &[data-tooltip-max-size="small"] {
    max-width: 5rem;
  }

  &[data-tooltip-max-size="medium"] {
    max-width: 10rem;
  }

  &[data-tooltip-max-size="large"] {
    max-width: 16rem;
  }

  &[data-tooltip-max-size="xlarge"] {
    max-width: 24rem;
  }
}

.code-c-tooltip-wrapper {
  display: inline-flex;
  align-items: center;
}
