.code-challenge-stats {
  .code-challenge-stats__score-distribution {
    display: flex;
    align-items: center;
  }

  .code-challenge-stats__logarithmic_scale {
    margin-left: 0.5rem;
    font-size: 0.8125rem;
    font-weight: normal;
  }

  .code-challenge-stats__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15rem;
    background: transparentize($color-gray-super-dark, 0.85);
  }
}
