.code-setting-contract {
  padding: 1.5rem;

  .code-setting__contract-header {
    margin-bottom: 0.75rem;
  }

  .code-setting-contract__block {
    display: grid;
    grid-template-columns: 1fr max-content 18rem;
    gap: 1.25rem;

    .code-setting-contract__block__divider {
      height: 100%;
      margin-top: 0.75rem;
    }

    @include mq(hd) {
      grid-template-columns: 1fr;

      .code-setting-contract__block__divider {
        display: none;
      }
    }

    // fixes content from expanding parent
    > div {
      min-width: 0;
    }

    .code-setting-contract__block__sub-title {
      &.code-setting-contract__sticky {
        position: sticky;
        top: 0;
        z-index: $z-index-message-bar;
      }

      margin-bottom: 1.5rem;
      font-size: 1.25rem;
      font-weight: bold;
      color: $color-black;
      background-color: white;
      border-bottom: 1px solid $color-border-gray;

      > p {
        padding: 0.75rem 0;
      }
    }

    .code-setting-contract__block__content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-bottom: 1.5rem;
      overflow: hidden;
    }
  }

  .code-setting-contract__input-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 0;

    .code-c-input {
      width: 20rem;
    }

    .code-setting-contract__input {
      font-size: 0.9rem;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .no-contract {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background: $color-gray-light;
  }

  .code-setting-contract__no-delivered {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: $color-gray-super-light;
    border-radius: 0.5rem;
  }

  .code-setting-contract__current-title {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-end;

    .code-setting-contract__contract-stats {
      display: flex;
      gap: 0.5rem;
      align-items: baseline;
    }

    .code-setting-contract__current-title__total-usage {
      font-size: 1.75rem;
      font-weight: bold;
    }

    .code-setting-contract__current-title__warning {
      color: $color-red-500;
    }
  }

  .code-setting-contract__title {
    .code-c-left-block {
      flex-shrink: 1;
    }

    .code-setting-contract__title__name {
      font-size: 1rem;
      font-weight: bold;
    }

    .code-setting-contract__title__dates {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      margin-right: 5px;
      color: $color-gray;
    }

    .code-setting-contract__upcoming-title {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .code-setting-contract__upcoming-title__text {
        font-size: 0.70rem;
      }

      .code-setting-contract__upcoming-title__sub-text {
        font-size: 1.25rem;
      }
    }
  }

  .code-setting-contract__usage {
    display: flex;
    gap: 1rem;

    > div {
      min-width: 0;
    }

    .code-setting-contract__usage-chart-title {
      font-weight: bold;
    }

    .code-setting-contract__usage__donut-chart {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .code-setting-contract__usage__bar-chart {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 1rem;
    }
  }

  .code-setting-contract__tier-tag {
    margin-left: 1rem;
  }
}
