.code-c-rich-markdown {
  display: flex;
  flex-direction: column;
  width: 100%;

  .code-c-rich-markdown__header {
    display: flex;
    flex-basis: 2.5rem;
    flex-shrink: 0;
    height: 2.5rem;
    white-space: nowrap;
    background: $color-gray-super-light;
    border-top: 1px solid $color-border-gray;
    border-right: 1px solid $color-border-gray;
    border-left: 1px solid $color-border-gray;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .code-c-rich-markdown__header__tabs {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
        padding: 0 1.5rem 0 1rem;
        border-top: 0;
      }
    }
  }

  .code-c-rich-markdown__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .code-c-markdown {
      flex-grow: 1;
      padding: 1rem;
      overflow-y: auto;
    }

    .code-c-textarea {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .textarea {
        flex-grow: 1;
        height: auto !important;
        max-height: unset !important;
        padding: 1rem;
        resize: none;
        border-radius: 0 0 3px 3px;
      }

      .code-c-validation-message {
        display: none;
      }
    }
  }

  &.on-edit {
    .code-c-rich-markdown__header__tabs__edit {
      font-weight: bold;
      background: $color-white;
      border-right: 1px solid $color-border-gray;
    }
  }

  &.on-preview {
    .code-c-rich-markdown__body {
      min-height: 3rem;
      background: $color-white;
      border: 1px solid $color-border-gray;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .code-c-rich-markdown__header__tabs__preview {
      font-weight: bold;
      background: $color-white;
      border-right: 1px solid $color-border-gray;
      border-left: 1px solid $color-border-gray;
    }
  }

  &.is-preview {
    .code-c-rich-markdown__body {
      min-height: 3rem;
      background: $color-white;
      border: 1px solid $color-border-gray;
      border-radius: 3px;
    }
  }
}
