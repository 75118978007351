.code-question-new {
  .code-question-new__preview {
    overflow-x: scroll;
  }

  .code-question-new__select {
    .code-question-new__select-label {
      margin-top: 3rem;
      text-align: center;
    }

    .code-question-new__select-buttons {
      display: flex;
      justify-content: center;

      button {
        margin: 2rem;
      }
    }
  }
}
