.code-exam-report-challenge-detail {
  margin: 1.5rem;

  .challenge-title {
    width: 100%;
    min-width: 400px;
    padding: 0.5rem;
    font-weight: bold;
    color: $color-gray-dark;
    word-break: break-all;
    background: $color-gray-super-light;
    border: 1px solid $color-border-gray;

    .challenge-title__status {
      margin-left: 1rem;
      font-weight: normal;
    }
  }

  .challenge-overview {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid $color-border-gray;
  }

  .challenge-stats,
  .challenge-graph {
    flex: 1;
    flex-basis: 400px;
    min-width: 400px;
    border: 1px solid $color-border-gray;
    border-top: none;
    border-left: none;
  }

  .challenge-stats {
    table {
      width: 100%;
      margin-bottom: -1px;
      table-layout: fixed;
      border-collapse: collapse;

      td {
        padding: 0.75rem;
        border: 1px solid $color-border-gray;
        border-top: none;
        border-right: none;
      }
    }

    .challenge-stats__title {
      font-weight: bold;
      word-break: keep-all;
      background: $color-gray-super-light;
    }
  }

  .challenge-details {
    width: 100%;
    min-width: 400px;
    border: 1px solid $color-border-gray;
    border-top: none;

    .challenge-details__detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 2rem;

      .details-title-description {
        margin: 1rem 0;
      }

      .details-title {
        font-weight: bold;
      }

      .details-description {
      }

      .details-grade-score {
        min-width: 100px;
        margin-left: 2rem;
        font-weight: 500;
        text-align: center;
      }

      .details-grade {
        font-size: 3rem;
        line-height: 3rem;
      }

      .details-score {
        font-weight: bold;
      }
    }

    .challenge-details__detail:not(:last-child) {
      border-bottom: 1px solid $color-border-gray;
    }
  }

  .within-exam {
    font-weight: bold;
    color: $color-report-within-exam;
  }

  .within-country {
    font-weight: bold;
    color: $color-report-within-country;
  }

  .chartjs-size-monitor-expand > div {
    width: 0;
    height: 0;
  }
}
