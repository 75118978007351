.code-submission-cheathelp-modal {
  font-size: 1rem;
  line-height: 1.25rem;

  .code-submission-cheathelp-modal__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .code-submission-cheathelp-modal__heading {
    margin-bottom: 0.25rem;
    font-weight: bold;
  }

  .code-submission-cheathelp-modal__description {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
  }

  .code-submission-cheathelp-modal__highlight {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    color: $color-warning-700;
  }

  .code-submission-cheathelp-modal__icon {
    font-size: 0.875rem;
  }

  .code-submission-cheathelp-modal__icon-base-small {
    width: 0.875rem;
    height: 0.875rem;
  }
}
