.code-deliver-email {
  margin-bottom: 3rem;

  .code-deliver-email__language {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .code-deliver-email__schedule-check {
    display: inline-flex;

    .code-c-checkbox__label {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .code-c-checkbox__checkbox {
        margin-top: 0;
        margin-bottom: 0;
      }

      .code-c-checkbox__label__text {
        display: flex;
        align-items: center;
      }
    }
  }

  .code-deliver-email__schedule-box {
    max-width: 30rem;
    padding: 1rem;
    border: 1px solid $color-border-gray;
    border-radius: 3px;
  }

  .code-deliver-email__preview {
    $preview-min-height: 118px; // auto calculated by Textarea component.

    .code-deliver-email__preview__input {
      width: 50%;

      .code-c-textarea {
        width: 100%;
      }
    }

    .code-deliver-email__preview__window {
      width: 50%;
      margin-left: 1rem;

      .code-deliver-email__preview__window__block {
        min-height: $preview-min-height;
        padding: 1rem;
        overflow: auto;
        word-break: break-all;
        white-space: pre-wrap;
        user-select: none;
        background: $color-gray-light;
        border: 1px solid $color-gray;
        border-radius: 3px;
      }

      .code-deliver-email__preview__window__text {
        margin-top: 0.75rem;
        margin-left: 0.5rem;
      }
    }
  }
}

.code-deliver-email__applicant-type-wrapper {
  margin-bottom: 0.6875rem;
}

.code-deliver-email__applicant-counter {
  flex: 1 1 auto;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: $color-gray-900;
  text-align: right;
}
