.code-c-footer {
  height: $footer-height;
  padding: 0 1rem;
  line-height: $footer-height;
  color: $color-gray-dark;
  background: $footer-background-color;

  .code-c-footer__container {
    display: flex;
    width: 100%;
    max-width: $hero-tile-max-width;
    margin: 0 auto;
  }

  .code-c-footer__left {
    display: flex;
    flex-grow: 1;
  }

  .code-c-footer__right {
    flex-grow: 1;
    text-align: right;
  }

  .code-c-footer__link {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }

    a {
      color: $color-gray-dark;
    }
  }
}
