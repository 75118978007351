.code-c-member-title-column {
  display: flex;

  .code-c-member-title-column__container {
    margin-left: 1rem;
  }

  .code-c-member-title-column__info {
    margin-bottom: 0.5em;
    color: $color-gray;
  }
}
