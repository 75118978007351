.code-submission-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - #{$header-height} - #{$breadcrumb-height} - 5rem);
  overflow: hidden;

  .code-submission-list__header {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    .code-submission-list__header-left {
      display: flex;
      column-gap: 1.5rem;
      align-items: center;

      .subtitle {
        margin-bottom: 0;
      }
    }

    .code-submission-list__header-right {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }

    .code-submission-list__search {
      > input {
        min-width: 20rem;
      }
    }
  }

  .code-submission-list__tabs {
    margin-bottom: 1rem;
  }

  .code-submission-list__header-status-block {
    align-items: flex-start;
  }

  .code-submission-list__header-status {
    display: flex;

    .code-c-checkbox:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .code-submission-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    background: $color-white;
  }

  .code-c-table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 11;
      }
    }
  }

  .code-submission-list__table {
    margin-top: 0.5rem;

    .code-submission-list-table-row {
      &.is-drawer-open {
        td {
          background: $color-primary-100;
          border-top: 1px solid $color-primary;
          border-bottom: 1px solid $color-primary;

          &:first-child {
            border-left: 1px solid $color-primary;
          }
        }
      }
    }
  }

  .code-submission-list__review-panel {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: $z-index-loading + 1;
    width: 100%;
    max-width: 35rem;
    background: $color-white;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .code-submission-list__overlay {
    background: transparentize($color-gray-super-dark, 0.5);
  }

  .code-submission-list_evaluation-modal {
    z-index: $z-index-loading + 2;
  }
}
