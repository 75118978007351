.code-c-custom-form-definition-display {
  display: flex;
  column-gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem;

  .code-c-custom-form-definition-display__container {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
  }

  .code-c-custom-form-definition-display__buttons {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
  }

  .code-c-custom-form-definition-display__title {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    word-break: break-word;
  }
}
