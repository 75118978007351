.code-c-switch-panel-content {
  display: flex;
  flex-direction: column;
  gap: 0.1875rem; // 3px

  .warning-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
  }

  .code-c-switch-panel-content__icon {
    display: inline-flex;
    width: 1.5rem;
    height: 1.5rem;

    & > :first-child {
      width: 1.5rem;

      // Bottom of stack
      font-size: 1.5rem;
      color: $color-primary-200;
    }

    & > :last-child {
      // Top of stack
      font-size: 0.75rem;
      color: $color-primary;
    }

    @media screen and (width <= 414px) {
      // Override unwanted icon sizing on mobile that affects layout of icon and following text
      width: 1.5rem !important;
    }
  }

  .code-c-switch-panel-content__icon-helpbutton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    color: $color-primary-900;
    cursor: pointer;
    border-radius: 0.25rem;

    &:hover {
      color: $color-neutral-900;
      background-color: $color-neutral-100;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    }
  }

  .code-c-switch-panel-content__title {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
    color: $color-primary-900;
    word-break: break-word;
  }

  .code-c-switch-panel-content__text {
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    color: $color-gray-700;
    word-break: break-word;
  }
}

.code-c-switch-panel__label.is-readonly .code-c-switch-panel-content {
  .code-c-switch-panel-content__title {
    color: $color-gray-600;
  }

  .code-c-switch-panel-content__text {
    color: $color-gray-600;
  }
}
