.code-c-fade {
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.show {
    visibility: visible;
  }

  &.active {
    opacity: 1;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
