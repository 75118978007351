.code-c-donut-chart {
  svg {
    font-family: $font-family-base;
  }

  .c3-chart-arcs-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .c3-target-black {
    text {
      font-size: 1rem;
      fill: $color-black;
    }
  }

  .code-c-donut-chart__legend {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    justify-content: center;

    // only allow blur if hovered label does not have a child with "toggled" class property
    &:has(.code-c-donut-chart__legend__label:hover:not(.code-c-donut-chart__legend__toggled)) {
      &:hover {
        .code-c-donut-chart__legend__label:not(:hover) {
          opacity: .5;
        }
      }
    }

    .code-c-donut-chart__legend__toggled {
      opacity: .5;
    }

    .code-c-donut-chart__legend__label {
      display: flex;
      gap: 0.35rem;
      font-size: 0.75rem;
      user-select: none;

      &:hover {
        cursor: pointer;
      }

      .code-c-donut-chart__legend__color {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-top: 3px;
      }
    }
  }
}
