.code-c-alert {
  position: relative;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0.75rem;
  font-weight: bold;

  &.is-warning {
    color: $color-warning;
    background-color: $color-warning-background;
    border: 1px solid $color-warning;
  }

  &.is-error {
    color: $color-error;
    background: $color-error-background;
    border: 1px solid $color-error;
  }
}
