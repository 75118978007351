.code-admin-signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;

  .code-c-validation-message {
    margin: 0 0 1.5em;
  }
}
