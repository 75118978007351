.code-c-toastr {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .code-c-toastr__toast {
    display: flex;
    padding: 0.5rem;
    border-radius: 3px;
  }

  .code-c-toastr__info {
    color: $color-white;
    background: $color-gray-super-dark;
  }

  .code-c-toastr__success {
    color: $color-white;
    background: $color-success;
  }

  .code-c-toastr__warning {
    color: $color-white;
    background: $color-warning;
  }

  .code-c-toastr__error {
    color: $color-white;
    background: $color-error;
  }
}

.Toastify__toast-body {
  word-break: break-all;
}

.Toastify__close-button {
  color: $color-white;
}
