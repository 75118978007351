.code-c-submission-result-action-log__pasted-card {
  section {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    .code-editor-appeal-comment__codeblock-code {
      flex-grow: 1;
      padding: 1rem !important;
    }
  }
}
