.code-editor-exam-detail-caution {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  text-align: center;

  .icon {
    width: 164px;
    height: 164px;
    margin-bottom: 1rem;
    background-repeat: no-repeat;

    &.icon-0 {
      background-image: url("./assets/images/no_cheat.svg");
    }

    &.icon-1 {
      background-image: url("./assets/images/no_share.svg");
    }

    &.icon-2 {
      background-image: url("./assets/images/no_personal_data.svg");
    }
  }

  .title-container {
    margin-bottom: 0.5rem;
  }

  .title {
    font-size: 1rem;
    font-weight: 700;
  }

  .text {
    font-size: 0.875rem;
    font-weight: 400;
  }
}
