.code-c-header-logo {
  display: flex;
  align-items: center;

  .code-c-header-logo__track-logo {
    width: 100px;
    height: 29px;
    background-image: url("./assets/images/logo.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }

  .code-c-header-logo__track-logo-mini {
    width: 32px;
    height: 32px;
    background-image: url("./assets/images/logo_mini.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }

  .code-c-header-logo__name {
    max-width: 33rem;
    margin-left: 0.5em;
    overflow: hidden;
    font-size: larger;
    color: $color-text;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
