.code-c-applicant-challenge-list {
  padding: 1rem 1.25rem;
  background: $color-white;
  border: 1px solid $color-border-gray;

  .code-c-applicant-challenge-list__header {
    display: flex;
    align-items: flex-end;

    .code-c-applicant-challenge-list__total {
      margin-right: 1rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .code-c-applicant-challenge-list__challenges {
    margin: 1rem 0;
  }

  .code-c-applicant-challenge-list__optional-sub-title {
    font-size: 0.8rem;
    font-weight: normal;
  }

  .code-c-applicant-challenge-list__optional-container {
    margin-left: 2rem;
  }
}
