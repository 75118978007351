.code-editor-exam-detail__fairness-form {
  &__label {
    font-size: 1rem;
  }

  &__textarea-container {
    margin-top: 2rem;
  }

  &__error {
    color: $color-error;
  }
}

.code-c-modal {
  .code-editor-exam-detail__fairness-form {
  &__textarea {
    max-height: calc(1.5rem * 10);
    overflow: 'auto';
    resize: 'none';
  }
  }
}