.code-c-linked-challenge-item {
  z-index: $z-index-account-menu;
  display: flex;
  width: 100%;
  padding: 0.25rem;
  border-collapse: collapse;
  background: $white;
  border: 1px solid $color-border-gray;
  border-top: 0;

  &.on-clickable {
    cursor: pointer;
  }

  &.hasBorderTop {
    border: 1px solid $color-border-gray;
  }

  .code-c-linked-challenge-item__left {
    margin-left: 0.5rem;
  }

  .code-c-linked-challenge-item__right {
    margin-left: auto;

    .delete {
      margin: 0.15rem 0 0 0.4rem;
    }

    .code-c-tag {
      margin-left: 0.3rem;
    }
  }
}
