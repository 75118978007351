.code-c-collapsible-exam-challenge-column {
  td.code-c-column {
    padding-bottom: 0.5rem;
  }

  .code-c-collapsible-exam-challenge-column__header {
    .header__title {
      cursor: pointer;

      .challenge-tags {
        display: flex;
        margin-bottom: 0.25rem;

        .code-c-tag {
          margin-right: 0.5rem;
        }
      }

      .challenge-title-wrapper {
        display: flex;
        justify-content: space-between;

        .challenge-title {
          font-weight: bold;
          word-break: break-all;
        }
      }
    }
  }

  .code-c-collapsible-exam-challenge-column__body {
    border-bottom: 1px solid $color-border-gray;

    td.code-c-column {
      padding-top: 0.5rem;
      border-top: 0 !important;
    }

    .body__discription {
      padding: 1rem;
      margin-bottom: 0.5rem;
      background: $color-gray-super-light;
    }
  }

  &.is-open {
    .code-c-collapsible-exam-challenge-column__body {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    }
  }

  .code-c-collapsible-exam-challenge-column__content {
    border-bottom: 1px solid $color-border-gray;
    box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.18);

    .content-container {
      position: relative;
      min-height: 200;
      padding: 1.5rem;

      .code-c-question {
        min-width: unset;
      }
    }
  }
}
