.code-c-warning-icon {
  width: 1.3em;
  height: 1rem;
  line-height: 1rem;

  .fa-exclamation-triangle {
    color: $color-warning;

    &.warning-background {
      font-size: 1.3em;
      color: $color-white;
    }
  }

  .fa-exclamation {
    color: $color-white;
  }
}