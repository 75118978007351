.code-c-custom-form-display-old {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid $color-neutral-200;

  &:not(:last-child) {
    border-bottom: 0;
  }

  dt {
    @include text-md;

    padding: 0.5rem 1rem;
    margin-bottom: 0;
    color: $color-gray-600;
    word-break: break-word;
    background: $color-neutral-100;
  }

  dd {
    @include text-md;

    padding: 0.5rem 1rem;
    margin-bottom: 0;
    color: $color-gray-900;
    word-break: break-word;
  }
}
