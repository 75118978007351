.code-admin-contract-new {
  .modal-card {
    height: 100%;
  }

  .admin-contract-new__container {
    display: flex;
    flex-wrap: wrap;
  }

  .admin-contract-new__contract-info {
    flex: 1;
    min-width: 400px;
    padding: 1rem;
  }

  .admin-contract-new__contract-projects {
    flex: 1;
    min-width: 400px;
    padding: 1rem;
  }
}
