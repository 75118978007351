.code-c-scatter-chart {
  svg {
    font-family: $font-family-base;
  }

  .c3-xgrid-focus {
    display: none;
  }

  .c3-axis-x,
  .c3-axis-y {
    path.domain {
      stroke-width: 0;
    }
  }

  .tick {
    line {
      opacity: 0;
    }

    text {
      font-size: 0.75rem;
      fill: $color-gray-600;
    }
  }

  .c3-circle {
    opacity: 0.8 !important;
    stroke: $color-white;
    stroke-width: 1px;
  }

  .c3-ygrid {
    opacity: 0.5;
    stroke: $color-border-gray;
    stroke-dasharray: none;
  }

  .c3-axis-x-label,
  .c3-axis-y-label {
    @include text-xs;

    fill: $color-gray-600;
  }

  .c3-tooltip-container {
    pointer-events: auto !important;
  }

  .code-c-scatter-chart__tooltip {
    display: flex;
    flex-direction: column;
    min-width: 218px;
    max-width: 10rem;
    background-color: $color-white;
    border: 1px solid $color-neutral-200;
    border-radius: 0.125rem;
    box-shadow: 0 2px 16px 0 rgba(20, 20, 19, 0.16);
    opacity: 1;

    .c3-tooltip {
      box-shadow: none;
      opacity: 1;
    }

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .code-c-scatter-chart__tooltip-label {
      @include text-md;

      padding: 0.5rem 0.75rem;
      font-weight: 700;
      background-color: $color-neutral-100;
    }

    .code-c-scatter-chart__tooltip-value-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.5rem 0.75rem;

      .code-c-scatter-chart__tooltip-value {
        display: flex;
        column-gap: 1rem;
        align-items: center;
        justify-content: space-between;

        .code-c-scatter-chart__tooltip-value-label {
          @include text-md;

          color: $color-neutral-600;
        }

        .code-c-scatter-chart__tooltip-value-value {
          @include text-md;

          color: $color-neutral-900;
        }
      }
    }
  }
}
