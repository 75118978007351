.code-c-message-bar {
  display: flex;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  color: $color-success;
  background: transparentize($color-success-background, 0.1);
  border: 1px solid $color-success;
  border-radius: 3px;

  &.is-warning {
    color: $color-warning;
    background: transparentize($color-warning-background, 0.1);
    border-color: $color-warning;
  }

  &.is-error {
    color: $color-error;
    background: transparentize($color-error-background, 0.1);
    border-color: $color-error;
  }

  .code-c-message-bar__body {
    flex-grow: 1;
    min-width: 0;
    overflow-wrap: break-word;
  }

  .code-c-message-bar__delete {
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
