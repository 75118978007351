.code-member-edit {
  .modal-card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 30rem;
  }

  .modal-card-foot__text {
    .code-c-icon {
      margin-right: 0.25rem;
      color: $color-yellow;
    }
  }
}
