.code-c-error-tile {
  position: relative;
  width: 100%;
  max-width: $error-tile-max-width;
  padding: 2rem;
  background: $color-white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  &::after {
    position: absolute;
    right: 2px;
    bottom: -24px;
    z-index: -1;
    font-family: $font-family-pop;
    font-size: 9rem;
    line-height: 9rem;
    color: transparentize($color-gray-light, 0.5);
    letter-spacing: -0.03em;
    content: attr(data-error);
  }

  .code-c-error-tile__title {
    font-family: $font-family-pop;
    font-size: 6rem;
    color: $color-primary;
  }

  .code-c-error-tile__message {
    font-size: 1.75rem;
    color: $color-text;
  }

  .code-c-error-tile__description {
    margin-bottom: 3rem;
  }

  .code-c-error-tile__report {
    max-width: 25rem;
    color: $color-gray;
  }

  .code-c-button {
    margin-bottom: 8rem;
  }
}
