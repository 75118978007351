.code-exam-edit {
  .code-exam-edit__steps {
    margin: 1rem;
  }

  .code-exam-edit__form {
    padding: 0 1.5rem 1.75rem;
  }

  .code-exam-edit__edit-limited {
    position: relative;
    margin: 1rem 0;
  }

  .code-exam-edit__challenges {
    .code-exam-edit__challenges__scroll-container {
      .code-exam-edit__exam-challenge-set-header {
        top: calc(#{$header-height} + #{$breadcrumb-height});
      }

      .code-exam-edit__challenge-table-header {
        top: calc(
          #{$header-height} + #{$breadcrumb-height} + #{$exam-challenge-set-header-height}
        );
      }
    }
  }

  .code-exam-edit__challenge {
    margin-bottom: 3rem;
  }

  .code-exam-edit__challenge-table {
    .code-exam-edit__custom-form-table__action {
      color: $color-gray;
      cursor: move;
    }
  }

  .code-exam-edit__reviewer-table {
    .code-c-reviewer-row:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .code-exam-edit__custom-form-table {
    margin-top: 1rem;

    .code-exam-edit__custom-form-table__action {
      margin-right: 1.5rem;
      margin-left: 0.5rem;
      color: $color-gray;
      cursor: move;
    }
  }

  .code-exam-edit__buttons {
    display: flex;
    justify-content: center;

    .code-c-button:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .code-c-page-section-title {
    max-width: 50rem;
  }

  .col-delete {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .code-exam-edit__drop-placeholder {
    visibility: hidden;
  }

  .form-alert-modal {
    .fa-exclamation-triangle {
      color: $color-yellow !important;
    }

    .jump-button {
      padding-top: 0.5rem;
      font-weight: 600;
      color: $color-report-applicant;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.code-exam-edit-new {
}
