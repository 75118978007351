.code-setting-member {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .code-setting-member__header {
    flex-wrap: wrap;
    align-items: center;
    padding: 1.5rem 1.5rem 0;
    white-space: nowrap;

    .code-c-left-block {
      margin-right: 2rem;
    }

    .code-setting-member__header__role-select {
      color: $color-gray-700;

      .select {
        height: 2.25rem;
      }
    }

    .code-setting-member__header__search {
      width: 20rem !important;
    }

    .code-c-right-block {
      flex-grow: 1;
      justify-content: flex-end;

      > :not(:last-child) {
        margin-right: 1rem;
      }

      .code-c-input {
        width: 100%;
        max-width: 30rem;
      }
    }

    .code-setting-member__header__count {
      margin-left: 0.5rem;
      font-size: 0.8125rem;
    }
  }

  .code-setting-member__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .code-setting-member__actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }

  .code-setting-member__table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .code-setting-member__loading-list {
    position: relative;
    min-height: 300px;
    margin-bottom: -300px;
    pointer-events: none;
  }
}
