.code-editor-exam-detail__survey {
  padding: 1rem 1rem 1.75rem;
  margin-bottom: 3rem;
  background: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 0.25rem;

  .code-editor-exam-detail__survey__title {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    margin-bottom: 1.25rem;

    h2 {
      flex-shrink: 0;
      margin-bottom: 0;
    }

    .code-editor-exam-detail__survey__title-info-banner {
      align-self: center;
    }
  }

  label {
    @include text-md;
  }

  p {
    @include text-xs;

    color: $color-gray-600;
  }

  .code-c-select {
    margin-top: 0.75rem;

    .select,
    .select select {
      width: 100%;
    }
  }
}
