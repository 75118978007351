.code-setting-configure {
  padding: 1.5rem;

  .code-setting-configure__tooltip {
    display: block;
  }

  .code-setting-configure__tooltip, .code-c-textarea {
    max-width: 29rem;
  }
}
