.code-c-sub-menu-item {
  .code-c-sub-menu-item__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25em 0.75em;
    font-size: 14px;
    color: $color-text;
    text-decoration: none;
    border-radius: 0;

    &:hover {
      color: inherit;
      background-color: $color-gray-light;

      > :not(.code-c-sub-menu-item__link__end-icon) {
        text-decoration: underline;
      }
    }

    &.is-active {
      font-weight: bold;
      color: inherit;
      background: $color-gray-light;
      border-left: 3px solid $color-gray-800;
    }

    .code-c-error-icon,
    .code-c-warning-icon {
      padding-top: 0.125rem;
      margin-left: 0.25rem;
    }

    .code-c-sub-menu-item__link__end-icon {
      &:hover {
        color: $color-gray-600;
      }
    }
  }

  &.is-disabled {
    .code-c-sub-menu-item__link {
      color: $color-gray;

      &:hover {
        color: $color-gray;
        text-decoration: none;
        cursor: not-allowed;
        background-color: inherit;

        > :not(.code-c-sub-menu-item__link__end-icon) {
          text-decoration: none;
        }
      }
    }
  }
}

@include mq(sm) {
  .menu-list a.code-c-sub-menu-item__link {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 1rem;
  }
}
