.code-exam-delivery-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - #{$header-height} - #{$breadcrumb-height} - 5rem);
  overflow: hidden;

  .code-exam-delivery-list__header__count {
    margin-left: 1rem;

    @include fontSize("small");

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    span:nth-child(2) {
      font-weight: bold;
    }
  }

  .code-exam-delivery-list__table {
    margin: 0 1.5rem;

    .code-c-table-head {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }

    .code-exam-delivery-list__table-title {
      background: $color-gray-light;

      .code-c-header-column {
        padding: 0.5rem 1rem;
        white-space: nowrap;
      }
    }

    .code-exam-delivery-list__table__body-start-at {
      display: flex;
      gap: 0 0.5rem;
      align-items: center;
    }

    .code-c-column {
      padding: 1rem;
    }

    .code-c-tooltip {
      z-index: unset;
    }

    .code-exam-delivery-list__action-buttons {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
    }
  }

  .code-exam-delivery-list__delete-modal {
    .delivery-title {
      margin-top: 1rem;
      font-weight: bold;
    }

    .exclamation-icon {
      margin-right: 0.5rem;
    }

    .code-exam-delivery-list__delete-modal__message {
      margin-bottom: 1rem;
    }
  }

  .code-exam-delivery-list__pagination {
    position: sticky;
    bottom: 0;
    z-index: $z-index-tooltip;
    display: flex;
    justify-content: center;
    padding: 2rem 1.5rem;
    background: $color-white;
  }

  .fa-exclamation-triangle {
    color: $color-yellow !important;
  }
}
