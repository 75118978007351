.code-exam-list-action {
  .code-c-icon {
    vertical-align: middle;
  }

  .code-exam-list-action__delete-button {
    color: $color-red-700 !important;
  }

  .code-exam-list-action__icon {
    width: 18px;
    height: 18px;

    &.archive {
      background: transparent url("./assets/images/icon-archive.svg") no-repeat;
      background-size: contain;
    }

    &.unarchive {
      background: transparent url("./assets/images/icon-unarchive.svg")
        no-repeat;
      background-size: contain;
    }
  }

  .code-c-tooltip-wrapper {
    width: 100%;
  }

  .code-exam-list-action__disable {
    width: 100%;
    color: $color-gray !important;
    cursor: not-allowed;

    .code-c-icon {
      opacity: 0.5;
    }

    .code-c-tooltip {
      z-index: unset;
      line-height: initial;
    }
  }
}
