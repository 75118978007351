.code-editor-exam-detail__fairness {
  &__warning {
    font-size: 1rem;
    font-weight: 700;
  }

  &__text {
    font-size: 1rem;
    font-weight: 400;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }

  &__icon {
    width: 272px;
    height: 304px;
    background-image: url("./assets/images/laptop-and-webcam.svg");
    background-repeat: no-repeat;
  }

  &__optional-link-container {
    margin-top: 0.5rem;
    text-align: center;

    &__optional-link {
      font-weight: 700;
      color: $color-neutral-800;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
