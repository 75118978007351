.code-c-review-status-tracker-bar {
  .code-c-review-status-tracker-bar__container {
    display: flex;
    gap: 0.25rem;
    font-weight: 700;

    > div {
      display: flex;
      flex: 1;
      gap: 0.25rem;
      align-items: center;
      justify-content: center;
      min-width: 2.25rem;
      height: 1.375rem; // 22px

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  .code-c-review-status-tracker-bar__hover-box {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    background-color: $color-white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

    .code-c-review-status-tracker-bar__hover-box__label {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      background-color: unset;

      .code-c-review-status-tracker-bar__text {
        font-weight: 700;
      }
    }
  }

  .code-c-review-status-tracker-bar__icon {
    width: 1rem;
    height: 1rem;
    background-color: unset !important;
  }

  .no-review {
    background-color: $color-neutral-100;
  }

  .is-strongly-approved {
    color: $color-success-700;
    background-color: $color-success-300;
  }

  .is-approved {
    color: $color-success-500;
    background-color: $color-success-100;
  }

  .is-rejected {
    color: $color-error-500;
    background-color: $color-error-100;
  }

  .is-strongly-rejected {
    color: $color-error-700;
    background-color: $color-error-300;
  }
}
