.code-c-score-distribution {
  display: flex;

  .code-c-score-distribution__chart {
    margin-right: 1rem;
  }

  .code-c-score-distribution__table {
  }

  .code-c-score-distribution__title {
    text-align: right;
    background: $color-gray-light;
    border-right: 1pc solid $color-border-gray;

    &:hover {
      background: $color-gray-light;
    }
  }

  .code-c-score-distribution__body {
    text-align: right;
  }
}
