.challenge-collection-detail-modal {
  > .modal-card {
    height: 100%;

    > .modal-card-body {
      display: flex;
      padding: 0;
      overflow: hidden;

      .challenge-collection__description {
        padding: 1rem 1rem 1.5rem;
        word-break: break-word;
        overflow-wrap: break-word;
      }

      .challenge-collection__table {
        margin: 0 1rem 1.5rem;
        overflow: visible;

        table {
          border-collapse: collapse;
        }

        .code-c-tooltip {
          z-index: unset;
        }

        .code-c-table-head {
          tr {
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }
    }
  }

  .challenge-collection__body {
    display: flex;
    flex-grow: 1;

    .challenge-collection__collections {
      overflow-y: auto;
    }

    .challenge-collection__official-exams {
      flex-basis: 17rem;
      flex-shrink: 0;
      padding: 1rem;
      overflow-y: auto;

      .challenge-collection__official-exam {
        cursor: pointer;
      }

      .challenge-collection__official-exams__title {
        margin-bottom: 0.5rem;

        @include fontSize("large", true);
      }

      > .challenge-collection__official-exam:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .code-challenge-detail {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: $z-index-loading + 1;
    width: 100%;
    max-width: 65rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .challenge-collection__overlay {
    background: transparentize($color-gray-super-dark, 0.5);
  }

  .code-official-exam-panel {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: 22;
    width: 100%;
    max-width: 65rem;
    animation: slideInRight 0.25s forwards;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
  }

  .code-official-exam-deliver {
    z-index: 25;
  }
}
