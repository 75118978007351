.code-c-file-card {
  min-height: 4.5rem;
  padding: 0 1rem;

  .code-c-file-card__icon {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    color: $color-neutral-500;
    background: $color-neutral-200;
    border-radius: 0.25rem;
  }

  .code-c-file-card__content {
    flex: 1;
    min-width: 0;
    line-height: 1.25rem;
  }

  .code-c-file-card__name {
    @include text-md;
  }

  .code-c-file-card__size {
    color: $color-gray-600;
  }

  .code-c-file-card__status {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .code-c-file-card__error-icon {
    color: $color-error-500;
  }

  .code-c-file-card__error {
    font-size: 0.75rem; // 12px
    line-height: 1rem; // 16px
    color: $color-gray-600;
  }

  .code-c-file-card__action-icon {
    cursor: pointer;
  }

  .code-c-file-card__action-icon--disabled {
    color: $color-gray-300;
    cursor: not-allowed;
  }

  .code-c-file-card__action-icon--error {
    color: $color-error-500;

    &:hover {
      color: $color-error-600;
    }
  }

  .code-c-file-card__spinner {
    width: 1.5rem;
  }

  .code-c-file-card__discard {
    position: absolute;
    top: -8px;
    right: -8px;
  }

  .code-c-file-card__action-tooltip {
    text-transform: capitalize;
  }
}
