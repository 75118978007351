.code-c-env-tag {
  text-transform: capitalize;
  background-color: $color-gray;

  &.is-develop {
    color: $color-white;
    background-color: $color-red;
  }

  &.is-staging {
    color: $color-white;
    background-color: $color-primary;
  }

  &.is-local {
    color: $color-white;
    background: linear-gradient(135deg, orange , yellow, green, cyan, blue, violet);
  }
}