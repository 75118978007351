.code-submission-evaluation-status-wrapper {
  display: flex;
  gap: 0.5rem;

  &.is-row {
    flex-direction: row;
    align-items: center;
  }

  &.is-column {
    flex-direction: column;
    align-items: flex-start;
  }
}
