.code-exam-entrance {
  max-width: 40rem;
  padding: 3rem 1rem;
  margin: 0 auto;

  .code-exam-entrance__title {
    margin-bottom: 1.5rem;
    font-size: 1.25em;
    text-align: center;
  }

  .code-exam-entrance__exam {
    display: flex;
    padding: 1.5rem;
    margin-bottom: 3rem;
    border: 1px solid $color-gray-light;
    border-radius: 0.5em;

    .code-exam-entrance__org {
      flex-grow: 1;

      .code-exam-entrance__orgname {
        margin-bottom: 0.25em;
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .code-exam-entrance__deadline {
      flex-basis: 10rem;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .code-exam-entrance__form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: 4rem;

    .code-exam-entrance__form-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding: 1rem;
      margin-bottom: 1.5rem;
      background: $color-gray-super-light;
      border-radius: 8px;
    }

    .code-exam-entrance__form-multi-lang {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      border-bottom: 1px solid $color-border-gray;
    }

    .code-exam-entrance__form-language {
      display: flex;
      column-gap: 0.5rem;
      margin-bottom: 1rem;

      > .code-c-radio {
        display: flex;
        flex-grow: 1;
        background: $color-white;
        border: 1px solid $color-border-gray;
        border-radius: 3px;

        > label {
          display: flex;
          flex-grow: 1;
          align-items: center;
          padding: 0.5rem;
          cursor: pointer;
        }

        &.is-checked {
          border-color: $color-secondary-500;
        }
      }
    }

    .code-exam-entrance__form-email {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }

    .code-exam-entrance__form-email-note {
      display: flex;
      column-gap: 0.5rem;

      > .code-c-icon {
        color: $color-yellow !important;
      }
    }

    .code-exam-entrance__form-button {
      align-self: center;
    }
  }

  .code-exam-entrance__steps {
    padding: 0.5rem 1rem 1.5rem;
    margin-bottom: 3rem;
    text-align: center;
    border: 4px solid $color-primary;
    border-radius: 12px;

    .code-c-page-sub-title {
      margin-top: 1rem;
    }
  }

  .code-exam-entrance__expired {
    color: $color-red;
  }
}
