.code-final-challenge-submit {
  .code-final-challenge-submit__img {
    width: 4.5rem;
    height: 4.5rem;
    margin-bottom: 1rem;
  }

  .code-final-challenge-submit__title {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .code-final-challenge-submit__body {
    text-align: center;

    strong {
      color: $color-red-500;
    }
  }

  &.modal {
    .modal-card-head {
      display: none;
    }

    .modal-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 1rem;
      padding-bottom: 1.5rem;
    }

    .modal-card-foot {
      border-top: 0;
    }
  }
}

