@mixin dot-shape() {
  position: absolute;
  top: calc((1.125rem - 0.5rem) / 2);
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  content: "";
  border-radius: 50%;
}

.code-c-timeline-item {
  position: relative;
  padding-left: 1rem;

  &__primary {
    &:nth-child(odd)::after {
      @include dot-shape;

      background-color: $color-primary-500;
    }
  }

  &:nth-child(even) {
    @include text-sm;

    padding-top: 0.5rem;
    padding-bottom: 1.5rem;

    &:not(:last-child)::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.25rem;
      height: 100%;
      content: "";
      border-right: 1px dashed $color-neutral-300;
    }
  }
}
