.code-orgs-container {
  .code-c-header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .code-c-menu {
    position: fixed;
    top: $header-height;
    height: calc(100% - #{$header-height});
  }

  .code-orgs-container__body {
    min-height: 100vh;
    padding-top: $header-height;
    padding-left: $menu-width;
    background: $color-white;
  }

  &.no-sidemenu {
    .code-orgs-container__body {
      padding-left: 0;
    }
  }

  &.no-header {
    .code-orgs-container__body {
      padding-top: 0;
    }
  }
}

@include mq(sm) {
  .code-orgs-container .code-orgs-container__body {
    padding-left: 0;
  }
}
