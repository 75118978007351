.code-exam-edit-new__detail_settings {
  .form-alert-modal {
    .fa-exclamation-triangle {
      color: $color-yellow !important;
    }

    .jump-button {
      padding-top: 0.5rem;
      font-weight: 600;
      color: $color-report-applicant;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
