.code-challenge-questions {
  .code-challenge-questions__row {
    display: flex;
    margin-bottom: 1rem;
  }

  .code-challenge-questions__no {
    flex-basis: 2rem;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  .code-challenge-questions__question {
    flex-grow: 1;
    overflow-x: auto;
    border: 1px solid $color-border-gray;
  }
}
