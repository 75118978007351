.code-c-autocomplete-email-template {
    &-popover-container {
        background-color: $color-white;
        border: 1px solid $color-gray-300;
        border-radius: 4px;

        &-children {
            padding: 0.5rem;
        }

        .no-matches {
            padding-top: 0.25rem;
            padding-right: 0.75rem;
            padding-bottom: 0.25rem;
            padding-left: 0.75rem;
        }
    }

    &-divider {
        border-top: 1px solid $color-gray-300;
    }

    &-container {
        padding-top: 0.25rem;
        padding-right: 0.375rem;
        padding-bottom: 0.25rem;
        padding-left: 0.75rem;
        margin: 8px;
        font-size: 0.875rem;
        color: $color-neutral-800;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            background-color: $color-neutral-100;
        }
        
        &-icon {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
    }
}

