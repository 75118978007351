.code-c-submitted-file-table {
  &.code-c-table {
    overflow: unset;
  }

  .code-c-tooltip {
    z-index: unset;
    text-align: center;
  }

  .code-c-submitted-file-table__score-unavailable {
    @include fontSize("xsmall");

    > .code-c-icon {
      color: $color-yellow;
    }
  }
}
