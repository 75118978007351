.code-challenge-collection {
  .challenge-collection__card-container {
    .challenge-collection__label {
      margin-bottom: 1rem;
      font-weight: bold;
    }

    .swiper {
      .swiper-button-next,
      .swiper-button-prev {
        top: 45%;
        width: 30px;
        height: 60px;
        color: $color-white;
        visibility: hidden;
        background: transparentize($color-gray, 0.5);

        &::after {
          font-family: FontAwesome;
          font-size: 30px;
        }
      }

      .swiper-button-next {
        right: 0;
        padding-left: 0.5rem;
        border-radius: 30px 0 0 30px;

        &::after {
          content: "\f0da";
        }
      }

      .swiper-button-prev {
        left: 0;
        padding-right: 0.5rem;
        border-radius: 0 30px 30px 0;

        &::after {
          content: "\f0d9";
        }
      }

      .swiper-wrapper {
        box-sizing: inherit;

        .swiper-slide {
          width: auto;
        }
      }
    }

    .swiper:hover .swiper-button-prev {
      visibility: visible;

      &.swiper-button-disabled {
        visibility: hidden;
      }
    }

    .swiper:hover .swiper-button-next {
      visibility: visible;

      &.swiper-button-disabled {
        visibility: hidden;
      }
    }
  }
}
