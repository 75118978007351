.code-c-quick-help {
  .code-c-icon {
    height: auto;
  }
}

.code-c-quick-help-wrapper {
  color: $color-gray-500;

  .code-c-icon {
    height: auto;
  }
}
