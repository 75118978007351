.code-diff-viewer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .code-diff-viewer-header {
    flex-shrink: 0;
  }

  .code-diff-viewer__iframe {
    flex-grow: 1;
    vertical-align: top;
  }
}
