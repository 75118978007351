.code-c-applicant-challenge-header-menu {
  $applicant-challenge-header-menu-width: 200px;

  position: absolute;
  top: calc(#{$header-height} - 0.25rem);
  right: 0.5rem;
  z-index: $z-index-account-menu;
  display: none;
  min-width: $applicant-challenge-header-menu-width;
  background: $color-white;
  border: 1px solid $color-gray-light;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  &.is-open {
    display: flex;
    animation: slideInDown 0.3s;
  }

  .code-c-applicant-challenge-header-menu__container {
    min-width: $applicant-challenge-header-menu-width;
  }

  .code-c-applicant-challenge-header-menu__item {
    height: 2rem;
    line-height: 2rem;
    transition: background-color 0.25s;

    &:hover {
      background: $color-gray-light;
    }

    a {
      display: block;
      padding: 0 0.5rem;
      color: $color-text;
    }
  }
}
