.code-c-tab-switch-button {
  pointer-events: none;
  background-color: $color-gray-200;
  border-color: 1px solid $color-gray-200;
  border-radius: 4px;

  &:hover {
    background-color: $color-gray-300;
  }

  .code-c-button {
    @include text-sm;

    pointer-events: stroke;
    border-color: $color-gray-400;
    border-radius: 4px;
  }

  button:focus:not(:active) {
    box-shadow: none;
  }

  button:focus {
    border-color: $color-gray-400;
  }

  :not(:first-child, :last-child) {
    border-radius: 0;
  }

  .code-c-tab-switch-button__disabled {
    color: $color-gray-500;
    pointer-events: stroke;
    background-color: $color-gray-200;
    border-color: $color-gray-200;
    box-shadow: none;

    &:hover {
      color: $color-gray-600;
      background-color: $color-gray-300;
      border-color: $color-gray-300;
    }
  }
}
