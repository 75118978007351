.code-c-score-button {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  row-gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 8rem;
  min-width: 4rem;
  min-height: 7.5rem;
  padding: 1rem 0;
  cursor: pointer;
  background: $color-white;
  border: 1px solid $color-border-gray;
  border-radius: 4px;
  outline: none;
  transition: all 0.25s;

  &.width-small {
    width: 6rem;
  }

  .code-c-score-button__score-text {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 0 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }


  &.dark-green {
    color: $color-green-700;

    &.is-checked,
    &.hover-highlight:hover {
      background: $color-green-300;
      border: 2px solid $color-green-700;
    }
  }

  &.green {
    color: $color-green-500;

    &.is-checked,
    &.hover-highlight:hover {
      background: $color-green-100;
      border: 2px solid $color-green-500;
    }
  }

  &.red {
    color: $color-red-500;

    &.is-checked,
    &.hover-highlight:hover {
      background: $color-red-100;
      border: 2px solid $color-red-500;
    }
  }

  &.dark-red {
    color: $color-red-700;

    &.is-checked,
    &.hover-highlight:hover {
      background: $color-red-300;
      border: 2px solid $color-red-700;
    }
  }

  &.is-disabled {
    opacity: 0.6;

    &:hover {
      cursor: not-allowed;
    }
  }
}
