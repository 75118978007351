.code-submission-detail-header {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;

  .code-submission-detail-header__left {
    flex-grow: 1;
    margin-right: 1rem;
  }

  .code-submission-detail-header__right {
    .code-c-button:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .code-submission-detail-header__share-link {
    flex-grow: 1;
    max-width: 25rem;

    .code-c-input {
      width: 100%;
    }
  }

  .code-submission-detail-header__submit-button {
    color: $color-red-700;
  }

  .code-submission-detail-header__icon {
    width: 18px;
    height: 18px;

    &.archive {
      background: transparent url("./assets/images/icon-archive.svg") no-repeat;
      background-size: contain;
    }

    &.unarchive {
      background: transparent url("./assets/images/icon-unarchive.svg")
        no-repeat;
      background-size: contain;
    }

    &.file-export {
      width: 20px;
      background: transparent url("./assets/images/icon-file-export.svg")
        no-repeat;
      background-size: contain;
    }
  }
}
