.code-challenge-prompts {
  .code-challenge-prompts__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30rem;
    background: transparentize($color-gray-super-dark, 0.85);
  }
}
